<div fxLayout="column">
  <div class="boxed-content-wrapper">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>

          <span class="md-title" fxFlex=100>Accounting /
            <a [routerLink]="['/accounting/bank']">Account</a> / {{ getFromAccountName() }} / New Transfer</span>

        </md-card-title>
      </md-card-header>

      <md-card-content>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="47%">
            <input mdInput placeholder="Description" [(ngModel)]="description">
          </md-input-container>
          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Date" type="date" [(ngModel)]="date">
          </md-input-container>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <!-- <md-select fxFlex="22%" disabled placeholder="From account" [(ngModel)]="from_account_id">
            <md-option *ngFor="let account of allAccounts$|async" [value]="account.id">{{account.name}}</md-option>
          </md-select>
        -->
        <md-input-container fxFlex="47%">            
            <input mdInput disabled placeholder="{{ getFromAccountName() }}" type="text">
        </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Amount({{getCurrency(from_account_id)}})" type="number" step="0.01" [(ngModel)]="from_amount" (ngModelChange)="to_amount=from_amount*currency_exchange_rate">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Currency exchange rate" type="number" step="0.01" [(ngModel)]="currency_exchange_rate" (ngModelChange)="to_amount=from_amount*currency_exchange_rate">
          </md-input-container>
        </div>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-select fxFlex="47%" placeholder="Accounts" [(ngModel)]="to_account_id">
            <md-option *ngFor="let account of allAccounts$|async" [value]="account.id">{{account.name}}</md-option>
          </md-select>

          <md-input-container fxFlex="22%">
            <input mdInput readonly placeholder="Amount({{getCurrency(to_account_id)}})" type="number" step="0.01" [ngModel]="to_amount">
          </md-input-container>
        </div>
      </md-card-content>

      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button [disabled]="_creating" md-button color="primary" class="bg-blue color-white" (click)="createTransfer()">Create</button>
          <button [disabled]="_creating" md-button color="warn" class="bg-red color-white" (click)="goBack()">Back</button>
        </div>
      </md-card-actions>
    </md-card>
  </div>
</div>