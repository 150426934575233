<div fxLayout="column">
  

  <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
    <md-icon>warning</md-icon>
    <span>{{ 'you_have_unsaved_changes' | translate:_authService.getLanguage() }}</span>
  </div>

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
      <td-steps mode="horizontal" *ngIf="!isDeclined()">
        <td-step label="DRAFT" sublabel="To be completed" [active]="true" [state]="isDraft()?'complete':'none'"
          disabled="true">NEXT: Move to SHIPPED status</td-step>
        <td-step label="SHIPPED" sublabel="Order Completed" [state]="isShipped()?'complete':'none'" [active]="true"
          disabled="true">Completed</td-step>
      </td-steps>
      <td-steps mode="horizontal" *ngIf="isDeclined()">
        <td-step label="DRAFT" sublabel="To be completed" [active]="true" state="complete" disabled="true"></td-step>
        <td-step label="DECLINED" sublabel="Order Declined" [active]="true" state="complete" disabled="true"></td-step>
      </td-steps>

      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex="100">{{ 'debit_and_credit_notes' | translate:_authService.getLanguage() }} /
            <a [routerLink]="['/debitnotes/list']">{{ 'list' | translate:_authService.getLanguage() }}</a> / {{ (isEditMode && currentDebitNote) ? currentDebitNote.order_number
            : title }}
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white text-only">
          {{ 'order_details' | translate:_authService.getLanguage() }}</md-card-subtitle>
      </md-card-header>
      <md-card-content fxLayout="column" *ngIf="currentDebitNote">

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-select fxFlex="22%" placeholder="{{ 'type' | translate:_authService.getLanguage() }}"
            [(ngModel)]="currentDebitNote.type">
            <md-option *ngFor="let type of ['Credit','Debit']" [value]="type">
              {{type | translate:_authService.getLanguage()}}</md-option>
          </md-select>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="22%">
            <input mdInput readonly placeholder="{{ 'order_number' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentDebitNote.order_number">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input [disabled]="isEditMode" mdInput flex placeholder="Prefix - Customer" [mdAutocomplete]="autoCustomer" [(ngModel)]="cust"
              (ngModelChange)="filter(cust);">
            <md-autocomplete #autoCustomer="mdAutocomplete">
              <md-option *ngFor="let customer of matchingCustomers" [value]="customer?.prefix + ' - ' + customer?.name"
                (click)="setSelected(customer)">
                {{customer?.prefix + ' - ' + customer?.name}}
              </md-option>
            </md-autocomplete>
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'order_date' | translate:_authService.getLanguage() }}" type="date"
              [(ngModel)]="currentDebitNote.order_date">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'delivery_date' | translate:_authService.getLanguage() }}" type="date"
              [(ngModel)]="currentDebitNote.delivery_date">
          </md-input-container>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <div fxFlex="22%"></div>

          <md-select fxFlex="22%" placeholder="{{ 'status' | translate:_authService.getLanguage() }}"
            [(ngModel)]="currentDebitNote.status">
            <md-option *ngFor="let status of availableStatuses" [value]="status">{{status}}</md-option>
          </md-select>

          <md-input-container fxFlex="22%">
            <input mdInput readonly placeholder="{{ 'salesman' | translate:_authService.getLanguage() }}"
              [ngModel]="currentDebitNote.salesman">
          </md-input-container>

          <md-select fxFlex="22%" [disabled]="isEditMode"
            placeholder="{{ 'branch' | translate:_authService.getLanguage() }}" name="branch"
            [(ngModel)]="currentDebitNote.branch_id"
            (ngModelChange)="updateTax($event); filterSalesmen(); loadCustomers();">
            <md-option *ngFor="let branch of filteredBranches" [value]="branch.id">{{branch.code}}/{{branch.name}}
            </md-option>
          </md-select>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="47%">
            <textarea mdInput placeholder="{{ 'notes' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentDebitNote.notes">{{currentDebitNote.notes}}</textarea>
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'customer_po_reference' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentDebitNote.customer_po">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'invoice_number' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentDebitNote.invoice_number">
          </md-input-container>

        </div>
      </md-card-content>
    </md-card>

    <md-card>
      <md-card-header fxLayout="column">
        <md-card-subtitle class="bg-grey color-white text-only">
          {{ 'order_items' | translate:_authService.getLanguage() }}
        </md-card-subtitle>
      </md-card-header>
      <md-card-content>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">

          <md-input-container fxFlex="47%">
            <input mdInput placeholder="{{ 'description' | translate:_authService.getLanguage() }}"
              [(ngModel)]="item.description">
          </md-input-container>
          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'price' | translate:_authService.getLanguage() }}" [(ngModel)]="item.price">
          </md-input-container>
          <div fxFlex="22%">
            <button md-button color="primary" class="bg-blue color-white"
              (click)="addItemsToOrder()">{{ 'add_capital' | translate:_authService.getLanguage() }}</button>
          </div>

        </div>
      </md-card-content>
    </md-card>

    <md-card>
      <md-card-header fxLayout="column">
        <md-card-subtitle class="bg-grey color-white text-only">{{ 'items' | translate:_authService.getLanguage() }}
        </md-card-subtitle>
      </md-card-header>

      <md-card-content fxLayout="column" *ngIf="currentDebitNote">
        <div class="mat-table-container tmp-driven-table">
          <table td-data-table class="duplicated-thead-fix">
            <thead>
              <tr td-data-table-column-row>
                <th td-data-table-column>Description</th>
                <th td-data-table-column>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr td-data-table-row *ngFor="let row of items">
                <td td-data-table-cell>{{ row['description'] }}</td>
                <td td-data-table-cell>{{ row['price'] }}</td>
              </tr>
              <tr td-data-table-row>
                <td td-data-table-cell>&nbsp;</td>
                <td td-data-table-cell>&nbsp;</td>
              </tr>
              <tr td-data-table-row>
                <td td-data-table-cell>
                  {{ 'total' | translate:_authService.getLanguage() }}
                </td>
                <td td-data-table-cell>{{ total() | number:'1.2-2' }}</td>
              </tr>
              <tr td-data-table-row>
                <td td-data-table-cell (click)="!isShipped() && openModifyTax1()" style="cursor:pointer">
                  {{ 'tax' | translate:_authService.getLanguage() }} ({{ currentDebitNote.tax }}%)
                </td>
                <td td-data-table-cell>{{ total() * currentDebitNote.tax / 100 | number:'1.2-2' }}</td>
              </tr>
              <tr td-data-table-row>
                <td td-data-table-cell (click)="!isShipped() && openModifyTax2()" style="cursor:pointer">
                  {{ 'tax2' | translate:_authService.getLanguage() }} ({{ currentDebitNote.tax2 }}%)
                </td>
                <td td-data-table-cell>{{ total() * currentDebitNote.tax2 / 100 | number:'1.2-2' }}</td>
              </tr>
              <tr td-data-table-row>
                <td td-data-table-cell>
                  <b>{{ 'total' | translate:_authService.getLanguage() }}</b>
                </td>
                <td td-data-table-cell>{{ total() * (1 + (currentDebitNote.tax / 100) + (currentDebitNote.tax2 / 100)) | number:'1.2-2' }}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </md-card-content>

      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button [disabled]="isLoading()" *ngIf="!isEditMode && !_authService.isViewer()" color="primary"
            class="bg-blue color-white"
            (click)="createNewDebitNote()">{{ 'create' | translate:_authService.getLanguage() }}</button>
          <button md-button [disabled]="isLoading()" *ngIf="isEditMode && !_authService.isViewer()" color="primary"
            class="bg-blue color-white"
            (click)="updateDebitNote()">{{ 'update' | translate:_authService.getLanguage() }}</button>
          <button md-button color="warn" [disabled]="isLoading()" class="bg-red color-white "
            (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
        </div>
      </md-card-actions>
    </md-card>
  </div>
</div>
