<div fxLayout="column">

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>
            PDA /
            <a [routerLink]="['/pda/inventory']">{{ 'inventory' | translate:_authService.getLanguage() }}</a> /
            {{ 'move_item' | translate:_authService.getLanguage() }}
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white text-only">PDA
          {{ 'move_item' | translate:_authService.getLanguage() }}</md-card-subtitle>
      </md-card-header>
      <md-card-content fxLayout="column">

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-slide-toggle [(ngModel)]="multiMode" (ngModelChange)="pieceNumber=''; pieceNumbers=''">Multi-piece mode
          </md-slide-toggle>
        </div>


        

        <!-- Load all items from a pallet -->

        <div *ngIf="multiMode" class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-select placeholder="{{ 'branch' | translate:_authService.getLanguage() }}" [(ngModel)]="branchFilter"
            name="branch" fxFlex="47%">
            <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.id">{{branchItem.code}} -
              {{branchItem.name}}</md-option>
          </md-select>
        </div>


        <div *ngIf="multiMode" class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">          
        
          <md-input-container fxFlex="47%">
            <input mdInput placeholder="{{ 'load_from_pallet' | translate:_authService.getLanguage() }}" [(ngModel)]="sourcePallet">
          </md-input-container>

          <div class="padded-container">
            <button md-button color="primary" class="bg-blue color-white"
              (click)="loadItems(sourcePallet)">{{ 'load' | translate:_authService.getLanguage() }}</button>
          </div>
        
        </div>
        

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">

          <md-input-container fxFlex="47%" *ngIf="multiMode">
            <textarea mdInput rows="10" placeholder="{{ 'piece_numbers' | translate:_authService.getLanguage() }}"
              [(ngModel)]="pieceNumbers"></textarea>
          </md-input-container>

          <md-input-container fxFlex="47%" *ngIf="!multiMode">
            <input mdInput placeholder="{{ 'piece_number' | translate:_authService.getLanguage() }}"
              [(ngModel)]="pieceNumber">
          </md-input-container>

        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="47%">
            <input mdInput placeholder="{{ 'new_pallet' | translate:_authService.getLanguage() }}"
              [(ngModel)]="newPallet">
          </md-input-container>
        </div>
      </md-card-content>

      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button color="primary" *ngIf="!multiMode" class="bg-blue color-white"
            (click)="changePallet()">{{ 'change' | translate:_authService.getLanguage() }}</button>
          <button md-button color="primary" *ngIf="multiMode" class="bg-blue color-white"
            (click)="changeAllPallet()">{{ 'change_all' | translate:_authService.getLanguage() }}</button>
          <button md-button color="warn" class="bg-red color-white"
            (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
        </div>
      </md-card-actions>
    </md-card>
  </div>
</div>
