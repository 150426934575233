<div fxLayout="column">
  
  <div class="boxed-content-wrapper">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>{{ 'sales_assistant' | translate:_authService.getLanguage() }}</span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white">
          <div layout="row">
            <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm"
              placeholder="{{ 'search_here' | translate:_authService.getLanguage() }}" (searchDebounce)="search($event)"
              flex>
            </td-search-box>
            <form class="push-right-sm" fxLayoutGap="16px">
              <md-select placeholder="{{ 'branch' | translate:_authService.getLanguage() }}" [(ngModel)]="branchFilter"
                (ngModelChange)="filter()" name="branch">
                <md-option [value]="'ALL'">{{ 'all_branches' | translate:_authService.getLanguage() }}</md-option>
                <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.code">{{branchItem.code}}
                  - {{branchItem.name}}</md-option>
              </md-select>

            </form>

            <button md-icon-button [mdMenuTriggerFor]="exportMenu">
              <md-icon>cloud_download</md-icon>
            </button>

            <md-menu #exportMenu="mdMenu">
              <button md-menu-item (click)="_exportService.asCsv(filteredData, 'Sales')">
                <md-icon>cloud_download</md-icon>{{ 'export_this_list' | translate:_authService.getLanguage() }}
              </button>
            </md-menu>
          </div>
        </md-card-subtitle>
      </md-card-header>

      <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">

        <!-- Date Range Selection -->
        <div fxLayout="row" fxLayoutAlign="end center" layout-margin fxLayoutGap="10px">
          <div flex></div>
          <md-input-container>
            <input mdInput type="date" placeholder="Date From" [(ngModel)]="from_date">
          </md-input-container>
          <md-input-container>
            <input mdInput type="date" placeholder="Date To" [(ngModel)]="to_date">
          </md-input-container>
          <button md-button color="primary" class="bg-blue color-white" (click)="loadHistory()">{{ 'find' | translate:_authService.getLanguage() }} </button>
        </div>
        <md-divider></md-divider>
        <div class="mat-table-container tmp-driven-table">
          <table td-data-table class="duplicated-thead-fix">
            <thead>
              <tr td-data-table-column-row>
                <th td-data-table-column *ngFor="let column of columns" [active]="sortBy == column.name"
                  [name]="column.name" [sortOrder]="sortOrder" [sortable]="true" (sortChange)="sort($event)"
                  [numeric]="false">
                  {{column.label}}
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- 
                  order_date, delivery_date, branch, branch_code, exchange, shipped_from_branch, shipped_from_branch_code, customer_name, salesman, order_number, invoice_number, fabric_type_name, collection_name, design_name, color_name, design_color, color_id, pieces_sold, quantity_sold, quantity_reserved, quantity_available, quantity_ordered, unit_price, total_local, total_eur, tax, total_after_tax 
            -->
              <tr td-data-table-row *ngFor="let row of filteredData">
                <td td-data-table-cell>{{row.delivery_date?.substring(0, 10)}}</td>
                <td td-data-table-cell>{{row.type}}</td>
                <td td-data-table-cell>{{row.branch_code}}</td>
                <td td-data-table-cell>{{row.shipped_from_branch_code}}</td>
                <td td-data-table-cell>{{row.customer_name}}</td>
                <td td-data-table-cell>{{row.salesman}}</td>
                <td td-data-table-cell>{{row.invoice_number}}</td>
                <td td-data-table-cell>{{row.fabric_type_name}}</td>
                <td td-data-table-cell>{{row.collection_name}}</td>
                <td td-data-table-cell (click)="openColorDetailsDialog(row)" style="cursor: pointer">{{row.design_color}} <md-icon class="md-18">info</md-icon></td>
                <td td-data-table-cell>{{row.quantity_sold}}</td>

                <!-- TODO: ON HOVER ADD EXCHANGE RATE AND original local unit price -->
                <td td-data-table-cell>{{row.unit_price_eur}}</td>
                <!-- TODO: ON HOVER ADD EXCHANGE RATE AND original local total -->
                <td td-data-table-cell>{{row.total_eur}}</td> <!-- TOTAL BEFORE TAX -->
                <!--
                <td td-data-table-cell>{{row.quantity_available}}</td>
                <td td-data-table-cell>{{row.quantity_reserved}}</td>
                <td td-data-table-cell>{{row.quantity_ordered}}</td>
                -->
              </tr>
              <tr td-data-table-column-row class="total-row no-border default">

                <td td-data-table-cell>{{ 'total' | translate:_authService.getLanguage() }}
                  ({{ 'page' | translate:_authService.getLanguage() }})
                </td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ total (filteredData, 'quantity_sold') | number:'1.2-2'}}m</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>€ {{ total (filteredData, 'total_eur') | number:'1.2-2'}}</td>
                
                <!--
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                -->
              </tr>
              <tr td-data-table-column-row class="total-row no-border default">

                <td td-data-table-cell>{{ 'total' | translate:_authService.getLanguage() }}
                  ({{ 'all' | translate:_authService.getLanguage() }})
                </td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ total (data, 'quantity_sold') | number:'1.2-2'}}m</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>€ {{ total (data, 'total_eur') | number:'1.2-2'}}</td>
                <!--
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                -->
              </tr>
            </tbody>
          </table>
        </div>
        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar
            [pageSizes]="[10, 50, 100, 1000, 'All']" [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal"
            (change)="page($event)">
            <span td-paging-bar-label hide-xs>{{ 'rows_per_page' | translate:_authService.getLanguage() }}:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">{{ 'all' | translate:_authService.getLanguage() }} </span>
            <span hide-xs>{{pagingBar.total}} {{ 'items' | translate:_authService.getLanguage() }}</span>
          </td-paging-bar>
        </div>
      </md-card-content>
    </md-card>
  </div>
</div>
