<div fxLayout="column">
    
    <div class="boxed-content-wrapper cards-no-gaps">
      <md-card>
          <md-card-header fxLayout="column">
            <md-card-title>
                <span class="md-title" fxFlex=100>
                    PDA / {{ 'receiving' | translate:_authService.getLanguage() }}
                </span>
            </md-card-title>
              <md-card-subtitle class="bg-grey color-white text-only">{{ 'receiving' | translate:_authService.getLanguage() }}</md-card-subtitle>
          </md-card-header>
          <md-card-content fxLayout="column">
              <div class="padded-container custom-container">
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/receiving/scan']">{{ 'scan' | translate:_authService.getLanguage() }}</button>
                  <!-- <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/receiving/transfer']">{{ 'transfer' | translate:_authService.getLanguage() }}</button> -->
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/receiving/return']">{{ 'scan_returns' | translate:_authService.getLanguage() }}</button>
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/receiving/printlabels']">{{ 'print_labels' | translate:_authService.getLanguage() }}</button>
              </div>
          </md-card-content>
      </md-card>
    </div>
</div>
