<div fxLayout="column">
    
    <div class="boxed-content-wrapper cards-no-gaps">
        <md-card>
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>
                        PDA /
                        <a [routerLink]="['/pda/receiving']">{{ 'receiving' | translate:_authService.getLanguage() }}</a> /
                        <a [routerLink]="['/pda/receiving/return']">{{ 'scan_returns_number' | translate:_authService.getLanguage() }}</a> / {{order_number}}
                    </span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white text-only">{{ 'scan_receiving_pieces' | translate:_authService.getLanguage() }}</md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column">
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="{{ 'piece_number' | translate:_authService.getLanguage() }}" [(ngModel)]="piece_no">
                        <!--(ngModelChange)="suggestSpecs()"-->
                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="40%">
                        <input mdInput placeholder="{{ 'pallet' | translate:_authService.getLanguage() }}" [(ngModel)]="pallet_name">
                    </md-input-container>
                    <button md-icon-button md-raised-button (click)="piece_pallet = ''" style="cursor: pointer">
                        <md-icon>clear</md-icon>
                    </button>
                </div>
                <!-- No need to override length and weight -->
                <!--div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="Length" [(ngModel)]="piece_length">
                    </md-input-container>
                </div-->
                <!--div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="Weight" [(ngModel)]="piece_weight">
                    </md-input-container>
                </div-->
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <button md-button color="primary" class="bg-blue color-white" (click)="scanned()">{{ 'add' | translate:_authService.getLanguage() }}</button>
                </div>
            </md-card-content>

            <md-card-actions>
                <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                    <button md-button color="primary" class="bg-blue color-white" (click)="confirmOrder()">{{ 'confirm' | translate:_authService.getLanguage() }}</button>
                    <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
                </div>
            </md-card-actions>

            <md-card-content>
                <md-divider></md-divider>
                <td-data-table #dataTable [data]="order_items" [columns]="columns" [selectable]="false" [sortable]="false" class="compressed-table">

                    <ng-template tdDataTableTemplate="check" let-value="value" let-row="row" let-column="column">
                        <div layout="row">
                            <md-icon md-list-icon *ngIf="!row['checked']">radio_button_unchecked</md-icon>
                            <md-icon md-list-icon *ngIf="row['checked']" style="color: green; cursor: pointer" (click)="row['checked']=false; row['pallet']=''; dataTable.refresh()">check_circle</md-icon>
                        </div>
                    </ng-template>

                </td-data-table>
            </md-card-content>

            <md-card-actions>
                <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                    <button md-button color="primary" class="bg-blue color-white" (click)="confirmOrder()">{{ 'confirm' | translate:_authService.getLanguage() }}</button>
                    <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
                </div>
            </md-card-actions>

        </md-card>
    </div>
</div>