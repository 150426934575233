<div flex layout="column" layout-wrap layout-margin>
  <div flex>
    <h4>Add New Piece to preparation</h4>
  </div>

  <div flex>
    <p>(Remaining credit for customer: {{available_credit | number:'1.2-2'}})</p>
  </div>

  <div flex layout="row">
    <md-input-container>
      <input mdInput placeholder="{{ 'piece_number' | translate:_authService.getLanguage() }}" [(ngModel)]="piece_no">
    </md-input-container>

    <md-icon md-list-icon *ngIf="found" style="color: green; cursor: pointer">check_circle</md-icon>
  </div>


  <button md-button md-raised-button color="primary" class="bg-blue color-white" (click)="verifyPiece()">{{ 'search' | translate:_authService.getLanguage() }}</button>

  <md-input-container fxFlex="40%">
    <input mdInput placeholder="{{ 'unit_price' | translate:_authService.getLanguage() }}" [(ngModel)]="unit_price">
  </md-input-container>

  <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
    <button md-button md-raised-button color="primary" class="bg-blue color-white" (click)="confirm()">{{ 'add_piece' | translate:_authService.getLanguage() }}</button>
    <button md-button md-raised-button color="warn" class="bg-red color-white" (click)="_ref.close()">{{ 'close' | translate:_authService.getLanguage() }}</button>
  </div>
</div>