<div fxLayout="column">
  

  <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
    <md-icon>warning</md-icon>
    <span>You have unsaved changes</span>
  </div>

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex="100">Return Orders /
            <a [routerLink]="['/refunds/list']">Order List</a> / {{ (isEditMode && currentSale) ? currentSale.order_number : title }}
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white text-only">Order Details</md-card-subtitle>
      </md-card-header>
      <md-card-content fxLayout="column" *ngIf="currentSale">
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="22%">
            <input mdInput readonly placeholder="Order Number" [(ngModel)]="currentSale.order_number">
          </md-input-container>

          <div fxFlex="22%" *ngIf="isEditMode && !currentSale.customer_name"></div>

          <!-- <md-select *ngIf="!isEditMode || currentSale.customer_name" fxFlex="22%" placeholder="Customer / Prefix" name="supplierPrefix"
            [(ngModel)]="currentSale.customer_id" (ngModelChange)="getPrefixForCustomerId($event); getSalesman($event); filterBranches(); updateInventory($event)">
            <md-option *ngFor="let customer of filteredCustomers" [value]="customer.id">{{customer.prefix}}/{{customer.name}}</md-option>
          </md-select> -->

          <md-input-container fxFlex="22%" *ngIf="!isEditMode || currentSale.customer_name">
            <input mdInput placeholder="{{ 'customer' | translate:_authService.getLanguage() }} / {{ 'prefix' | translate:_authService.getLanguage() }}"
              [mdAutocomplete]="autoCustomer" [(ngModel)]="currentSale.customer_name"
              (ngModelChange)="filterCustomers(); getPrefixForCustomerName($event); filterBranches(); updateInventory($event)">
            <md-autocomplete #autoCustomer="mdAutocomplete">
              <md-option *ngFor="let customer of customerOptions(currentSale.customer_name)" [value]="customer.name">
                <span>{{customer?.prefix + ' - ' + customer?.name}}</span>
              </md-option>
            </md-autocomplete>
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Order Date" type="date" [(ngModel)]="currentSale.order_date">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Delivery Date" type="date" [(ngModel)]="currentSale.delivery_date">
          </md-input-container>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <!--md-select fxFlex="22%" placeholder="Type" name="cityCountry" [(ngModel)]="currentSale.type">
            <md-option *ngFor="let type of allTypes$ | async" [value]="type">{{type}}</md-option>
          </md-select-->
          <div fxFlex="22%"></div>

          <!-- Status shows on New or on an existing that's not branch to branch -->
          <md-select *ngIf="!isEditMode || (currentSale.customer_name && isEditMode)" fxFlex="22%" placeholder="Status" name="cityCountry"
            [(ngModel)]="currentSale.status">
            <md-option *ngFor="let status of availableStatuses" [value]="status">{{status}}</md-option>
          </md-select>
          <!-- Empty shows on branch to branch, edit Only -->
          
          <!--md-input-container fxFlex="22%" *ngIf="!isEditMode || currentSale.customer_name">
            <input mdInput readonly placeholder="Salesman" [ngModel]="currentSale.salesman">
          </md-input-container-->
          <div fxFlex="22%"></div>
          


          <md-select fxFlex="22%" [disabled]="isEditMode && !currentSale.customer_name" placeholder="Branch" name="branch" [(ngModel)]="currentSale.branch_id"
            (ngModelChange)="updateTax($event); filterSalesmen(); filterCustomers()">
            <md-option *ngFor="let branch of filteredBranches" [value]="branch.id">{{branch.code}}/{{branch.name}}</md-option>
          </md-select>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="47%">
            <textarea mdInput placeholder="Notes" [(ngModel)]="currentSale.notes">{{currentSale.notes}}</textarea>
          </md-input-container>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Shipping Charges" [(ngModel)]="currentSale.shipping_charges">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Discount" [(ngModel)]="currentSale.discount">
          </md-input-container>

          <!-- Accept/Decline show on branch to branch, edit Only -->
          <div fxFlex="11%" *ngIf="isEditMode && !currentSale.customer_name"></div>
          <div fxFlex="33%" *ngIf="isEditMode && !currentSale.customer_name" fxFlexAlign="end" fxFlexAlign.lt-md="end" fxLayoutGap="6px">
            <button *ngIf="currentSale.status=='RESERVED' && _authService.hasRole('Sales') && _authService.isAdmin()" md-button class="bg-blue color-white"
              color="primary" (click)="acceptB2B()">Accept</button>
            <button *ngIf="currentSale.status=='RESERVED' && _authService.hasRole('Sales') && _authService.isAdmin()" md-button class="bg-blue color-white"
              color="primary" (click)="changeB2B()">Change</button>
            <button *ngIf="currentSale.status=='RESERVED' && _authService.hasRole('Sales') && _authService.isAdmin()" md-button class="bg-red color-white"
              color="primary" (click)="declineB2B()">Decline</button>
          </div>

        </div>
      </md-card-content>
    </md-card>

    <!-- SOLD ITEMS -->
    <md-card *ngIf="((!isEditMode || (currentSale.customer_name && isEditMode)) && !isConfirmed()) && !_authService.isViewer()">
      <md-card-subtitle class="bg-grey color-white">
        <div layout="row" layout-align="center center">
          <span class="md-title">Search Sale History</span>
          <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="Search Here" (searchDebounce)="search($event)"
            flex>Search Sold Items
          </td-search-box>
        </div>
      </md-card-subtitle>

      <div class="mat-table-container tmp-driven-table">
        <table td-data-table class="duplicated-thead-fix">
          <thead>
            <tr td-data-table-column-row>
              <td td-data-table-cell>
                <md-checkbox flex color="primary" (click)="toggleSelectAll(!_selectAll)" [(ngModel)]="_selectAll"></md-checkbox>
              </td>
              <th td-data-table-column *ngFor="let column of columns_existing" [numeric]="column.numeric">
                {{column.label}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr td-data-table-row *ngFor="let row of filteredData">
              <td td-data-table-cell>
                <md-checkbox flex color="primary" (ngModelChange)="row['selected'] = !row['selected']; _selectAll = shouldSelectAll()" [(ngModel)]="row['selected']"></md-checkbox>
              </td>
              <td td-data-table-cell>{{ row['collection_name'] }}</td>
              <td td-data-table-cell>{{ row['design_name']}}/{{row['color_name'] }}</td>
              <td td-data-table-cell>{{ row['piece_number'] }}</td>
              <td td-data-table-cell>{{ row['lot_number'] }}</td>
              <td td-data-table-cell>{{ row['grade_code'] }}</td>
              <td td-data-table-cell>{{ row['length'] }}</td>
              <td td-data-table-cell>{{ row['weight'] }}</td>
              <td td-data-table-cell>{{ row['original_price'] }}</td>
            </tr>
            <tr td-data-table-column-row class="total-row no-border default">
              <td td-data-table-cell></td>
              <td td-data-table-cell>Total</td>
              <td td-data-table-cell></td>
              <td td-data-table-cell></td>
              <td td-data-table-cell></td>
              <td td-data-table-cell>{{ filteredData.length }} PCS</td>
              <td td-data-table-cell>{{ total(filteredData, 'length')}} m</td>
              <td td-data-table-cell>{{ total(filteredData, 'weight') }} Kg</td>
              <td td-data-table-cell></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div fxLayout='row' fxLayout.lt-md="column">
        <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSizes]="[10, 50, 100, 1000, 'All']"
          [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal" (change)="page($event)">
          <span td-paging-bar-label hide-xs>Row per page:</span>
          <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
          <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
          <span *ngIf="pagingBar.pageSize == 'All'">All </span>
          <span hide-xs>{{pagingBar.total}} items</span>
        </td-paging-bar>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" layout-margin>
        <button md-raised-button color="primary" (click)="addItemsToOrder(filteredData)">ADD ({{totalChecked(filteredData, 'length')}} m)</button>
      </div>
      <br>
    </md-card>
    <!-- END Current Inventory -->

    <md-card>
      <md-card-header fxLayout="column">
        <md-card-subtitle class="bg-grey color-white text-only">Order items
        </md-card-subtitle>
      </md-card-header>
      <md-card-content fxLayout="column" *ngIf="currentSale">

        <!--app-sale-items></app-sale-items-->
        <!--div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">

                  <md-select fxFlex="22%" placeholder="Collection" name="supplierPrefix">
                      <md-option *ngFor="let collection of allCollections$ | async" [value]="collection">{{collection}}</md-option>
                  </md-select>
                  <md-select fxFlex="22%" placeholder="Design / Color" name="supplierPrefix">
                      <md-option *ngFor="let designColor of allDesignColors$ | async" [value]="designColor">{{designColor}}</md-option>
                  </md-select>

                  <md-input-container fxFlex="10%">
                      <input mdInput placeholder="Pieces">
                  </md-input-container>

                  <md-input-container fxFlex="10%">
                      <input mdInput placeholder="Meters">
                  </md-input-container>

                  <md-input-container fxFlex="12.5%">
                      <input mdInput placeholder="Unit Price (CAD)">
                  </md-input-container>
              </div-->
        <div class="mat-table-container tmp-driven-table">
          <table td-data-table class="duplicated-thead-fix">
            <thead>
              <tr td-data-table-column-row>
                <th td-data-table-column *ngFor="let column of columns" [numeric]="column.numeric">
                  {{column.label}}
                </th>
                <th td-data-table-column>
                  Total
                </th>
                <th td-data-table-column>

                </th>
              </tr>
            </thead>
            <tbody>
              <tr td-data-table-row *ngFor="let row of items" [style.background-color]="(row['quantity']*1 < row['full_length']*1)?'lightyellow':''">
                <td td-data-table-cell>{{ row['collection_name'] }}</td>
                <td td-data-table-cell>{{ row['design_name']}}/{{row['color_name'] }}</td>
                <td td-data-table-cell>{{ row['piece_number'] }}</td>
                <td td-data-table-cell>{{ row['lot_number'] }}</td>
                <td td-data-table-cell>{{ row['grade_code'] }}</td>
                
                <td td-data-table-cell class="linkCursor" (click)="enterNewValueDialog(row, 'quantity')">
                  <span>{{ row['quantity'] }}</span>
                  <span *ngIf="row['quantity']*1 < row['full_length']*1">&nbsp;of {{ row['full_length']}}</span>
                </td>
                <td td-data-table-cell class="linkCursor" (click)="enterNewValueDialog(row, 'unit_price')">
                  <span>{{ row['unit_price'] | currency:_currency_symbol:'true'}}</span>
                </td>
                <td td-data-table-cell style="text-align:right">{{ row['quantity'] * row['unit_price'] | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell>
                  <a (click)="removeItem(row.id)" class="linkCursor" *ngIf="((((currentSale.customer_name && isEditMode) || !isEditMode) && !isConfirmed()) || _change_b2b_mode) && !_authService.isViewer()">
                    <md-icon class="rotate45">add_circle</md-icon>
                  </a>
                </td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low first">
                <td td-data-table-cell>{{ 'sub_total' | translate:_authService.getLanguage() }}</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ total(items, 'quantity') }} m</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell style="text-align:right">{{ totalProduct(items, 'quantity', 'unit_price')| currency:_currency_symbol:'true' }}</td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>Shipping Charges</td>
                <td td-data-table-cell style="text-align:right">{{currentSale.shipping_charges | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>Discount</td>
                <td td-data-table-cell style="text-align:right">- {{currentSale.discount | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell (click)="!isConfirmed() && openModifyTax1()" style="cursor:pointer">GST ({{ currentSale.tax }}%)</td>
                <td td-data-table-cell style="text-align:right">{{(totalProduct(items, 'quantity', 'unit_price') + (currentSale.shipping_charges*1) - (currentSale.discount*1))
                  * (currentSale.tax/100) | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell (click)="!isConfirmed() && openModifyTax2()" style="cursor:pointer">QST ({{ currentSale.tax2 }}%)</td>
                <td td-data-table-cell style="text-align:right">{{(totalProduct(items, 'quantity', 'unit_price') + (currentSale.shipping_charges*1) - (currentSale.discount*1))
                  * (currentSale.tax2/100) | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>Total</td>
                <td td-data-table-cell style="text-align:right">{{ (totalProduct(items, 'quantity', 'unit_price') + (currentSale.shipping_charges*1) - (currentSale.discount*1))
                  * (1 + (currentSale.tax/100) + (currentSale.tax2/100)) | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
              </tr>
            </tbody>
          </table>
        </div>
        
      </md-card-content>
      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button [disabled]="isLoading()" *ngIf="!isEditMode && !_authService.isViewer()" color="primary" class="bg-blue color-white" (click)="createNewRefundOrder()">Create</button>
          <button md-button [disabled]="isLoading()" *ngIf="isEditMode && !wasConfirmed() && !_authService.isViewer()" color="primary" class="bg-blue color-white"
            (click)="updateRefundOrder()">Update</button>
          <button md-button [disabled]="isLoading()" color="warn" class="bg-red color-white " (click)="goBack()">Back</button>
        </div>
      </md-card-actions>
    </md-card>
  </div>
</div>
