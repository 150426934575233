<div fxLayout="column">

  <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
    <md-icon>warning</md-icon>
    <span>You have unsaved changes</span>
  </div>

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>{{ 'admin' | translate:_authService.getLanguage() }} / {{ 'options' | translate:_authService.getLanguage() }} / </span>
          <!-- / {{ currentOption.name }} -->
        </md-card-title>
      </md-card-header>

      <md-card-subtitle class="bg-grey color-white text-only">{{ 'user_options' | translate:_authService.getLanguage() }}</md-card-subtitle>

      <md-card-content fxLayout="column" *ngIf="currentUser">

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-select placeholder="{{ 'language' | translate:_authService.getLanguage() }}" fxFlex="22%" [(ngModel)]="currentUser.language">
            <md-option *ngFor="let language of languages" [value]="language.id">
              <img width="20px" style="vertical-align:middle" src="{{language.icon}}">
              <span>{{language.name}}</span>
            </md-option>
          </md-select>
        </div>

        <md-card-subtitle class="bg-grey color-white text-only">{{ 'printer_options' | translate:_authService.getLanguage() }}</md-card-subtitle>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">

        </div>

        <md-card-actions>
          <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
            <button md-button *ngIf="isEditMode" color="primary" class="bg-blue color-white" (click)="saveUser()">{{ 'save' | translate:_authService.getLanguage() }}</button>
            <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
          </div>
        </md-card-actions>
      </md-card-content>
    </md-card>
  </div>
</div>