<div fxLayout="column">
    
    <div class="boxed-content-wrapper">
        <md-card>
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>Return Orders / List</span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white">
                    <div layout="row">
                        <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="Search Here" (searchDebounce)="search($event)"
                            flex>
                        </td-search-box>
                        <form class="push-right-sm" fxLayoutGap="16px">
                            <md-select placeholder="Branch" [(ngModel)]="branchFilter" (ngModelChange)="filter()" name="branch">
                                <md-option [value]="'ALL'">All Branches</md-option>
                                <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.code">{{branchItem.code}} - {{branchItem.name}}</md-option>
                            </md-select>
                        </form>
                        <!-- If you wanted ALL data replace "filteredData" with "data" -->
                        <button md-icon-button [mdMenuTriggerFor]="exportMenu">
                            <md-icon>cloud_download</md-icon>
                        </button>

                        <md-menu #exportMenu="mdMenu">
                            <button md-menu-item (click)="_exportService.asCsv(filteredData, 'Returns')">
                                <md-icon>cloud_download</md-icon>Export this list</button>
                            <!--button md-menu-item (click)="salesReport()">
                                  <md-icon>assignment_returned</md-icon>Sales Report</button>
                              <button md-menu-item (click)="branchTransferReport()">
                                  <md-icon>assignment_returned</md-icon>Branch Transfer Report</button-->
                        </md-menu>
                    </div>
                </md-card-subtitle>
            </md-card-header>

            <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
                <md-divider></md-divider>
                <div class="mat-table-container tmp-driven-table">
                    <table td-data-table class="duplicated-thead-fix">
                        <thead>
                            <tr td-data-table-column-row>
                                <th td-data-table-column>
                                    <md-checkbox flex color="primary" (click)="selectAll()" [checked]="allSelected()"></md-checkbox>
                                </th>
                                <th td-data-table-column *ngFor="let column of columns" [active]="sortBy == column.name" [name]="column.name" [sortOrder]="sortOrder"
                                    [sortable]="true" (sortChange)="sort($event)" [numeric]="false">
                                    {{column.label}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr td-data-table-row *ngFor="let row of filteredData">
                                <td td-data-table-cell>
                                    <md-checkbox flex color="primary" (change)="select($event.checked, row)" [checked]="rowSelected(row)"></md-checkbox>
                                </td>
                                <td td-data-table-cell>
                                    <div layout="row">
                                        <a class="tableLinks" href="#" [routerLink]="['/refunds', 'edit', row['id']]">{{row.order_number}}</a>
                                    </div>
                                </td>
                                <td td-data-table-cell>{{row.invoice_number}}</td>
                                <td td-data-table-cell>{{row.customer_name?row.customer_name:'(Branch Transfer)'}}</td>
                                <td td-data-table-cell>{{row.salesman}}</td>
                                <td td-data-table-cell>{{row.order_date?.substring(0, 10)}}</td>
                                <td td-data-table-cell>{{row.pieces}}</td>
                                <td td-data-table-cell>{{row.quantity | number:'1.2-2'}}</td>
                                <td td-data-table-cell>{{row.branch_code}} - {{row.branch_name}}</td>
                                <td td-data-table-cell>{{row.status}}</td>
                            </tr>
                            <tr td-data-table-column-row class="total-row no-border default">
                                <td td-data-table-cell></td>
                                <td td-data-table-cell>Total (Page)</td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell>{{ total (filteredData, 'pieces') }}</td>
                                <td td-data-table-cell>{{ total (filteredData, 'quantity') | number:'1.2-2'}}</td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                            </tr>
                            <tr td-data-table-column-row class="total-row no-border default">
                                <td td-data-table-cell></td>
                                <td td-data-table-cell>Total (All)</td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell>{{ total (data, 'pieces') }}</td>
                                <td td-data-table-cell>{{ total (data, 'quantity') | number:'1.2-2'}}</td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div fxLayout='row' fxLayout.lt-md="column">
                    <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSizes]="[10, 50, 100, 1000, 'All']"
                        [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal" (change)="page($event)">
                        <span td-paging-bar-label hide-xs>Row per page:</span>
                        <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
                        <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
                        <span *ngIf="pagingBar.pageSize == 'All'">All </span>
                        <span hide-xs>{{pagingBar.total}} items</span>
                    </td-paging-bar>
                </div>
            </md-card-content>
            <md-card-actions *ngIf="!_authService.isViewer()">
                <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                    <button md-button color="primary" class="bg-blue color-white" routerLink="/refunds/new">New</button>
                    <button md-button color="warn" class="bg-red color-white" (click)="openConfirm()">Delete</button>
                </div>
            </md-card-actions>
        </md-card>
    </div>
</div>