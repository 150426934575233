<div fxLayout="column">
  
  <div class="boxed-content-wrapper cards-no-gaps">
      <md-card>
          <md-card-header fxLayout="column">
              <md-card-title>
                  <span class="md-title" fxFlex=100>
                      PDA / <a [routerLink]="['/pda/inventory']">{{ 'inventory' | translate:_authService.getLanguage() }}</a> / {{ 'print_location_label' | translate:_authService.getLanguage() }}
                  </span>
              </md-card-title>
              <md-card-subtitle class="bg-grey color-white text-only">{{ 'print_location_label' | translate:_authService.getLanguage() }}</md-card-subtitle>
          </md-card-header>
          <md-card-content fxLayout="column">
            <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                <md-input-container fxFlex="47%">
                    <input mdInput placeholder="{{ 'location' | translate:_authService.getLanguage() }}" [(ngModel)]="locationToPrint">
                </md-input-container>
            </div>
          </md-card-content>

          <md-card-actions>
              <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                  <button md-button color="primary" class="bg-blue color-white" (click)="print()">{{ 'print' | translate:_authService.getLanguage() }}</button>
                  <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
              </div>
          </md-card-actions>
      </md-card>
  </div>
</div>
