<div flex layout="column" layout-wrap layout-margin style="min-width: 400px;">

  <md-toolbar>New Advance Payment</md-toolbar>


  <md-input-container flex layout-margin>
    <input mdInput flex placeholder="Prefix - Customer" [mdAutocomplete]="autoCustomer" [(ngModel)]="cust"
      (ngModelChange)="filter(cust);">
    <md-autocomplete #autoCustomer="mdAutocomplete">
      <md-option *ngFor="let customer of matchingCustomers" [value]="customer?.prefix + ' - ' + customer?.name"
        (click)="setSelected(customer)">
        {{customer?.prefix + ' - ' + customer?.name}}
      </md-option>
    </md-autocomplete>
  </md-input-container>


  <md-select [(ngModel)]="branch_id" placeholder="Branch" flex>
    <md-option *ngFor="let b of allBranches$ | async" [value]="b.id">{{b.code}} - {{b.name}}</md-option>
  </md-select>

  <md-input-container flex>
    <input mdInput placeholder="Invoice date" type="date" [(ngModel)]="invoice_date">
  </md-input-container>

  <md-input-container flex>
    <input mdInput placeholder="Total" type="number" step="0.01" [(ngModel)]="total">
  </md-input-container>

  <md-select [(ngModel)]="bank_id" placeholder="Deposit {{ total }} to Bank" flex>
    <md-option *ngFor="let b of allBanks$ | async" [value]="b.id">{{b.name}}</md-option>
  </md-select>

  <md-input-container flex>
    <input mdInput placeholder="Description" [(ngModel)]="description">
  </md-input-container>

  <div flex layout="row" layout-padding layout-wrap>
    <button md-button md-raised-button color="primary" (click)="performAction(true)">SAVE</button>
    <span flex></span>
    <button md-button md-raised-button color="warn" (click)="performAction(false)">CANCEL</button>
  </div>
</div>

<!--
  {
    customer_id: customer_id,
    branch_id: branch_id,
    invoice_date: invoice_date,
    type: type,
    total: total
  },
-->
