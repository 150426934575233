<div fxLayout="column">

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex="100">Accounting /
            <a [routerLink]="['/accounting/invoices']">Invoice List</a> / New Payment
          </span>
        </md-card-title>
      </md-card-header>
      <md-card-content fxLayout="column">

        <md-card-subtitle class="bg-grey color-white text-only">Customer Payment</md-card-subtitle>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-select fxFlex="22%" placeholder="Branch" [(ngModel)]="branch_id"
            (ngModelChange)="currency=getCurrency(account_id)">
            <md-option *ngFor="let branch of allBranches$|async" [value]="branch.id">{{ branch.code}} - {{branch.name}}
            </md-option>
          </md-select>

          <md-select fxFlex="22%" placeholder="Accounts" [(ngModel)]="account_id"
            (ngModelChange)="currency=getCurrency($event)">
            <md-option *ngFor="let account of allAccounts$|async" [value]="account.id">{{account.name}}</md-option>
            <md-option [value]="0">Cheque</md-option>
          </md-select>

          <md-input-container fxFlex="22%">
            <input mdInput readonly placeholder="Currency" [ngModel]="currency">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput type="date" placeholder="Received Date" [(ngModel)]="payment_date">
          </md-input-container>
        </div>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">

          <md-input-container fxFlex="22%">
            <input mdInput flex placeholder="Prefix - Customer" [mdAutocomplete]="autoCustomer" [(ngModel)]="cust"
              (ngModelChange)="filterCustomer(cust);">
            <md-autocomplete #autoCustomer="mdAutocomplete">
              <md-option *ngFor="let customer of matchingCustomers" [value]="customer?.prefix + ' - ' + customer?.name"
                (click)="setSelected(customer)">
                {{customer?.prefix + ' - ' + customer?.name}}
              </md-option>
            </md-autocomplete>
          </md-input-container>

          <md-input-container fxFlex="22%" *ngIf="account_id==0">
            <input mdInput placeholder="Endorsement" [(ngModel)]="endorsement">
          </md-input-container>
          <md-input-container fxFlex="22%" *ngIf="account_id==0">
            <input mdInput type="date" placeholder="Maturity Date" [(ngModel)]="maturity_date">
          </md-input-container>
          <md-input-container fxFlex="22%" *ngIf="account_id==0">
            <input mdInput placeholder="Cheque #" [(ngModel)]="cheque_number">
          </md-input-container>
        </div>

        <!-- Outstanding invoices -->
        <md-card-subtitle class="bg-grey color-white">
          <div layout="row" layout-align="center center">
            <span class="md-title">Outstanding Invoices</span>
            <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="Search Here"
              (searchDebounce)="search($event)" flex>Outstanding Invoices
            </td-search-box>
          </div>
        </md-card-subtitle>

        <div class="mat-table-container tmp-driven-table">
          <table td-data-table class="duplicated-thead-fix">
            <thead>
              <tr td-data-table-column-row>
                <td td-data-table-cell>
                  <md-checkbox flex color="primary" (click)="toggleSelectAll(!_selectAll)" [(ngModel)]="_selectAll">
                  </md-checkbox>
                </td>
                <th td-data-table-column *ngFor="let column of columns_outstanding" [numeric]="column.numeric">
                  {{column.label}}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr td-data-table-row *ngFor="let row of filteredData">
                <td td-data-table-cell>
                  <md-checkbox flex color="primary"
                    (ngModelChange)="row['selected'] = !row['selected']; _selectAll = shouldSelectAll()"
                    [(ngModel)]="row['selected']"></md-checkbox>
                </td>
                <td td-data-table-cell>{{ row['invoice_number']?row['invoice_number']:row['type'] }}</td>
                <td td-data-table-cell>{{ row['invoice_date']?.substring(0, 10) }}</td>
                <td td-data-table-cell>{{ row['due_date']?.substring(0, 10) }}</td>
                <td td-data-table-cell>{{ row['total'] | number:'1.2-2'}}</td>
                <td td-data-table-cell>{{ row['total'] - row['processed'] | number:'1.2-2'}}</td>
                <td td-data-table-cell>{{ row['cheques'] | number:'1.2-2'}}</td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border default">
                <td td-data-table-cell></td>
                <td td-data-table-cell>Total</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ total(filteredData, 'total') | number:'1.2-2'}}</td>
                <td td-data-table-cell>{{ total(filteredData, 'total') - total(filteredData, 'processed') |
                  number:'1.2-2'}}</td>
                <td td-data-table-cell>{{ total(filteredData, 'cheques') | number:'1.2-2'}}</td>
              </tr>
            </tbody>

          </table>
        </div>
        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar
            [pageSize]="_settingsService.getPageSize()" [pageSizes]="[10, 50, 100, 1000, 'All']" [total]="filteredTotal"
            (change)="page($event)">
            <span td-paging-bar-label hide-xs>Row per page:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">All </span>
            <span hide-xs>{{pagingBar.total}} items</span>
          </td-paging-bar>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" layout-margin>
          <button md-raised-button color="primary" (click)="addItemsToPayable(filteredData)">ADD ({{
            totalChecked(filteredData, 'total') - totalChecked(filteredData, 'processed') | number:'1.2-2'}})</button>
        </div>

        <!-- Items to pay for -->
        <md-card-subtitle class="bg-grey color-white text-only">Apply Payment To</md-card-subtitle>

        <div class="mat-table-container tmp-driven-table">
          <table td-data-table class="duplicated-thead-fix">
            <thead>
              <tr td-data-table-column-row>
                <th td-data-table-column *ngFor="let column of columns_payable" [numeric]="column.numeric">
                  {{column.label}}
                </th>
                <th td-data-table-column></th>
              </tr>
            </thead>
            <tbody>
              <tr td-data-table-row *ngFor="let row of payableInvoices"
                [style.background-color]="(row['length']*1 < row['full_length']*1)?'lightyellow':''">
                <td td-data-table-cell>{{ row['invoice_number']?row['invoice_number']:row['type'] }}</td>
                <td td-data-table-cell>{{ row['invoice_date']?.substring(0, 10) }}</td>
                <td td-data-table-cell>{{ row['total'] | number:'1.2-2'}}</td>
                <td td-data-table-cell class="linkCursor" (click)="enterNewValueDialog(row, 'paid')">
                  <span>{{ row['paid'] | number:'1.2-2'}} <md-icon class="md-18">create</md-icon></span>
                </td>
                <td td-data-table-cell class="linkCursor" (click)="enterNewValueDialog(row, 'discount')">
                  <span>{{ row['discount'] | number:'1.2-2'}} <md-icon class="md-18">create</md-icon></span>
                </td>
                <td td-data-table-cell>{{ row['total'] - row['processed'] - row['paid'] - row['discount'] |
                  number:'1.2-2'}}</td>
                <td td-data-table-cell>
                  <a (click)="removeItem(row.id); filter()" class="linkCursor">
                    <md-icon class="rotate45">add_circle</md-icon>
                  </a>
                </td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border default">
                <td td-data-table-cell>Total</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ total(payableInvoices, 'total') | number:'1.2-2'}}</td>
                <td td-data-table-cell>{{ total(payableInvoices, 'paid') | number:'1.2-2'}}</td>
                <td td-data-table-cell>{{ total(payableInvoices, 'discount') | number:'1.2-2'}}</td>
                <td td-data-table-cell>{{ total(payableInvoices, 'total') - total(payableInvoices, 'processed') -
                  total(payableInvoices, 'paid') - total(payableInvoices, 'discount') | number:'1.2-2'}}</td>
                <td td-data-table-cell></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar
            [pageSize]="_settingsService.getPageSize()" [pageSizes]="[10, 50, 100, 1000, 'All']" [total]="payableTotal"
            (change)="page($event)">
            <span td-paging-bar-label hide-xs>Row per page:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">All </span>
            <span hide-xs>{{pagingBar.total}} items</span>
          </td-paging-bar>
        </div>

      </md-card-content>

      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxLayoutGap="12px" fxFlexAlign.lt-md="center">
          <button [disabled]="_creating" md-button color="primary" class="bg-blue color-white"
            (click)="pay()">Create</button>
          <button [disabled]="_creating" md-button color="warn" class="bg-red color-white"
            (click)="goBack()">Back</button>
        </div>
      </md-card-actions>
    </md-card>
  </div>
</div>
