<div fxLayout="column">
  <h4>Select date range</h4>
  <md-input-container fxFlex="22%">
    <input mdInput type="date" placeholder="Date From" [(ngModel)]="from">
  </md-input-container>
  <md-input-container fxFlex="22%">
    <input mdInput type="date" placeholder="Date To" [(ngModel)]="to">
  </md-input-container>

    <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxLayoutGap="12px" fxFlexAlign.lt-md="center">
      <button md-button color="primary" class="bg-blue color-white" (click)="close({from: from, to:to})">Accept</button>
      <button md-button color="warn" class="bg-red color-white" (click)="close(null)">Cancel</button>
    </div>

</div>