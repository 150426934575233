<div fxLayout="column">
  <div class="boxed-content-wrapper">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>

          <span class="md-title" fxFlex=100>{{ 'accounting' | translate:_authService.getLanguage() }} /
            <a [routerLink]="['/accounting/bank']">{{ 'account' | translate:_authService.getLanguage() }}</a> /
            {{currentLedger.bank_name}}</span>

        </md-card-title>
        <md-card-subtitle class="bg-grey color-white">
          <div layout="row">
            <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="{{ 'search_here' | translate:_authService.getLanguage() }}"
              (searchDebounce)="search($event)" flex>
            </td-search-box>
            <!-- If you wanted ALL data replace "filteredData" with "data" -->
            <button md-icon-button [mdMenuTriggerFor]="exportMenu">
              <md-icon>cloud_download</md-icon>
            </button>

            <md-menu #exportMenu="mdMenu">
              <button md-menu-item (click)="_exportService.asCsv(filteredData, currentLedger.bank_name + 'Ledger')">
                <md-icon>cloud_download</md-icon>{{ 'export_this_list' | translate:_authService.getLanguage() }}
              </button>
              <button md-menu-item (click)="cashReport()">
                <md-icon>assignment_returned</md-icon>{{ 'cash_report' | translate:_authService.getLanguage() }}
              </button>
            </md-menu>
          </div>
        </md-card-subtitle>
      </md-card-header>

      <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
        <md-divider></md-divider>
        <td-data-table #dataTable [data]="filteredData" [columns]="columns" [selectable]="true" [multiple]="true"
          [sortable]="true" [sortOrder]="'DESC'" (sortChange)="sort($event)" [(ngModel)]="selectedRows">

          <ng-template tdDataTableTemplate="transaction" let-value="value" let-row="row" let-column="column">
            {{value}}
          </ng-template>
          <ng-template tdDataTableTemplate="last_updated" let-value="value" let-row="row" let-column="column">
            {{value?.substring(0, 10)}}
          </ng-template>
          <ng-template tdDataTableTemplate="in" let-value="value" let-row="row" let-column="column">
            {{value | number:'1.2-2'}}
          </ng-template>
          <ng-template tdDataTableTemplate="out" let-value="value" let-row="row" let-column="column">
            {{value | number:'1.2-2'}}
          </ng-template>
          <ng-template tdDataTableTemplate="vat" let-value="value" let-row="row" let-column="column">
            {{value | number:'1.2-2'}}
          </ng-template>
          <ng-template tdDataTableTemplate="balance" let-value="value" let-row="row" let-column="column">
            {{value | number:'1.2-2'}}
          </ng-template>
        </td-data-table>


        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSizes]="[10, 50, 100, 1000, 'All']"
            [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal" (change)="page($event)">
            <span td-paging-bar-label hide-xs>{{ 'rows_per_page' | translate:_authService.getLanguage() }}:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs> {{ 'of' | translate:_authService.getLanguage() }}</span>
            <span *ngIf="pagingBar.pageSize == 'All'"> {{ 'all' | translate:_authService.getLanguage() }}</span>
            <span hide-xs>{{pagingBar.total}} {{ 'items' | translate:_authService.getLanguage() }}</span>
          </td-paging-bar>
        </div>
      </md-card-content>
      <md-card-actions *ngIf="!_authService.isViewer()">
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button color="primary" class="bg-blue color-white" [mdMenuTriggerFor]="ledgerMenu">{{ 'new' |
            translate:_authService.getLanguage() }}
            <md-icon>arrow_drop_down</md-icon>
          </button>
        </div>
        <md-menu #ledgerMenu="mdMenu" yPosition="below" xPosition="after" [overlapTrigger]="false">
          <md-nav-list>
            <a md-list-item [routerLink]="['/accounting','transfer', currentLedger.bank_id]">{{ 'transfer' |
              translate:_authService.getLanguage() }}</a>
            <a md-list-item [routerLink]="['/accounting','deposit', currentLedger.bank_id]">{{ 'deposit' |
              translate:_authService.getLanguage() }}</a>
            <a md-list-item [routerLink]="['/accounting','withdrawal', currentLedger.bank_id]">{{ 'withdrawal' |
              translate:_authService.getLanguage() }}</a>
          </md-nav-list>
        </md-menu>
      </md-card-actions>
    </md-card>
  </div>
</div>
