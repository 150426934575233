<div fxLayout="column">
    
    <div class="boxed-content-wrapper cards-no-gaps">
        <md-card>
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>
                        PDA /
                        <a [routerLink]="['/pda/inventory']">{{ 'inventory' | translate:_authService.getLanguage() }}</a> / {{ 'cut_item' | translate:_authService.getLanguage() }}
                    </span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white text-only">{{ 'cut_item' | translate:_authService.getLanguage() }}</md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column">
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="{{ 'piece_number' | translate:_authService.getLanguage() }}" [(ngModel)]="pieceNumber">
                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <button md-button color="primary" class="bg-blue color-white" (click)="findItem(pieceNumber)">{{ 'find' | translate:_authService.getLanguage() }}</button>
                </div>
                <div *ngIf="fullLength && fullLength > 0" class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
                    fxLayoutGap.lt-md="14px">
                    <span>{{ 'available_meters' | translate:_authService.getLanguage() }}: {{fullLength}}</span>
                </div>
                <br/>
                <br/>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="{{ 'new_piece_number' | translate:_authService.getLanguage() }}" [(ngModel)]="newPieceNumber">
                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <input mdInput type="number" placeholder="{{ 'length' | translate:_authService.getLanguage() }}" [(ngModel)]="newLength">
                    </md-input-container>
                </div>
            </md-card-content>

            <md-card-actions>
                <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                    <button md-button color="primary" class="bg-blue color-white" (click)="cutItem()">{{ 'cut' | translate:_authService.getLanguage() }}</button>
                    <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
                </div>
            </md-card-actions>
        </md-card>
    </div>
</div>