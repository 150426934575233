<div fxLayout="column">
  

  <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
    <md-icon>warning</md-icon>
    <span>{{ 'you_have_unsaved_changes' | translate:_authService.getLanguage() }}</span>
  </div>

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
      <td-steps mode="horizontal" *ngIf="!isDeclined()">
        <td-step label="DRAFT" sublabel="To be completed" [active]="true" [state]="isDraft()?'complete':'none'"
          disabled="true">NEXT: Move to ORDERED status</td-step>
        <td-step label="RESERVED" sublabel="Submit Order to supplier" [state]="isReserved()?'complete':'none'"
          [active]="true" disabled="true">NEXT: Scan pieces in PDA module</td-step>
        <td-step label="APPROVED" sublabel="Approve the Sales Order"
          [state]="isApproved() || isPrepared() || isShipped() ?'complete':'none'" [active]="true" disabled="true">The
          order is part of inventory</td-step>
        <td-step label="PREPARED" sublabel="Scan pieces in PDA prepare" [state]="isPrepared()?'complete':'none'"
          [active]="true" disabled="true">The order is part of inventory</td-step>
        <td-step label="SHIPPED" sublabel="Scan pieces in PDA shipping" [state]="isShipped()?'complete':'none'"
          [active]="true" disabled="true">The order is part of inventory</td-step>
        <td-step *ngIf="isEditMode && !currentSale?.customer_name" label="RECEIVED" sublabel="Pieces Received"
          [state]="isReceived()?'complete':'none'" [active]="true" disabled="true">The order is part of inventory
        </td-step>
      </td-steps>
      <td-steps mode="horizontal" *ngIf="isDeclined()">
        <td-step label="DRAFT" sublabel="To be completed" [active]="true" state="complete" disabled="true"></td-step>
        <td-step label="RESERVED" sublabel="Pieces reserved in inventory" [active]="true" state="complete"
          disabled="true"></td-step>
        <td-step label="DECLINED" sublabel="Order Declined" [active]="true" state="complete" disabled="true"></td-step>
      </td-steps>
      <td-steps mode="horizontal" *ngIf="isCancelled()">
        <td-step label="DRAFT" sublabel="To be completed" [active]="true" state="complete" disabled="true"></td-step>
        <td-step label="RESERVED" sublabel="Pieces reserved in inventory" [active]="true" state="complete"
          disabled="true"></td-step>
        <td-step label="CANCELLED" sublabel="Order Cancelled" [active]="true" state="complete" disabled="true">
        </td-step>
      </td-steps>

      <div flex layout-margin *ngIf="isApproved()">
        <span>{{ preparedStatusText() }}</span>
        <md-progress-bar mode="determinate" [value]="preparedStatusValue()"></md-progress-bar>
      </div>

      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex="100">{{ 'sales_orders' | translate:_authService.getLanguage() }} /
            <a [routerLink]="['/sales/list']">{{ 'order_list' | translate:_authService.getLanguage() }}</a> / {{ (isEditMode && currentSale) ?
                        currentSale.order_number : title }}
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white">
          <div layout="row" layout-align="start center">
            <div class="md-title">{{ 'order_details' | translate:_authService.getLanguage() }}</div>

            <div flex></div>

            <button md-icon-button [mdMenuTriggerFor]="exportMenu">
              <md-icon>cloud_download</md-icon>
            </button>

            <md-menu #exportMenu="mdMenu">
              <button md-menu-item (click)="generatePackingList()">
                <md-icon>assignment_returned</md-icon>{{ 'packing_list' | translate:_authService.getLanguage() }}
              </button>
              <button md-menu-item (click)="generateOrderConfirmation()">
                <md-icon>assignment_returned</md-icon>{{ 'order_confirmation' | translate:_authService.getLanguage() }}
              </button>
              <button md-menu-item (click)="generateLocalInvoice()">
                <md-icon>assignment_returned</md-icon>{{ 'local_invoice' | translate:_authService.getLanguage() }}
              </button>
              <button md-menu-item (click)="generateExportInvoice()">
                <md-icon>assignment_returned</md-icon>{{ 'export_invoice' | translate:_authService.getLanguage() }}
              </button>
            </md-menu>

          </div>

        </md-card-subtitle>


      </md-card-header>
      <md-card-content fxLayout="column" *ngIf="currentSale">
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="22%">
            <input mdInput readonly placeholder="{{ 'order_number' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentSale.order_number">
          </md-input-container>

          <!--div fxFlex="22%" *ngIf="isEditMode && !currentSale.customer_name"></div-->
          <md-select *ngIf="currentSale.type=='B2B'" fxFlex="22%"
            placeholder="{{ 'requesting_branch' | translate:_authService.getLanguage() }}" name="requestingBranch"
            [(ngModel)]="currentSale.requesting_branch_id" [disabled]="currentSale.status!='DRAFT'">
            <md-option *ngFor="let branch of fromBranches$|async" [value]="branch.id">{{branch.code}}/{{branch.name}}
            </md-option>
          </md-select>
          <!-- ngIf was !isEditMode || currentSale.customer_name -->
          <!--md-select *ngIf="currentSale.type!='B2B'" fxFlex="22%" placeholder="{{ 'customer' | translate:_authService.getLanguage() }} / {{ 'prefix' | translate:_authService.getLanguage() }}"
                            name="supplierPrefix" [(ngModel)]="currentSale.customer_id" (ngModelChange)="getPrefixForCustomerId($event); getSalesman($event); filterBranches(); getCustomerCreditInfo()">
                            <md-option *ngFor="let customer of filteredCustomers" [value]="customer.id">{{customer.prefix}}/{{customer.name}}</md-option>
                        </md-select-->
          <md-input-container fxFlex="22%" *ngIf="currentSale.type!='B2B'">
            <input mdInput
              placeholder="{{ 'customer' | translate:_authService.getLanguage() }} / {{ 'prefix' | translate:_authService.getLanguage() }}"
              [mdAutocomplete]="autoCustomer" [(ngModel)]="currentSale.customer_name"
              (ngModelChange)="filterCustomers(); getPrefixForCustomerName($event)">
            <md-autocomplete #autoCustomer="mdAutocomplete">
              <md-option *ngFor="let customer of customerOptions(currentSale.customer_name)" [value]="customer.name">
                <span>{{customer.prefix}}/{{customer.name}}</span>
              </md-option>
            </md-autocomplete>
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'order_date' | translate:_authService.getLanguage() }}" type="date"
              [(ngModel)]="currentSale.order_date">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'delivery_date' | translate:_authService.getLanguage() }}" type="date"
              [(ngModel)]="currentSale.delivery_date">
          </md-input-container>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-select fxFlex="22%" placeholder="{{ 'type' | translate:_authService.getLanguage() }}" name="cityCountry"
            [(ngModel)]="currentSale.type">
            <md-option *ngFor="let type of allTypes$ | async" [value]="type">{{type}}</md-option>
          </md-select>

          <!-- Status shows on New or on an existing that's not branch to branch -->
          <md-select *ngIf="!isEditMode || originalSale.status=='DRAFT' || (currentSale.type!='B2B' && isEditMode)"
            fxFlex="22%" placeholder="{{ 'status' | translate:_authService.getLanguage() }}" name="cityCountry"
            [(ngModel)]="currentSale.status">
            <md-option *ngFor="let status of availableStatuses" [value]="status">{{status}}</md-option>
          </md-select>
          <!-- Empty shows on branch to branch, edit Only -->
          <div fxFlex="22%"
            *ngIf="!(!isEditMode || originalSale.status=='DRAFT' || (currentSale.type!='B2B' && isEditMode))"></div>

          <div fxFlex="22%" *ngIf="currentSale.type=='B2B'"></div>
          <!--md-select *ngIf="!isEditMode || currentSale.customer_name" fxFlex="22%" placeholder="Salesman" [(ngModel)]="currentSale.salesman">
                            <md-option *ngFor="let p of valid_salesmen" [value]="p">{{p}}</md-option>
                        </md-select-->
          <!-- Sales man is readonly and defaults to this customer's salesman -->
          <md-input-container fxFlex="22%" *ngIf="currentSale.type!='B2B'">
            <input mdInput readonly placeholder="{{ 'salesman' | translate:_authService.getLanguage() }}"
              [ngModel]="currentSale.salesman">
          </md-input-container>


          <md-select fxFlex="22%" [disabled]="isEditMode && !currentSale.customer_name"
            placeholder="{{ 'branch' | translate:_authService.getLanguage() }}" name="branch"
            [(ngModel)]="currentSale.branch_id"
            (ngModelChange)="updateTax($event); filterSalesmen(); filterCustomers()">
            <md-option *ngFor="let branch of filteredBranches" [value]="branch.id">{{branch.code}}/{{branch.name}}
            </md-option>
          </md-select>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="47%">
            <textarea mdInput placeholder="{{ 'notes' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentSale.notes">{{currentSale.notes}}</textarea>
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'customer_po_reference' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentSale.customer_po">
          </md-input-container>

          <md-select fxFlex="22%" [disabled]="isEditMode && !currentSale.customer_name"
            placeholder="{{ 'ship_from_branch' | translate:_authService.getLanguage() }}" name="branch"
            [(ngModel)]="currentSale.serve_from_branch_id" (ngModelChange)="updateInventory($event)">
            <md-option *ngFor="let branch of fromBranches$ | async" [value]="branch.id">{{branch.code}}/{{branch.name}}
            </md-option>
          </md-select>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'shipping_charges' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentSale.shipping_charges">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'discount' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentSale.discount">
          </md-input-container>

          <div fxFlex="22%">
            <span>Credit info </span>
            <md-icon *ngIf="currentSale.customer_id" (click)="displayCreditDetails()" class="linkCursor">info</md-icon>
          </div>
        </div>
        <div class="padded-container">
          <button *ngIf="isApproved()" md-button class="bg-blue color-white" color="primary" (click)="backToReserved()">
            <md-icon>keyboard_backspace</md-icon> {{ 'back_to_reserved' | translate:_authService.getLanguage() }}
          </button>

          <button *ngIf="isApproved()" md-button class="bg-red color-white" color="primary" (click)="cancelOrder()">
            <md-icon>cancel</md-icon> {{ 'cancel_order' | translate:_authService.getLanguage() }}
          </button>


          <!-- Accept/Decline show on branch to branch, edit Only -->
          <div class="padded-container" *ngIf="isEditMode && !currentSale.customer_name">
            <button
              *ngIf="currentSale.status=='RESERVED' && originalSale.status=='RESERVED' && _authService.hasRole('Sales') && _authService.isAdmin()"
              md-button class="bg-blue color-white" color="primary" (click)="acceptB2B()">
              <md-icon>playlist_add_check</md-icon> {{ 'accept' | translate:_authService.getLanguage() }}
            </button>

            <button
              *ngIf="currentSale.status=='RESERVED' && originalSale.status=='RESERVED'  && _authService.hasRole('Sales') && _authService.isAdmin()"
              md-button class="bg-blue color-white" color="primary" (click)="changeB2B()">
              <md-icon>playlist_add</md-icon> {{ 'change' | translate:_authService.getLanguage() }}
            </button>

            <button
              *ngIf="currentSale.status=='RESERVED'  && originalSale.status=='RESERVED' && _authService.hasRole('Sales') && _authService.isAdmin()"
              md-button class="bg-red color-white" color="primary" (click)="declineB2B()">
              <md-icon>block</md-icon> {{ 'decline' | translate:_authService.getLanguage() }}
            </button>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <!-- Current Inventory -->
    <!-- isPreparing below was isApproved-->

    <!-- <app-inventory-selector *ngIf="(((!isEditMode || (currentSale.customer_name && isEditMode) || wasDraft()) && !refundMode && ((currentSale.type=='B2B' && !isApproved())|| (currentSale.type!='B2B' && !isPreparing()) ) ) || _change_b2b_mode) && !_authService.isViewer() && !isCancelled()"></app-inventory-selector> -->

    <md-card
      *ngIf="(((!isEditMode || (currentSale.customer_name && isEditMode) || wasDraft()) && !refundMode && ((currentSale.type=='B2B' && !isApproved())|| (currentSale.type!='B2B' && !isPreparing()) ) ) || _change_b2b_mode) && !_authService.isViewer() && !isCancelled()">
      <md-card-subtitle class="bg-grey color-white">
        <div layout="row" layout-align="center center">

          <span class="md-title">{{ 'search_inventory' | translate:_authService.getLanguage() }}</span>
          <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm"
            placeholder="{{ 'search_here' | translate:_authService.getLanguage() }}" (searchDebounce)="search($event)"
            flex>{{ 'search_inventory' | translate:_authService.getLanguage() }}
          </td-search-box>

          <form class="push-right-sm" fxLayoutGap="16px">
            <md-select [disabled]="items.length > 0" placeholder="Fabric type" [(ngModel)]="_selectedFabricTypeId"
              (ngModelChange)="onFabricSelect(_selectedFabricTypeId); updateInventory(currentSale.serve_from_branch_id, _selectedFabricTypeId)"
              name="fabricType" class="fs18">
              <md-option *ngFor="let fabricTypeItem of allFabricTypes$ | async" [value]="fabricTypeItem.id">
                {{fabricTypeItem.name}}</md-option>
            </md-select>
            <md-select placeholder="Unit" [(ngModel)]="_selectedUnit" (ngModelChange)="onUnitChange(_selectedUnit)"
              name="unit" class="fs18">
              <md-option *ngFor="let unit of availableUnits" [value]="unit">{{unit}}</md-option>
            </md-select>
          </form>

        </div>
      </md-card-subtitle>

      <div class="mat-table-container tmp-driven-table">
        <table td-data-table class="duplicated-thead-fix">
          <thead>
            <tr td-data-table-column-row>
              <td td-data-table-cell>
                <md-checkbox flex color="primary" (click)="toggleSelectAll(!_selectAll)" [(ngModel)]="_selectAll">
                </md-checkbox>
              </td>
              <th td-data-table-column *ngFor="let column of columns_existing" [numeric]="column.numeric">
                {{column.label}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr td-data-table-row *ngFor="let row of filteredData">
              <td td-data-table-cell>
                <md-checkbox flex color="primary"
                  (ngModelChange)="row['selected'] = !row['selected']; _selectAll = shouldSelectAll()"
                  [(ngModel)]="row['selected']"></md-checkbox>
              </td>
              <td td-data-table-cell><span>{{ row['collection_name'] }}</span>
              </td>
              <td td-data-table-cell>{{ row['design_name']}}/{{row['color_name'] }}</td>
              <td td-data-table-cell>{{ row['piece_number'] }} <md-icon class="md-18" style="display: inline-flex;
                vertical-align: middle; color:red" *ngIf="row['defect']" title="{{row['defect_comment']}}">warning
                </md-icon>
                <md-icon class="md-18" style="display: inline-flex;
                vertical-align: middle; color:red" *ngIf="row['reserved'] > 0" title="{{row['reserved_comment']}}">
                  bookmark</md-icon>
              </td>
              <td td-data-table-cell>{{ row.lot_number }}</td>
              <td td-data-table-cell>{{ row.grade_code }}</td>
              <td td-data-table-cell>{{ row.length * _selectedUnitMultiplier || 0 | number:'1.2-2'}}</td>
              <td td-data-table-cell>{{ row.weight || 0 | number:'1.2-2'}}</td>
              <td td-data-table-cell>{{ row.location_name }}</td>
            </tr>
            <!-- TODO update the totals-->
            <tr td-data-table-column-row class="total-row no-border default">
              <td td-data-table-cell></td>
              <td td-data-table-cell>{{ 'total' | translate:_authService.getLanguage() }}</td>
              <td td-data-table-cell></td>
              <td td-data-table-cell></td>
              <td td-data-table-cell>{{ filteredData.length }} PCS</td>
              <td td-data-table-cell></td>
              <td td-data-table-cell *ngIf="_selectedUnit == 'Meters'">
                  {{ total(filteredData, 'length') * _selectedUnitMultiplier || 0 | number:'1.2-2' }} {{_selectedUnitSymbol}}</td>

              <td td-data-table-cell>{{ total(filteredData, 'weight') | number:'1.2-2'}} Kg</td>
              <td td-data-table-cell></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div fxLayout='row' fxLayout.lt-md="column">
        <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar
          [pageSizes]="[10, 50, 100, 1000, 'All']" [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal"
          (change)="page($event)">
          <span td-paging-bar-label hide-xs>{{ 'rows_per_page' | translate:_authService.getLanguage() }}:</span>
          <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
          <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
          <span *ngIf="pagingBar.pageSize == 'All'">{{ 'all' | translate:_authService.getLanguage() }} </span>
          <span hide-xs>{{pagingBar.total}} {{ 'items' | translate:_authService.getLanguage() }}</span>
        </td-paging-bar>
      </div>
      <div fxLayout="row" fxLayout.lt-md="column" layout-margin>
        <button md-raised-button color="primary"
          (click)="addItemsToOrder(filteredData)">{{ 'add_capital' | translate:_authService.getLanguage() }} 
          ({{totalChecked(filteredData, 'length') * _selectedUnitMultiplier | number:'1.2-2'}} {{_selectedUnitSymbol}})
        </button>
      </div>
      <br>
    </md-card>
    <!-- END Current Inventory -->

    <md-card>
      <md-card-header fxLayout="column">
        <md-card-subtitle class="bg-grey color-white text-only">
          {{ 'order_items' | translate:_authService.getLanguage() }}
        </md-card-subtitle>
      </md-card-header>
      <md-card-content fxLayout="column" *ngIf="currentSale">
        <div class="mat-table-container tmp-driven-table">
          <table td-data-table class="duplicated-thead-fix">
            <thead>
              <tr td-data-table-column-row>
                <th td-data-table-column>
                  <md-checkbox [checked]="allTagged()" (click)="selectTagAll()"></md-checkbox>
                </th>
                <th td-data-table-column *ngFor="let column of columns" [numeric]="column.numeric">
                  {{column.label}}
                </th>
                <th td-data-table-column>
                  {{ 'total' | translate:_authService.getLanguage() }}
                </th>
                <th td-data-table-column>
                  <!--This is the tag column-->
                </th>
                <th td-data-table-column>
                  <!--This is the remove column-->
                </th>
              </tr>
            </thead>
            <tbody>
              <tr td-data-table-row *ngFor="let row of items"
                [style.background-color]="(row['quantity']*1 < row['full_length']*1)?'lightyellow':''">
                <td td-data-table-cell>
                  <!--md-checkbox [checked]="checkedForTagging(row)" [value]="checkedForTagging(row)" (click)="selectTag(row)"></md-checkbox-->
                  <md-checkbox (ngModelChange)="row['selected'] = !row['selected']" [(ngModel)]="row['selected']">
                  </md-checkbox>
                </td>
                <td td-data-table-cell>
                  <span>{{ row['collection_name'] }}</span>
                </td>
                <td td-data-table-cell>{{ row['design_name']}}/{{row['color_name'] }}</td>
                <td td-data-table-cell>{{ row['piece_number'] }} <md-icon class="md-18" style="display: inline-flex;
                  vertical-align: middle; color:red" *ngIf="row['defect']" title="{{row['defect_comment']}}">warning
                  </md-icon>
                  <md-icon class="md-18" style="display: inline-flex;
                  vertical-align: middle; color:red" *ngIf="row['reserved'] > 0" title="{{row['reserved_comment']}}">
                    bookmark</md-icon>
                </td>
                <td td-data-table-cell>{{ row['lot_number'] }}</td>
                <td td-data-table-cell>{{ row['grade_code'] }}</td>

                <td td-data-table-cell class="linkCursor" (click)="enterNewValueDialog(row, 'quantity', _selectedUnitMultiplier)">
                  <span>{{ row.quantity * _selectedUnitMultiplier || 0 | number:'1.2-2'}} {{_selectedUnitSymbol}}</span>
                  <span *ngIf="row.quantity < row.full_length">&nbsp;of
                    {{ row.full_length * _selectedUnitMultiplier || 0 | number:'1.2-2'}} {{_selectedUnitSymbol}}</span>
                </td>

                <td td-data-table-cell class="linkCursor" (click)="enterNewValueDialog(row, 'unit_price', (1/_selectedUnitMultiplier))">
                  <span>{{ row['unit_price'] / _selectedUnitMultiplier | currency:_currency_symbol:'true'}}</span>
                </td>
                <td td-data-table-cell style="text-align:right">
                  {{ row.quantity * row.unit_price | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell>
                  <a (click)="displayTag(row['tag'])" class="linkCursor" *ngIf="row['tag']">
                    <md-icon>local_offer</md-icon>
                  </a>
                </td>
                <td td-data-table-cell>
                  <a (click)="removeItem(row.id)" class="linkCursor"
                    *ngIf="((((currentSale.customer_name && isEditMode) || !isEditMode) && !isApproved()) || _change_b2b_mode) && !_authService.isViewer()">
                    <md-icon class="rotate45">add_circle</md-icon>
                  </a>
                </td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low first">
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ 'subtotal' | translate:_authService.getLanguage() }}</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <!-- total quantity in selected unit-->
                <td td-data-table-cell>{{ total(items, 'quantity') * _selectedUnitMultiplier | number:'1.2-2' }} {{_selectedUnitSymbol}}</td>
                <td td-data-table-cell></td>

                <!-- total price in unit selected * unit price (per unit selected) -->
                <td td-data-table-cell style="text-align:right">
                  {{ totalProduct(items, 'quantity', 'unit_price') || 0 | currency:_currency_symbol:'true' }}</td>

                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ 'shipping_charges' | translate:_authService.getLanguage() }}</td>
                <td td-data-table-cell style="text-align:right">
                  {{currentSale.shipping_charges | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ 'discount' | translate:_authService.getLanguage() }}</td>
                <td td-data-table-cell style="text-align:right">-
                  {{currentSale.discount | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell (click)="!isApproved() && openModifyTax1()" style="cursor:pointer">
                  {{ 'tax' | translate:_authService.getLanguage() }} ({{ currentSale.tax }}%)</td>
                <td td-data-table-cell style="text-align:right">{{(totalProduct(items, 'quantity', 'unit_price') + (currentSale.shipping_charges*1) - (currentSale.discount*1))
                                    * (currentSale.tax/100) | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell (click)="!isApproved() && openModifyTax2()" style="cursor:pointer">
                  {{ 'tax2' | translate:_authService.getLanguage() }} ({{ currentSale.tax2 }}%)</td>
                <td td-data-table-cell style="text-align:right">{{(totalProduct(items, 'quantity', 'unit_price') + (currentSale.shipping_charges*1) - (currentSale.discount*1))
                                    * (currentSale.tax2/100) | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>Total</td>
                <td td-data-table-cell style="text-align:right">
                  {{ (totalProduct(items, 'quantity', 'unit_price') + (currentSale.shipping_charges*1) - (currentSale.discount*1))
                                    * (1 + (currentSale.tax/100) + (currentSale.tax2/100)) | currency:_currency_symbol:'true'}}
                </td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell>
                  <button md-button md-raised-button color="primary" (click)="addTag()">Add tag</button>
                </td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
              </tr>
            </tbody>
          </table>
        </div>
      </md-card-content>

      <span *ngIf="isPrepared() && nonPreparedItems.length > 0">
        <md-card-header fxLayout="column">
          <md-card-subtitle class="bg-grey color-white text-only">
            {{ 'items_not_shipped' | translate:_authService.getLanguage() }}
          </md-card-subtitle>
        </md-card-header>

        <md-card-content fxLayout="column">
          <div class="mat-table-container tmp-driven-table">
            <table td-data-table class="duplicated-thead-fix">
              <thead>
                <tr td-data-table-column-row>
                  <th td-data-table-column *ngFor="let column of columns" [numeric]="column.numeric">
                    {{column.label}}
                  </th>
                  <th td-data-table-column>
                    {{ 'total' | translate:_authService.getLanguage() }}
                  </th>
                  <th td-data-table-column>

                  </th>
                </tr>
              </thead>
              <tbody>
                <tr td-data-table-row *ngFor="let row of nonPreparedItems"
                  [style.background-color]="(row['quantity']*1 < row['full_length']*1)?'lightyellow':''">
                  <td td-data-table-cell><span>{{ row['collection_name'] }}</span>
                  </td>
                  <td td-data-table-cell>{{ row['design_name']}}/{{row['color_name'] }}</td>
                  <td td-data-table-cell>{{ row['piece_number'] }} <md-icon class="md-18" style="display: inline-flex;
                    vertical-align: middle; color:red" *ngIf="row['defect']" title="{{row['defect_comment']}}">warning
                    </md-icon>
                    <md-icon class="md-18" style="display: inline-flex;
                    vertical-align: middle; color:red" *ngIf="row['reserved'] > 0" title="{{row['reserved_comment']}}">
                      bookmark</md-icon>
                  </td>
                  <td td-data-table-cell>{{ row['lot_number'] }}</td>
                  <td td-data-table-cell>
                    <span>{{ row['quantity'] | number:'1.2-2'}}</span>
                    <span *ngIf="row['quantity']*1 < row['full_length']*1">&nbsp;of {{ row['full_length']}}</span>
                  </td>
                  <td td-data-table-cell>
                    <span>{{ row['unit_price'] | currency:_currency_symbol:'true'}}</span>
                  </td>
                  <td td-data-table-cell style="text-align:right">
                    {{ row['quantity'] * row['unit_price'] | currency:_currency_symbol:'true'}}</td>
                  <td td-data-table-cell></td>
                </tr>

              </tbody>
            </table>
          </div>
        </md-card-content>
      </span>

      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button [disabled]="isLoading()" *ngIf="!isEditMode && !_authService.isViewer()" color="primary"
            class="bg-blue color-white"
            (click)="createNewSalesOrder()">{{ 'create' | translate:_authService.getLanguage() }}</button>
          <!-- isPreparing below was wasApproved()-->

          <button md-button [disabled]="isLoading()"
            *ngIf="isEditMode && ((currentSale.type=='B2B' && !wasApproved()) || (currentSale.type!='B2B' && !isPreparing())) && !isCancelled() && !_authService.isViewer()"
            color="primary" class="bg-blue color-white"
            (click)="updateSalesOrder()">{{ 'update' | translate:_authService.getLanguage() }}</button>

          <!--
          <button md-button [disabled]="isLoading()" color="primary" class="bg-blue color-white "
            [mdMenuTriggerFor]="printMenu">{{ 'print' | translate:_authService.getLanguage() }}</button>
            -->

          <button md-button [disabled]="isLoading()" color="warn" class="bg-red color-white "
            (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
          <!--button md-button color="warn" class="bg-red color-white " *ngIf="!_authService.isViewer() && currentSale.status=='SHIPPED' && currentSale.customer_name !=''"
                            [routerLink]="[ '/sales', 'edit', currentSale.id] " [queryParams]="{refund:true}">REFUND</button-->
        </div>
      </md-card-actions>


    </md-card>
  </div>
</div>
