<div fxLayout="column">
  
  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100> PDA / Import </span>
        </md-card-title>
      </md-card-header>

      <md-card-content fxLayout="column">
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-select fxFlex="30%" placeholder="Branch" [(ngModel)]="branch_id" (ngModelChange)="getLocations()">
            <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.id">{{branchItem.code}} - {{branchItem.name}}</md-option>
          </md-select>
        </div>

        <md-divider></md-divider>

        <h4> Valmust</h4>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-select fxFlex="30%" placeholder="Collection" [(ngModel)]="collection_name" (ngModelChange)="setColor()">
            <md-option *ngFor="let c of filteredCollections" [value]="c">{{c}}</md-option>
          </md-select>
        </div>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="30%">
            <input mdInput placeholder="Design" [(ngModel)]="design_name" list="desAuto" (ngModelChange)="filterColors()">
            <datalist id="desAuto">
              <option *ngFor="let d of filteredDesigns" [value]="d"> {{ d }} </option>
            </datalist>
          </md-input-container>
        </div>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="30%">
            <input mdInput placeholder="Color" [(ngModel)]="color_name" list="colAuto" (ngModelChange)="filterCollections()">
            <datalist id="colAuto">
              <option *ngFor="let c of filteredColors" [value]="c"> {{ c }} </option>
            </datalist>
            <!--md-autocomplete #colAuto="mdAutocomplete">
              <md-option *ngFor="let c of filteredColors" [value]="c"> {{ c }} </md-option>
            </md-autocomplete-->
          </md-input-container>
        </div>

        <md-divider></md-divider>

        <h4>Supplier</h4>
        
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-select fxFlex="30%" placeholder="Supplier Collection" [(ngModel)]="supplier_collection_name" (ngModelChange)="setSupplierColor()">
            <md-option *ngFor="let c of filteredSupplierCollections" [value]="c">{{c}}</md-option>
          </md-select>
        </div>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="30%">
            <input mdInput placeholder="Supplier Design" [(ngModel)]="supplier_design_name" list="desSupplAuto" (ngModelChange)="filterSupplierColors()">
            <datalist id="desSupplAuto">
              <option *ngFor="let d of filteredSupplierDesigns" [value]="d"> {{ d }} </option>
            </datalist>
          </md-input-container>
        </div>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="30%">
            <input mdInput placeholder="Supplier Color" [(ngModel)]="supplier_color_name" list="colSupplAuto" (ngModelChange)="filterSupplierCollections()">
            <datalist id="colSupplAuto">
              <option *ngFor="let c of filteredSupplierColors" [value]="c"> {{ c }} </option>
            </datalist>
            <!--md-autocomplete #colSupplAuto="mdAutocomplete">
              <md-option *ngFor="let c of filteredSupplierColors" [value]="c"> {{ c }} </md-option>
            </md-autocomplete-->
          </md-input-container>
        </div>
        <md-divider></md-divider>
        
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="30%">
            <input mdInput placeholder="Location" [(ngModel)]="location" (ngModelChange)="setLocation()">
          </md-input-container>
        </div>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="14%">
            <input mdInput placeholder="Length (M)" [(ngModel)]="length" (ngModelChange)="setWeight()">
          </md-input-container>
          <md-input-container fxFlex="14%">
            <input mdInput placeholder="Weight (Kg)" [(ngModel)]="weight">
          </md-input-container>
        </div>
      </md-card-content>

      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button color="primary" class="bg-blue color-white" (click)="saveAndPrint()">Save and print</button>
          <button md-button color="warn" class="bg-red color-white" (click)="goBack()">Back</button>
        </div>
      </md-card-actions>

    </md-card>
  </div>
</div>