<div fxLayout="column">
  

  <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
    <md-icon>warning</md-icon>
    <span>You have unsaved changes</span>
  </div>

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>{{ 'purchasing' | translate:_authService.getLanguage() }} /
            <a [routerLink]="['/purchasing/fabrictypes']">{{ 'fabrictypes' | translate:_authService.getLanguage() }}</a>
            / {{ (isEditMode && currentFabrictype) ? currentFabrictype.name : title }}
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white text-only" *ngIf="isEditMode">
          {{ 'edit_fabrictype' | translate:_authService.getLanguage() }}</md-card-subtitle>
        <md-card-subtitle class="bg-grey color-white text-only" *ngIf="!isEditMode">
          {{ 'create_fabrictype' | translate:_authService.getLanguage() }}</md-card-subtitle>
      </md-card-header>
      <md-card-content fxLayout="column" *ngIf="currentFabrictype">

        <div class="padded-container" fxLayout="row" fxLayoutGap="10px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="28">
            <input mdInput required placeholder="{{ 'name' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentFabrictype.name">
            <md-error>{{ 'required' | translate:_authService.getLanguage() }}</md-error>
            <md-error>{{ 'must_be_unique' | translate:_authService.getLanguage() }}</md-error>
          </md-input-container>

          <md-checkbox [(ngModel)]="currentFabrictype.active" fxFlex="20">
            Active
          </md-checkbox>

          <md-checkbox [(ngModel)]="currentFabrictype.default" fxFlex="20">
            Default
          </md-checkbox>

          <md-select fxFlex="28" placeholder="Unit Type" name="unitType" [(ngModel)]="currentFabrictype.unit_type">
            <md-option *ngFor="let u of allUnits" [value]="u.value">{{u.name}}</md-option>
          </md-select>

        </div>

        <md-card-actions *ngIf="!_authService.isViewer()">
          <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
            <button md-button *ngIf="!isEditMode" color="primary" class="bg-blue color-white"
              (click)="createFabricType()">{{ 'create' | translate:_authService.getLanguage() }}</button>
            <button md-button *ngIf="isEditMode" color="primary" class="bg-blue color-white"
              (click)="saveFabricType()">{{ 'save' | translate:_authService.getLanguage() }}</button>
            <button md-button color="warn" class="bg-red color-white"
              (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
          </div>
        </md-card-actions>
      </md-card-content>
    </md-card>
  </div>
</div>
