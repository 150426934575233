
    <div flex layout="column" layout-margin layout-wrap>
      <md-list *ngIf="lines.length > 0">
        <md-list-item *ngFor="let l of lines" (click)="dialogRef.close()">
          <h4 md-line>{{l.po_number}}</h4>
          <p md-line *ngIf="l.id != 0">{{l.qty}} meters - ETA: {{l.estimated_arrival_date}}</p>
          
        </md-list-item>
      </md-list>
      <span *ngIf="lines.length == 0">No ordered meterage</span>
    </div>
  