<div flex layout="column" style="min-width: 500px;" layout-wrap layout-margin>
  <div flex>
    <h4>New Piece</h4>
  </div>
  <md-input-container fxFlex="40%">
    <input mdInput placeholder="{{ 'piece_number' | translate:_authService.getLanguage() }}" [(ngModel)]="piece.piece_no">
  </md-input-container>



  <md-input-container>
    <input mdInput flex placeholder="Collection - Design/Color" [mdAutocomplete]="autoColor" [(ngModel)]="prod"
      (ngModelChange)="filterProducts(prod);">
    <md-autocomplete #autoColor="mdAutocomplete">
      <md-option *ngFor="let product of matchingProducts"
        [value]="product?.collection_name + ' - ' + product?.design_name + '/' + product?.name"
        (click)="setSelected(product)">
        {{product?.collection_name + ' - ' + product?.design_name + '/' + product?.name}}
      </md-option>
    </md-autocomplete>
  </md-input-container>

<!--
  <md-select fxFlex="22%" placeholder="{{ 'fabric_type' | translate:_authService.getLanguage() }}" [(ngModel)]="piece.fabric_type">
    <md-option *ngFor="let fType of ['Upholstery Fabric', 'Clothing Fabric']" [value]="fType">{{ fType}}</md-option>
  </md-select>
  <md-select fxFlex="22%" placeholder="{{ 'collection' | translate:_authService.getLanguage() }}" [(ngModel)]="piece.collection"
    (ngModelChange)="loadDesigns($event)">
    <md-option *ngFor="let c of collectionList()" [value]="c">{{ c }}</md-option>
  </md-select>
  <md-select fxFlex="22%" placeholder="{{ 'design' | translate:_authService.getLanguage() }}" [(ngModel)]="piece.design" (ngModelChange)="loadColors($event)">
    <md-option *ngFor="let d of designList()" [value]="d">{{ d }}</md-option>
  </md-select>
  <md-select fxFlex="22%" placeholder="{{ 'color' | translate:_authService.getLanguage() }}" [(ngModel)]="piece.color">
    <md-option *ngFor="let color of colorList()" [value]="color">{{ color }}</md-option>
  </md-select>
-->

  <md-input-container fxFlex="40%">
    <input mdInput placeholder="{{ 'lot_no' | translate:_authService.getLanguage() }}" [(ngModel)]="piece.lot_no">
  </md-input-container>
  <md-input-container fxFlex="40%">
    <input mdInput placeholder="{{ 'length' | translate:_authService.getLanguage() }}" [(ngModel)]="piece.length">
  </md-input-container>
  <md-input-container fxFlex="40%">
    <input mdInput placeholder="{{ 'weight' | translate:_authService.getLanguage() }}" [(ngModel)]="piece.weight">
  </md-input-container>

  <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
    <button md-button md-raised-button color="primary" class="bg-blue color-white" (click)="confirm()">{{ 'add_piece' | translate:_authService.getLanguage() }}</button>
    <button md-button md-raised-button color="warn" class="bg-red color-white" (click)="_ref.close()">{{ 'close' | translate:_authService.getLanguage() }}</button>
  </div>
</div>