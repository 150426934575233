<div fxLayout="column">
  <div class="boxed-content-wrapper">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>Users / List</span>
        </md-card-title>
        <md-toolbar color="primary">
          <span *ngIf="!searchBox.searchVisible" class="push-left-sm"></span>
          <td-search-box #searchBox backIcon="arrow_back" class="push-right-sm" placeholder="Search here" (searchDebounce)="search($event)"
            flex>
          </td-search-box>
          <!-- If you wanted ALL data replace "filteredData" with "data" -->
          <button md-icon-button (click)="_exportService.asCsv(filteredData, 'Users')">
            <md-icon>cloud_download</md-icon>
          </button>
        </md-toolbar>
      </md-card-header>
      <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
        
        <table td-data-table>
          <thead>
            <tr td-data-table-column-row>
              <th td-data-table-column>
                <md-checkbox flex color="primary" (click)="selectAll()" [checked]="allSelected()"></md-checkbox>
              </th>
              <th td-data-table-column>User Login</th>
              <th td-data-table-column>Status</th>
              <th td-data-table-column *ngFor="let column of columns" [numeric]="column.numeric">
                {{column.label}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr td-data-table-row *ngFor="let row of filteredData">
              <td td-data-table-cell>
                <md-checkbox flex color="primary" (change)="select($event.checked, row)" [checked]="rowSelected(row)"></md-checkbox>
              </td>
              <td td-data-table-cell>
                <a class="tableLinks" href="#" [routerLink]="['/user/edit/', row.id]">{{row.name}}</a>
              </td>
              <td td-data-table-cell>
                <span>{{ row.active==1?'Active':'Inactive'}}</span>
              </td>
              <td td-data-table-cell *ngFor="let column of columns" [numeric]="column.numeric">
                {{row[column.name]}}
              </td>
            </tr>
          </tbody>
        </table>
        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSizes]="[10, 50, 100, 1000, 'All']"
            [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal" (change)="page($event)">
            <span td-paging-bar-label hide-xs>Row per page:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">All </span>
            <span hide-xs>{{pagingBar.total}} items</span>
          </td-paging-bar>
        </div>

        <md-card-actions *ngIf="!_authService.isViewer()">
          <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
            <button md-button *ngIf="_authService.isAdmin()" color="primary" class="bg-blue color-white" [routerLink]="['/user', 'edit', 'new']">Add</button>
            <button md-button *ngIf="_authService.isAdmin()" color="accent" class="bg-blue color-white" (click)="deleteSelectedUsers()">Delete</button>
            <button md-button color="warn" class="bg-red color-white" (click)="goBack()">Back</button>
          </div>
        </md-card-actions>
      </md-card-content>
    </md-card>
  </div>
</div>