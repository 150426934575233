<div fxLayout="column">
  
  <div class="boxed-content-wrapper">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>Admin / Unit Prices</span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white">
          <div layout="row">
            <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="Search Here" (searchDebounce)="search($event)"
              flex>
            </td-search-box>
            <!-- If you wanted ALL data replace "filteredData" with "data" -->
            <button md-icon-button (click)="_exportService.asCsv(filteredData, 'Prices')">
              <md-icon>cloud_download</md-icon>
            </button>

          </div>
        </md-card-subtitle>
      </md-card-header>

      <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
        <md-divider></md-divider>
        <td-data-table #dataTable [data]="filteredData" [columns]="columns" [selectable]="false" [sortable]="true" [sortOrder]="'ASC'"
          (sortChange)="sort($event)">

          <ng-template tdDataTableTemplate="unit_price_eur" let-value="value" let-row="row" let-column="column">
            <div layout="row" (click)="openPriceChangeDialog(row)" class="linkCursor">
              {{value}}
            </div>
          </ng-template>

        </td-data-table>


        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSizes]="[10, 50, 100, 1000, 'All']"
            [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal" (change)="page($event)">
            <span td-paging-bar-label hide-xs>Row per page:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">All </span>
            <span hide-xs>{{pagingBar.total}} items</span>
          </td-paging-bar>
        </div>
      </md-card-content>
      <!--md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button color="primary" class="bg-blue color-white" routerLink="/purchasing/products/new">New</button>
          <button md-button color="warn" class="bg-red color-white" (click)="openConfirm()">Delete</button>
        </div>
      </md-card-actions-->
    </md-card>
  </div>
</div>