<div fxLayout="column">

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>
            PDA /
            <a [routerLink]="['/pda/shipping']">{{ 'shipping' | translate:_authService.getLanguage() }}</a> /
            {{order_number}} / {{ 'order_items' | translate:_authService.getLanguage()
            }}
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white text-only">{{ 'prepare_order' |
          translate:_authService.getLanguage() }}</md-card-subtitle>
      </md-card-header>


      <md-card-content fxLayout="column">

        <div flex layout-margin>
          <span>{{ preparedStatusText() }}</span>
          <md-progress-bar mode="determinate" [value]="preparedStatusValue()"></md-progress-bar>
        </div>

        <div class="padded-container" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-md="14px"
          style="max-width: 250px">
          <h4> {{ 'notes_capital' | translate:_authService.getLanguage() }}:</h4>
          <span>{{_notes}}</span>
        </div>
      </md-card-content>


      <md-card-content>
        <div class="padded-container" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-md="14px"
          style="max-width: 250px">
          <md-input-container fxFlex="47%">
            <input mdInput placeholder="{{ 'pallet' | translate:_authService.getLanguage() }}" [(ngModel)]="_pallet">
          </md-input-container>
        </div>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-slide-toggle [(ngModel)]="multiMode">Multi-piece mode</md-slide-toggle>
        </div>

        <div class="padded-container" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-md="14px"
          style="max-width: 250px">

          <md-input-container *ngIf="!multiMode">
            <input mdInput placeholder="{{ 'scan' | translate:_authService.getLanguage() }}" [(ngModel)]="_scanned">
          </md-input-container>
          <md-input-container *ngIf="multiMode">
            <textarea mdInput rows="10" placeholder="{{ 'scan' | translate:_authService.getLanguage() }}"
              [(ngModel)]="_scanned"></textarea>
          </md-input-container>

          <button md-button color="primary" class="bg-blue color-white" (click)="markScanned(_scanned)">{{ 'verify' |
        translate:_authService.getLanguage() }}</button>
        </div>
      </md-card-content>

      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button color="primary" class="bg-blue color-white" (click)="prepareOrder()">{{ 'prepare' |
            translate:_authService.getLanguage() }}</button>
          <button md-button color="primary" class="bg-blue color-white" (click)="addPiece()">{{ 'add_piece' |
            translate:_authService.getLanguage() }}</button>
          <button md-button color="primary" class="bg-blue color-white" (click)="savePreparationStatus()">{{
            'save_preparation_status' | translate:_authService.getLanguage() }}</button>
          <button md-button color="warn" class="bg-red color-white" [routerLink]="['/pda/shipping/prepareOrder']">{{
            'back' | translate:_authService.getLanguage() }}</button>
        </div>
      </md-card-actions>

<hr>
      <md-card-content fxLayout="column">
        
        <div class="padded-container" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-md="14px"
          *ngFor="let group of grouped_items">
          <md-list>
            <md-list-item>
              <span style="border-bottom: 1px solid black">{{group.location}}</span>
            </md-list-item>

            <md-list-item *ngFor="let item of group.pieces">
              <md-icon md-list-icon *ngIf="item.sold || item.deleted" style="color: red;">cancel</md-icon>
              <md-icon md-list-icon *ngIf="!item.checked && !item.sold && !item.deleted">radio_button_unchecked
              </md-icon>
              <md-icon md-list-icon *ngIf="item.checked" style="color: green; cursor: pointer"
                (click)="unPrepareItem(item)">check_circle</md-icon>
              <h4 md-line>
                <span style="height: 60%; color:darkgray">
                  <del *ngIf="item.sold || item.deleted">{{item.piece_number}}</del>
                  <span *ngIf="!item.sold && !item.deleted">{{item.piece_number}}</span>
                  ( {{item.quantity}}m <span style="height: 60%; color:darkgray"
                    *ngIf="item.quantity*1 < item.full_length*1">of {{item.full_length}}m</span> )
                  - {{item.grade_code}}
                </span>


                <span style="height: 60%; color:darkgray" *ngIf="item.prepared_pallet_id">[{{ 'pallet' |
                  translate:_authService.getLanguage() }}: {{item.prepared_pallet_name}}]</span>
              </h4>
              <p md-line> {{item.collection_name}}/{{item.design_name}}/{{item.color_name}} </p>

              <span md-line *ngIf="item.tag" class="linkCursor" (click)="printFreeTextLabel(item)">
                <md-icon>local_offer</md-icon> {{item.tag}}
              </span>

              <md-icon md-list-icon *ngIf="item.quantity*1 < item.full_length*1" (click)="openInlineCut(item)"
                style="cursor:pointer">content_cut</md-icon>
              <md-icon md-list-icon *ngIf="item.quantity*1 < item.full_length*1" (click)="overrideItemCut(item)"
                style="cursor:pointer">forward</md-icon>
              <md-icon md-list-icon *ngIf="!item.defect" style="cursor:pointer" title="Change Piece"
                (click)="swapPiece(item)">swap_horiz</md-icon>
            </md-list-item>
          </md-list>
        </div>
      </md-card-content>
<hr>

      <md-card-content>
        <div class="padded-container" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-md="14px"
          style="max-width: 250px">
          <md-input-container fxFlex="47%">
            <input mdInput placeholder="{{ 'pallet' | translate:_authService.getLanguage() }}" [(ngModel)]="_pallet">
          </md-input-container>
        </div>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-slide-toggle [(ngModel)]="multiMode">Multi-piece mode</md-slide-toggle>
        </div>

        <div class="padded-container" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-md="14px"
          style="max-width: 250px">

          <md-input-container *ngIf="!multiMode">
            <input mdInput placeholder="{{ 'scan' | translate:_authService.getLanguage() }}" [(ngModel)]="_scanned">
          </md-input-container>
          <md-input-container *ngIf="multiMode">
            <textarea mdInput rows="10" placeholder="{{ 'scan' | translate:_authService.getLanguage() }}"
              [(ngModel)]="_scanned"></textarea>
          </md-input-container>

          <button md-button color="primary" class="bg-blue color-white" (click)="markScanned(_scanned)">{{ 'verify' |
        translate:_authService.getLanguage() }}</button>
        </div>
        
      </md-card-content>


      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button color="primary" class="bg-blue color-white" (click)="prepareOrder()">{{ 'prepare' |
            translate:_authService.getLanguage() }}</button>
          <button md-button color="primary" class="bg-blue color-white" (click)="addPiece()">{{ 'add_piece' |
            translate:_authService.getLanguage() }}</button>
          <button md-button color="primary" class="bg-blue color-white" (click)="savePreparationStatus()">{{
            'save_preparation_status' | translate:_authService.getLanguage() }}</button>
          <button md-button color="warn" class="bg-red color-white" [routerLink]="['/pda/shipping/prepareOrder']">{{
            'back' | translate:_authService.getLanguage() }}</button>
        </div>
      </md-card-actions>
    </md-card>
  </div>
</div>
