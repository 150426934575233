<div flex layout="column" layout-wrap layout-margin>
    <md-toolbar>New Invoice</md-toolbar>
  
    <md-select [(ngModel)]="customer_id" placeholder="Customer" flex>
      <md-option *ngFor="let c of allCustomers$ | async" [value]="c.id">{{c.prefix}} - {{c.name}}</md-option>
    </md-select>
  
    <md-select [(ngModel)]="branch_id" placeholder="Branch" flex>
      <md-option *ngFor="let b of allBranches$ | async" [value]="b.id">{{b.code}} - {{b.name}}</md-option>
    </md-select>
  
    <md-input-container flex>
        <input mdInput placeholder="Invoice date" type="date" [(ngModel)]="invoice_date">
      </md-input-container>

      <md-input-container flex>
          <input mdInput placeholder="Invoice #" [(ngModel)]="invoice_number">
        </md-input-container>
  
     <md-input-container flex>
      <input mdInput placeholder="Total" type="number" step="0.01" [(ngModel)]="total">
    </md-input-container>
  
    <div flex layout="row" layout-padding layout-wrap>
      <button md-button md-raised-button color="primary" (click)="performAction(true)">SAVE</button>
      <span flex></span>
      <button md-button md-raised-button color="warn" (click)="performAction(false)">CANCEL</button>
    </div>
  </div>
  
  <!--
    {
      customer_id: customer_id,
      branch_id: branch_id,
      invoice_date: invoice_date,
      type: type,
      total: total
    },
  -->