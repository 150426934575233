<div fxLayout="column">
  

  <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
    <md-icon>warning</md-icon>
    <span>{{ 'you_have_unsaved_changes' | translate:_authService.getLanguage() }}</span>
  </div>

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
      <td-steps mode="horizontal" *ngIf="!isCancelled()">
        <td-step label="DRAFT" sublabel="To be completed" [active]="true" [state]="isDraft()?'complete':'none'" disabled="true">NEXT: Move to ORDERED status</td-step>
        <td-step label="ORDERED" sublabel="Submit Order to supplier" [state]="isOrdered()?'complete':'none'" [active]="true" disabled="true">NEXT: Scan pieces in PDA module</td-step>
        <td-step label="RECEIVED" sublabel="Scan pieces in PDA module" [state]="isReceived()?'complete':'none'" [active]="true" disabled="true">The order is part of inventory</td-step>
      </td-steps>
      <td-steps mode="horizontal" *ngIf="isCancelled()">
        <td-step label="DRAFT" sublabel="To be completed" [active]="true" state="complete" disabled="true"></td-step>
        <td-step label="CANCELLED" sublabel="The order is cancelled" state="complete" [active]="true" disabled="true"></td-step>
      </td-steps>

      <div flex layout-margin *ngIf="isOrdered()">
        <span>{{ preparedStatusText() }}</span>
        <md-progress-bar mode="determinate" [value]="preparedStatusValue()"></md-progress-bar>
      </div>

      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex="100">{{ 'purchasing' | translate:_authService.getLanguage() }} /
            <a [routerLink]="['/purchasing/po']">{{ 'po_list' | translate:_authService.getLanguage() }}</a> / {{ (isEditMode && currentPo) ? currentPo.po_number
            : title }}
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white text-only">{{ 'transfer_details' | translate:_authService.getLanguage() }}</md-card-subtitle>
      </md-card-header>
      <md-card-content fxLayout="column" *ngIf="currentBranchPo">
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-select fxFlex="22%" placeholder="{{ 'pieces_source_from' | translate:_authService.getLanguage() }}" name="sBranch" [ngModel]="_src_branch"
            (ngModelChange)="currentBranchPo.source_branch_id = $event.id; currentBranchPo.source_branch_code = $event.code"
            (ngModelChange)="updateInventory($event.id)">
            <md-option *ngFor="let branch of allBranches" [value]="branch">{{ branch.code}} - {{branch.name}}</md-option>
          </md-select>

          <md-select fxFlex="22%" placeholder="{{ 'send_pieces_to' | translate:_authService.getLanguage() }}" name="rBranch" [ngModel]="_dst_branch"
            (ngModelChange)="currentBranchPo.requesting_branch_id = $event.id; currentBranchPo.requesting_branch_code = $event.code">
            <md-option *ngFor="let branch of _authService.getUserBranches()" [value]="branch">{{ branch.code}} - {{branch.name}}</md-option>
          </md-select>

          <md-input-container fxFlex="22%">
            <input mdInput type="date" placeholder="{{ 'order_date' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranchPo.order_date">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput type="date" placeholder="{{ 'estimated_delivery_date' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranchPo.estimated_arrival_date">
          </md-input-container>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-select fxFlex="22%" placeholder="{{ 'status' | translate:_authService.getLanguage() }}" name="cityCountry" [(ngModel)]="currentBranchPo.status">
            <!--md-option *ngFor="let status of allStatuses$ | async" [value]="status">{{status}}</md-option-->
            <md-option *ngFor="let status of availableStatuses" [value]="status">{{status}}</md-option>
          </md-select>

          <md-input-container fxFlex="50%">
            <input mdInput placeholder="{{ 'notes' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranchPo.notes">
          </md-input-container>
        </div>

        <!-- Current Inventory -->
        <md-card-subtitle class="bg-grey color-white">
          <div layout="row" layout-align="center center">
            <span class="md-title">{{ 'search_inventory' | translate:_authService.getLanguage() }}</span>
            <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="Search Here" (searchDebounce)="search($event)"
              flex>{{ 'search_inventory' | translate:_authService.getLanguage() }}
            </td-search-box>
          </div>
        </md-card-subtitle>

        <div class="mat-table-container tmp-driven-table">
          <table td-data-table class="duplicated-thead-fix">
            <thead>
              <tr td-data-table-column-row>
                <td td-data-table-cell>
                  <md-checkbox flex color="primary" (click)="toggleSelectAll(!_selectAll)" [(ngModel)]="_selectAll"></md-checkbox>
                </td>
                <th td-data-table-column *ngFor="let column of columns_existing" [numeric]="column.numeric">
                  {{column.label}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr td-data-table-row *ngFor="let row of filteredData">
                <td td-data-table-cell>
                  <md-checkbox flex color="primary" (ngModelChange)="row['selected'] = !row['selected']; _selectAll = shouldSelectAll()" [(ngModel)]="row['selected']"></md-checkbox>
                </td>
                <td td-data-table-cell>{{ row['collection_name'] }}</td>
                <td td-data-table-cell>{{ row['design_name']}}/{{row['color_name'] }}</td>
                <td td-data-table-cell>{{ row['piece_number'] }}</td>
                <td td-data-table-cell>{{ row['lot_number'] }}</td>
                <td td-data-table-cell>{{ row['length'] | number:'1.2-2' }} m</td>
                <td td-data-table-cell>{{ row['weight'] | number:'1.2-2'}} kg</td>
                <td td-data-table-cell>{{ row['location_name'] }}</td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border default">
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ 'total' | translate:_authService.getLanguage() }}</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ filteredData.length }} PCS</td>
                <td td-data-table-cell>{{ total(filteredData, 'length') | number:'1.2-2' }} m</td>
                <td td-data-table-cell>{{ total(filteredData, 'weight') | number:'1.2-2' }} Kg</td>
                <td td-data-table-cell></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSize]="_settingsService.getPageSize()"
            [pageSizes]="[10, 50, 100, 1000, 'All']" [total]="filteredTotal" (change)="page($event)">
            <span td-paging-bar-label hide-xs>Row per page:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">{{ 'all' | translate:_authService.getLanguage() }} </span>
            <span hide-xs>{{pagingBar.total}} {{ 'items' | translate:_authService.getLanguage() }}</span>
          </td-paging-bar>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" layout-margin>
          <button md-raised-button color="primary" (click)="addItemsToTransfer(filteredData)">{{ 'add_capital' | translate:_authService.getLanguage() }} ({{totalChecked(filteredData, 'length')}} m)</button>
        </div>

        <!-- Branch PO Items -->
        <md-card-subtitle class="bg-grey color-white text-only">{{ 'transfer_items' | translate:_authService.getLanguage() }}</md-card-subtitle>

        <div class="mat-table-container tmp-driven-table">
          <table td-data-table class="duplicated-thead-fix">
            <thead>
              <tr td-data-table-column-row>
                <th td-data-table-column>
                  <md-checkbox [checked]="allTagged()" (click)="selectTagAll()"></md-checkbox>
                </th>
                <th td-data-table-column *ngFor="let column of columns_transfer" [numeric]="column.numeric">
                  {{column.label}}
                </th>
                <th td-data-table-column>
                  <!--This is the tag column-->
                </th>
                <th td-data-table-column>
                  <!--This is the remove column-->
                </th>
              </tr>
            </thead>
            <tbody>
              <tr td-data-table-row *ngFor="let row of items" [style.background-color]="(row['length']*1 < row['full_length']*1)?'lightyellow':''">
                <td td-data-table-cell>
                  <!--md-checkbox [checked]="checkedForTagging(row)" [value]="checkedForTagging(row)" (click)="selectTag(row)"></md-checkbox-->
                  <md-checkbox (ngModelChange)="row['selected'] = !row['selected']" [(ngModel)]="row['selected']"></md-checkbox>
                </td>
                <td td-data-table-cell>{{ row['collection_name'] }}</td>
                <td td-data-table-cell>{{ row['design_name']}}/{{row['color_name'] }}</td>
                <td td-data-table-cell>{{ row['piece_number'] }}</td>
                <td td-data-table-cell>{{ row['lot_number'] }}</td>
                <td td-data-table-cell class="linkCursor" (click)="enterNewValueDialog(row, 'length')">
                  <span>{{ row['length'] | number:'1.2-2'}} m</span>
                  <span *ngIf="row['length']*1 < row['full_length']*1">&nbsp;of {{ row['full_length']}} m</span>
                </td>
                <td td-data-table-cell class="linkCursor" (click)="enterNewValueDialog(row, 'weight')">
                  <span>{{ row['weight'] | number:'1.2-2' }} kg</span>
                </td>
                <td td-data-table-cell>
                  <a (click)="displayTag(row['tag'])" class="linkCursor" *ngIf="row['tag']">
                    <md-icon>local_offer</md-icon>
                  </a>
                </td>
                <td td-data-table-cell>
                  <a (click)="removeItem(row.id); filter()" class="linkCursor">
                    <md-icon class="rotate45">add_circle</md-icon>
                  </a>
                </td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border default">
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ 'total' | translate:_authService.getLanguage() }}</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ items.length }} PCS</td>
                <td td-data-table-cell>{{ total(items, 'length') | number:'1.2-2'}} m</td>
                <td td-data-table-cell>{{ total(items, 'weight') | number:'1.2-2'}} Kg</td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border default">
                <td td-data-table-cell>
                  <button md-button md-raised-button color="primary" (click)="addTag()">Add tag</button>
                </td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar2 [pageSize]="_settingsService.getPageSize()"
            [pageSizes]="[10, 50, 100, 1000, 'All']" [total]="items.length" (change)="page($event)">
            <span td-paging-bar-label hide-xs>{{ 'rows_per_page' | translate:_authService.getLanguage() }}:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar2.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">{{ 'all' | translate:_authService.getLanguage() }} </span>
            <span hide-xs>{{pagingBar2.total}} {{ 'items' | translate:_authService.getLanguage() }}</span>
          </td-paging-bar>
        </div-->

        <span *ngIf="isPrepared() && items_not_prepared.length > 0">
          <!-- Not prepared Items -->
          <md-card-subtitle class="bg-grey color-white text-only">{{ 'items_not_shipped' | translate:_authService.getLanguage() }}</md-card-subtitle>

          <div class="mat-table-container tmp-driven-table">
            <table td-data-table class="duplicated-thead-fix">
              <thead>
                <tr td-data-table-column-row>
                  <th td-data-table-column *ngFor="let column of columns_transfer" [numeric]="column.numeric">
                    {{column.label}}
                  </th>
                  <th td-data-table-column></th>
                </tr>
              </thead>
              <tbody>
                <tr td-data-table-row *ngFor="let row of items_not_prepared" [style.background-color]="(row['length']*1 < row['full_length']*1)?'lightyellow':''">
                  <td td-data-table-cell>{{ row['collection_name'] }}</td>
                  <td td-data-table-cell>{{ row['design_name']}}/{{row['color_name'] }}</td>
                  <td td-data-table-cell>{{ row['piece_number'] }}</td>
                  <td td-data-table-cell>{{ row['lot_number'] }}</td>
                  <td td-data-table-cell>
                    <span>{{ row['length'] | number:'1.2-2'}} m</span>
                    <span *ngIf="row['length']*1 < row['full_length']*1">&nbsp;of {{ row['full_length']}} m</span>
                  </td>
                  <td td-data-table-cell>
                    <span>{{ row['weight'] | number:'1.2-2' }} kg</span>
                  </td>
                  <td td-data-table-cell></td>
                </tr>
                <tr td-data-table-column-row class="total-row no-border default">
                  <td td-data-table-cell>{{ 'total' | translate:_authService.getLanguage() }}</td>
                  <td td-data-table-cell></td>
                  <td td-data-table-cell></td>
                  <td td-data-table-cell>{{ items_not_prepared.length }} PCS</td>
                  <td td-data-table-cell>{{ total(items_not_prepared, 'length') | number:'1.2-2'}} m</td>
                  <td td-data-table-cell>{{ total(items_not_prepared, 'weight') | number:'1.2-2'}} Kg</td>
                </tr>
              </tbody>
            </table>
          </div>
        </span>

      </md-card-content>
      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxLayoutGap="12px" fxFlexAlign.lt-md="center">
          <button md-button *ngIf="!isEditMode && !_authService.isViewer()" color="primary" class="bg-blue color-white" (click)="createTransfer()">{{ 'create' | translate:_authService.getLanguage() }}</button>
          <!--button md-button *ngIf="!isEditMode" color="primary" class="bg-blue color-white" (click)="createAndSendTransfer()">Send</button-->
          <button md-button *ngIf="isEditMode && !_authService.isViewer() && (currentBranchPo?.status=='DRAFT' || currentBranchPo?.status=='ORDERED')"
            color="primary" class="bg-blue color-white" (click)="saveTransfer()">{{ 'save' | translate:_authService.getLanguage() }}</button>
          <button md-button color="primary" class="bg-blue color-white" (click)="printPO()">{{ 'print_po' | translate:_authService.getLanguage() }}</button>
          <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
        </div>
      </md-card-actions>
    </md-card>
  </div>
</div>