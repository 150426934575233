<div layout="column" layout-fill class="login-block">
    <div class="md-content" layout-padding flex>
        <div layout-gt-xs="row" layout-align-gt-xs="center start" class="margin">
            <div flex-gt-xs="50">
                <md-card class="login-card" fxFlexAlign="start" padding>
                    <md-card-title>
                        <h4>Login</h4>
                    </md-card-title>
                    <md-card-content>
                        <form class="login-form">
                            <md-input-container class="full-width">
                                <input mdInput type="text" placeholder="Username" [(ngModel)]="username" name="username">
                            </md-input-container>
                            <md-input-container class="full-width">
                                <input mdInput type="password" placeholder="Your password" [(ngModel)]="password" name="password">
                            </md-input-container>
                            <button md-button (click)="login()" class="bg-blue color-white">Login</button>
                        </form>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</div>