<div fxLayout="column">
  
  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>
            PDA /
            <a [routerLink]="['/pda/inventory']">{{ 'inventory' | translate:_authService.getLanguage() }}</a> /
            {{ 'list_pallet_items' | translate:_authService.getLanguage() }}
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white text-only">
          {{ 'list_location_items' | translate:_authService.getLanguage() }}</md-card-subtitle>
      </md-card-header>
      <md-card-content fxLayout="column">
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-select placeholder="{{ 'branch' | translate:_authService.getLanguage() }}" [(ngModel)]="branchFilter"
            name="branch" fxFlex="47%">
            <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.id">{{branchItem.code}} -
              {{branchItem.name}}</md-option>
          </md-select>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="47%">
            <input mdInput placeholder="{{ 'pallet' | translate:_authService.getLanguage() }}" [(ngModel)]="palletName">
          </md-input-container>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <button md-button color="primary" class="bg-blue color-white"
            (click)="findItems(palletName)">{{ 'list' | translate:_authService.getLanguage() }}</button>
        </div>
      </md-card-content>

      <md-card-content>
        <md-divider></md-divider>
        <div class="mat-table-container tmp-driven-table">
          <table td-data-table class="duplicated-thead-fix">
            <thead>
              <tr td-data-table-column-row>
                <th td-data-table-column *ngFor="let column of columns" [active]="sortBy == column.name"
                  [name]="column.name" [sortOrder]="sortOrder" [sortable]="true" (sortChange)="sort($event)"
                  [numeric]="false">
                  {{column.label}}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr td-data-table-row *ngFor="let row of filteredData">
                <td td-data-table-cell *ngFor="let column of columns" [numeric]="column.numeric">
                  <div [ngSwitch]="column.name">
                    <span *ngSwitchCase="'length'">
                      {{row.length | number:'1.2-2'}}{{getUnit(row.unit_type)}}
                      <!-- {{getLengthString(row.length, row.unit_type)}} -->
                    </span>
                    <span *ngSwitchDefault>
                      {{row[column.name]}}
                    </span>
                  </div>
                </td>
              </tr>
              <!-- Totals row -->
              <tr td-data-table-column-row class="total-row no-border default">
                <td td-data-table-cell>
                  <b>{{ 'total' | translate:_authService.getLanguage() }}</b> {{totalPieces()}}
                  {{ 'pieces' | translate:_authService.getLanguage() }}</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <!-- TODO only show if all rows have the same unit type -->
                <td td-data-table-cell><span *ngIf="allSameUnitType()">{{totalMeters() | number:'1.2-2'}}</span></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
              </tr>
            </tbody>
          </table>
        </div>

        <md-divider></md-divider>

        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar
            [pageSizes]="[10, 50, 100, 1000, 'All']" [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal"
            (change)="page($event)">
            <span td-paging-bar-label hide-xs>{{ 'rows_per_page' | translate:_authService.getLanguage() }}:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">{{ 'all' | translate:_authService.getLanguage() }} </span>
            <span hide-xs>{{pagingBar.total}} {{ 'items' | translate:_authService.getLanguage() }}</span>
          </td-paging-bar>
        </div>
      </md-card-content>

      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button color="warn" class="bg-red color-white"
            (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
        </div>
      </md-card-actions>
    </md-card>
  </div>
</div>
