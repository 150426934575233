<div fxLayout="column">
    

    <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
        <md-icon>warning</md-icon>
        <span>You have unsaved changes</span>
    </div>

    <div class="boxed-content-wrapper cards-no-gaps">
        <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
            <td-steps mode="horizontal">
                <td-step label="ORDERED" sublabel="Submit order" [active]="true" [state]="isOrdered()?'complete':'none'" disabled="true">NEXT: Import packing list</td-step>
                <td-step label="PACKING LIST" sublabel="Import Packing List" [state]="isPackingList()?'complete':'none'" [active]="true"
                    disabled="true">NEXT: Scan pieces in PDA module</td-step>
                <td-step label="RECEIVED" sublabel="Scan pieces in PDA module" [state]="isReceived()?'complete':'none'" [active]="true" disabled="true">The order is part of inventory</td-step>
            </td-steps>

            <div flex layout-margin *ngIf="isPackingList()">
                <span>{{ receivedStatusText() }}</span>
                <md-progress-bar mode="determinate" [value]="receivedStatusValue()"></md-progress-bar>
            </div>

            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex="100" *ngIf="currentReceiving">Receiving /
                        <a [routerLink]="['/receiving/list/']">Receiving list</a> /{{currentReceiving.receiving_number}}</span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white text-only">Po Details</md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column" *ngIf="currentReceiving">
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <!--md-input-container fxFlex="22%">
                        <input mdInput placeholder="Receiving number" [(ngModel)]="currentReceiving.receiving_number">
                    </md-input-container-->

                    <!-- If this was a normal PO -->
                    <md-input-container *ngIf="currentReceiving.supplier" fxFlex="22%">
                        <input mdInput readonly placeholder="Supplier Prefix" value="{{currentReceiving.supplier_code}}">
                    </md-input-container>

                    <!-- If this was a B2B PO -->
                    <md-select *ngIf="!currentReceiving.supplier" fxFlex="22%" placeholder="Source Branch" name="branch" [(ngModel)]="currentReceiving.source_branch_id">
                        <md-option *ngFor="let branch of allBranches$ | async" [value]="branch.id">{{branch.code}}/{{branch.name}}</md-option>
                    </md-select>
                    <md-select *ngIf="!currentReceiving.supplier" fxFlex="22%" placeholder="Requesting Branch" name="branch" [(ngModel)]="currentReceiving.requesting_branch_id">
                        <md-option *ngFor="let branch of allBranches$ | async" [value]="branch.id">{{branch.code}}/{{branch.name}}</md-option>
                    </md-select>

                    <md-input-container *ngIf="!currentReceiving.supplier" fxFlex="22%">
                        <input mdInput type="date" placeholder="Order Date" [(ngModel)]="currentReceiving.order_date">
                    </md-input-container>
                    <!-- End local variations -->

                    <md-input-container fxFlex="22%">
                        <input mdInput readonly placeholder="PO Number" [(ngModel)]="currentReceiving.po_number">
                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput type="date" placeholder="Expected Date" [(ngModel)]="currentReceiving.estimated_arrival_date" [min]="getMinDate()"
                            [max]="getMaxDate()">

                        <md-hint *ngIf="!withinRange(currentReceiving.estimated_arrival_date, getMinDate(), getMaxDate())" align="end">
                            <span style="color:red">This date is not within valid range</span>
                        </md-hint>

                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput readonly type="date" placeholder="Actual Arrival Date" [(ngModel)]="currentReceiving.actual_arrival_date">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput readonly placeholder="Status" [(ngModel)]="currentReceiving.status">
                    </md-input-container>

                    <div fxFlex="22%" *ngIf="currentReceiving && currentReceiving.status == 'ORDERED' && !currentReceiving.supplier">
                        <button md-button color="primary" class="bg-blue color-white" (click)="markReceived()">SET PACKING LIST RECEIVED</button>
                    </div>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <textarea mdInput placeholder="Notes" [(ngModel)]="currentReceiving.notes">{{currentReceiving.notes}}</textarea>
                    </md-input-container>
                    <div>
                        <button md-button *ngIf="!_authService.isViewer()" color="primary" class="bg-blue color-white" (click)="updateOrder()">SAVE NOTES</button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
        <!-- Only if this is a normal PO that we want to upload a packing list to -->
        <md-card *ngIf="currentReceiving && currentReceiving.supplier && currentReceiving.status == 'ORDERED'">
            <md-card-header fxLayout="column">
                <md-card-subtitle class="bg-grey color-white text-only">Packing List Import
                </md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="row" *ngIf="currentReceiving" layout-margin>
                <div class="padded-container">
                    <td-file-upload #fileUpload (select)="selectEvent($event)" (upload)="uploadEvent($event)" (cancel)="cancelEvent()" accept=".xls,.xlsx,.csv"
                        defaultColor="accent" activeColor="warn" cancelColor="primary" [disabled]="disabled">
                        <md-icon>file_upload</md-icon>
                        <span>
                            {{ fileUpload.files?.name || fileUpload.files?.length }}
                            <span *ngIf="fileUpload.files?.length">files selected</span>
                        </span>
                        <ng-template td-file-input-label>
                            <md-icon>attach_file</md-icon>
                            <span>Choose CSV file...</span>
                        </ng-template>
                    </td-file-upload>
                </div>
                <span flex></span>
                <!-- Move the button to the bottom -->
                <!--div class="padded-container">
                    <button md-button *ngIf="currentReceiving.status == 'ORDERED'" color="primary" class="bg-blue color-white" (click)="markReceived()">SET PACKING LIST RECEIVED</button>
                </div-->
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-header fxLayout="column">
                <md-card-subtitle class="bg-grey color-white text-only">
                    <span>Receiving Items</span>
                    <span *ngIf="currentReceiving && currentReceiving.supplier">&nbsp;(From Packing List)</span>
                </md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column" *ngIf="currentReceiving">
                <div class="mat-table-container tmp-driven-table">

                <!-- Packing list items-->
                <div *ngIf="currentReceiving && currentReceiving.supplier " class="md-table-container tmp-driven-table">
                    <table td-data-table class="duplicated-thead-fix">
                        <thead>
                            <tr td-data-table-column-row>
                                <th td-data-table-column *ngFor="let column of columns" [numeric]="column.numeric">
                                    {{column.label}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr td-data-table-row *ngFor="let row of filteredData" [style.background-color]="(row.po_qty_ordered == 0)?'LightCoral':((row.po_qty_ordered * 0.9 > row.pkg_list_qty || row.po_qty_ordered * 1.1 < row.pkg_list_qty)?'LightGoldenRodYellow ':'')">
                                <td td-data-table-cell>{{row.collection}}</td>
                                <td td-data-table-cell>{{row.design}}/{{row.color}}</td>
                                <td td-data-table-cell>{{row.grade_code}}</td>
                                <td td-data-table-cell>{{row.po_qty_ordered}}</td>
                                <td td-data-table-cell>{{row.pkg_list_pcs}}</td>
                                <td td-data-table-cell>{{row.pkg_list_qty | number:'1.2-2'}}</td>
                                <td td-data-table-cell>{{row.actual_pcs}}</td>
                                <td td-data-table-cell>{{row.actual_qty | number:'1.2-2'}}</td>

                               <!--
                                   this is not in the data, would need to be added when importing the packing list
                                    <td td-data-table-cell>{{row.weight | number:'1.2-2'}}</td>
                               -->

                            </tr>
                            <tr td-data-table-column-row class="total-row no-border default">
                                <td td-data-table-cell>Total</td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell>{{ total(filteredData, 'po_qty_ordered') }}</td>
                                <td td-data-table-cell>{{ total(filteredData, 'pkg_list_pcs') }}</td>
                                <td td-data-table-cell>{{ total(filteredData, 'pkg_list_qty') | number:'1.2-2' }}</td>
                                <td td-data-table-cell>{{ total(filteredData, 'actual_pcs') }}</td>
                                <td td-data-table-cell>{{ total(filteredData, 'actual_qty') | number:'1.2-2' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Original B2B list items-->
                <div *ngIf="currentReceiving && !currentReceiving.supplier" class="md-table-container tmp-driven-table">
                    <table td-data-table class="duplicated-thead-fix">
                        <thead>
                            <tr td-data-table-column-row>
                                <th td-data-table-column *ngFor="let column of b2b_columns" [numeric]="column.numeric">
                                    {{column.label}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr td-data-table-row *ngFor="let row of b2b_items">
                                <td td-data-table-cell>{{row.collection_name}}</td>
                                <td td-data-table-cell>{{row.design_name}}/{{row.color_name}}</td>
                                <td td-data-table-cell>{{row.piece_number}}</td>
                                <td td-data-table-cell>{{row.lot_number}}</td>
                                <td td-data-table-cell>{{row.length | number:'1.2-2'}}</td>
                                <td td-data-table-cell>{{row.weight | number:'1.2-2'}}</td>
                            </tr>
                            <tr td-data-table-column-row class="total-row no-border default">
                                <td td-data-table-cell>Total</td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell>{{ total(b2b_items, 'length') | number:'1.2-2'}}</td>
                                <td td-data-table-cell>{{ total(b2b_items, 'weight') | number:'1.2-2'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- ---- ---- ---- -->
                <!--div fxLayout="row" fxLayout.lt-md="column" fxLayout="row-reverse">
                    <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSizes]="[10, 50, 100, 1000, 'All']"
                        [total]="filteredTotal" (change)="page($event)">
                        <span td-paging-bar-label hide-xs>Row per page:</span>
                        <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
                        <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
                        <span *ngIf="pagingBar.pageSize == 'All'">All </span>
                        <span hide-xs>{{pagingBar.total}} items</span>
                    </td-paging-bar>
                </div-->
                </div>
            </md-card-content>
        </md-card>
        <!-- Only if this is a normal PO -->
        <md-card *ngIf="currentReceiving && currentReceiving.supplier">
            <md-card-header fxLayout="column">
                <md-card-subtitle class="bg-grey color-white text-only">Original PO {{currentReceiving?.po_number}} items (for reference)
                </md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column" *ngIf="currentReceiving">

                <!-- PO orders for reference -->
                <div class="md-table-container tmp-driven-table">
                    <table td-data-table class="duplicated-thead-fix">
                        <thead>
                            <tr td-data-table-column-row>
                                <th td-data-table-column *ngFor="let column of po_item_columns" [numeric]="column.numeric">
                                    {{column.label}}
                                </th>
                                <!--th td-data-table-column>Total (CAD)</th-->
                            </tr>
                        </thead>
                        <tbody>
                            <tr td-data-table-row *ngFor="let row of po_items">
                                <td td-data-table-cell>{{ row['collection_name'] }}</td>
                                <td td-data-table-cell>{{ row['supplier_collection_name'] }}</td>
                                <td td-data-table-cell>{{ row['design_name']}}/{{row['color_name'] }}</td>
                                <td td-data-table-cell>{{ row['supplier_design_name']}}/{{row['supplier_color_name'] }}</td>
                                <td td-data-table-cell>{{ row['quantity'] }}</td>
                                <!--td td-data-table-cell>{{ row['unit_price'] | currency:'USD':true}}</td>
                                    <td td-data-table-cell>{{ row['quantity'] * row['unit_price'] | currency:'USD':true}}</td-->
                            </tr>
                            <tr td-data-table-column-row class="total-row no-border default">
                                <td td-data-table-cell>Total</td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell>{{ getTotalMeters() }}</td>
                                <!--td td-data-table-cell></td>
                                    <td td-data-table-cell>{{ getTotalValue() | currency:'USD':true}}</td-->
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End PO reference -->

            </md-card-content>
            <md-card-actions *ngIf="!_authService.isViewer()">
                <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxLayoutGap="12px" fxFlexAlign.lt-md="center">
                    <button md-button color="primary" class="bg-blue color-white" (click)="updateOrder()">Update</button>
                    <button md-button *ngIf="currentReceiving.status == 'ORDERED'" color="primary" class="bg-blue color-white" (click)="markReceived()">Confirm PACKING LIST</button>

                    <button md-button *ngIf="isPackingList()" color="primary" class="bg-blue color-white" (click)="mismatchReport()">Mismatch Report</button>

                    <button md-button color="warn" class="bg-red color-white" (click)="goBack()">Back</button>
                </div>
            </md-card-actions>
        </md-card>
    </div>
</div>