<div fxLayout="column">
  

  <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
    <md-icon>warning</md-icon>
    <span>You have unsaved changes</span>
  </div>

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>Admin /
            <a [routerLink]="['/admin/users/list']">Users</a> / {{ (isEditMode && currentUser) ? currentUser.name : title }}
          </span>
        </md-card-title>
        <!--md-card-subtitle class="bg-grey color-white text-only" *ngIf="isEditMode">Edit User</md-card-subtitle>
                <md-card-subtitle class="bg-grey color-white text-only" *ngIf="!isEditMode">Create User</md-card-subtitle-->
      </md-card-header>

      <md-card-subtitle class="bg-grey color-white text-only">{{ 'account_details' | translate:_authService.getLanguage() }}</md-card-subtitle>

      <md-card-content fxLayout="column" *ngIf="currentUser">
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'username' | translate:_authService.getLanguage() }}" [(ngModel)]="currentUser.name">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'first_name' | translate:_authService.getLanguage() }}" [(ngModel)]="currentUser.first_name">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'last_name' | translate:_authService.getLanguage() }}" [(ngModel)]="currentUser.last_name">
          </md-input-container>

          <div fxFlex="22%" *ngIf="!isEditMode">
          </div>
          <md-input-container fxFlex="22%" *ngIf="isEditMode">
            <input mdInput readonly placeholder="Last Updated" [(ngModel)]="currentUser.last_updated">
          </md-input-container>
        </div>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="22%">
            <input mdInput placeholder="CPN Code" [(ngModel)]="currentUser.cpn_code">
          </md-input-container>

          <md-select placeholder="Home Branch" fxFlex="22%" [(ngModel)]="currentUser.branch_id">
            <md-option *ngFor="let branch of branches" [value]="branch.id">{{branch.code}} - {{branch.name}}</md-option>
          </md-select>
          <!--md-input-container fxFlex="22%">
            <input mdInput placeholder="Home Branch" [(ngModel)]="currentUser.branch">
          </md-input-container-->
          <md-select placeholder="Language" fxFlex="22%" [(ngModel)]="currentUser.language">
            <md-option *ngFor="let language of languages" [value]="language.id">
              <img width="20px" style="vertical-align:middle" src="{{language.icon}}">
              <span>{{language.name}}</span>
            </md-option>
          </md-select>

          <div fxFlex="22%" fxLayout="column">
            <button md-button *ngIf="isEditMode && _authService.isAdmin()" color="primary" class="bg-blue color-white" (click)="resetPassword()">Change Password</button>
            <md-input-container *ngIf="!isEditMode && _authService.isAdmin()">
              <input mdInput placeholder="{{ 'password' | translate:_authService.getLanguage() }}" type="text" [(ngModel)]="password">
            </md-input-container>
          </div>
          <!--md-input-container fxFlex="22%">
            <input mdInput placeholder="Language" [(ngModel)]="currentUser.language">
          </md-input-container-->

        </div>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Position" [(ngModel)]="currentUser.position">
          </md-input-container>
          <md-input-container fxFlex="22%">
            <input mdInput placeholder="e-Mail" [(ngModel)]="currentUser.email">
          </md-input-container>
          <md-select placeholder="Printer" fxFlex="22%" [(ngModel)]="currentUser.printer_id">
            <md-option *ngFor="let printer of printers$ | async" [value]="printer.id">{{printer.name}}
            </md-option>
          </md-select>
        </div>

        <md-card-subtitle class="bg-grey color-white text-only">User Rights</md-card-subtitle>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">

          <div fxFlex="22%" fxLayout="column">
            <h3 md-subheader>Status</h3>
            <md-slide-toggle [ngModel]="currentUser.active == 1" (ngModelChange)="currentUser.active=$event?1:0">Active </md-slide-toggle>
          </div>
          <!--md-input-container fxFlex="22%">
            <input mdInput placeholder="Active" [(ngModel)]="currentUser.active">
          </md-input-container-->

          <div fxFlex="22%" fxLayout="column">
            <h3 md-subheader>Branch Access</h3>
            <section fxLayout="column">
              <md-checkbox *ngFor="let branch of branches" [ngModel]="hasBranch(branch)" (ngModelChange)="updateBranches($event, branch)">{{branch.code}} - {{branch.name}}</md-checkbox>
            </section>
            <!--md-list>
              <h3 md-subheader>Branch Access</h3>
              <md-list-item *ngFor="let branch of currentUser.branch_list">
                <h4 md-line>{{branch.code}}</h4>
                <p md-line>{{branch.name}}</p>
              </md-list-item>
              <md-list-item>
                <md-icon md-list-icon>add</md-icon>
                <h4 md-line>ADD</h4>
                <p md-line>New Branch</p>
              </md-list-item>
            </md-list-->
          </div>

          <div fxFlex="22%" fxLayout="column">
            <h3 md-subheader>User Right</h3>
            <md-radio-group fxLayout="column" [(ngModel)]="currentUser.right_level">
              <md-radio-button *ngFor="let option of _authService.isSuper()?['Senior', 'Admin', 'Executive', 'Viewer']:['Admin', 'Executive', 'Viewer']"
                [value]="option">
                {{option}}
              </md-radio-button>
            </md-radio-group>
          </div>
          <!--md-select fxFlex="22%" placeholder="User Right" [(ngModel)]="currentUser.right_level">
            <md-option *ngFor="let option of ['Admin', 'Executive', 'Viewer']" [value]="option">{{option}}</md-option>
          </md-select-->

          <div fxFlex="22%" fxLayout="column">
            <h3 md-subheader>Module Roles</h3>
            <section fxLayout="column">
              <md-checkbox *ngFor="let role of roles" [ngModel]="hasRole(role)" (ngModelChange)="updateRoles($event, role)">{{role.name}}</md-checkbox>
            </section>
          </div>
        </div>

        <md-card-actions *ngIf="!_authService.isViewer()">
          <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
            <button md-button *ngIf="!isEditMode" color="primary" class="bg-blue color-white" (click)="createUser()">Create</button>
            <button md-button *ngIf="isEditMode" color="primary" class="bg-blue color-white" (click)="saveUser()">Save</button>
            <button md-button color="warn" class="bg-red color-white" (click)="goBack()">Back</button>
          </div>
        </md-card-actions>
      </md-card-content>
    </md-card>
  </div>
</div>