<div fxLayout="column">

  <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
    <md-icon>warning</md-icon>
    <span>{{ 'you_have_unsaved_changes' | translate:_authService.getLanguage() }}</span>
  </div>

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>{{ 'accounting' | translate:_authService.getLanguage() }} /
            <a
              [routerLink]="['/accounting/bank']">{{ 'accounts_management' | translate:_authService.getLanguage() }}</a>
            /
            {{ (isEditMode && currentAccount) ? currentAccount.name : ('new_account' | translate:_authService.getLanguage()) }}
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white text-only">
          {{ 'account_details' | translate:_authService.getLanguage() }}</md-card-subtitle>
      </md-card-header>

      <md-card-content fxLayout="column" *ngIf="currentAccount">
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">

          <md-input-container fxFlex="47%">
            <input mdInput placeholder="{{ 'name' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentAccount.name">
          </md-input-container>

          <md-select fxFlex="22%" placeholder="{{ 'type' | translate:_authService.getLanguage() }}"
            [(ngModel)]="currentAccount.type">
            <md-option *ngFor="let type of ['Checking', 'Cash', 'Card']" [value]="type">
              {{ type | translate:_authService.getLanguage() }}</md-option>
          </md-select>

          <md-select fxFlex="22%" placeholder="{{ 'branch' | translate:_authService.getLanguage() }}"
            [(ngModel)]="currentAccount.branch_id">
            <md-option *ngFor="let branch of allBranches$ | async" [value]="branch.id">{{branch.code}} - {{branch.name}}
            </md-option>
          </md-select>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">

          <md-input-container fxFlex="22%" *ngIf="!isEditMode">
            <input mdInput placeholder="{{ 'starting_balance' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentAccount.starting_balance">
          </md-input-container>
          <div flex *ngIf="isEditMode"></div>

          <!-- <md-select fxFlex="22%" placeholder="{{ 'currency' | translate:_authService.getLanguage() }}"
            [(ngModel)]="currentAccount.currency">
            <md-option *ngFor="let currency of ['CAD', 'USD', 'EUR', 'LEI', 'LEVA', 'MDL', 'RSD']" [value]="currency">
              {{currency}}
            </md-option>
          </md-select> -->

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'currency' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentAccount.currency" [mdAutocomplete]="currencyAuto"
              (ngModelChange)="filterCurrencies($event)">
            <md-autocomplete #currencyAuto="mdAutocomplete">
              <md-option *ngFor="let c of _currencies" [value]="c.code">{{c.code}} ({{c.description}})</md-option>
            </md-autocomplete>
          </md-input-container>

          <div fxFlex="22%"></div>
          <div fxFlex="22%"></div>
        </div>
      </md-card-content>
      <md-card-actions>
        <md-card-actions *ngIf="!_authService.isViewer()">
          <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
            <button *ngIf="!isEditMode" md-button color="primary" class="bg-blue color-white"
              (click)="createAccount()">{{ 'create' | translate:_authService.getLanguage() }}</button>
            <button *ngIf="isEditMode" md-button color="primary" class="bg-blue color-white"
              (click)="saveAccount()">{{ 'save_data' | translate:_authService.getLanguage() }}</button>
            <button md-button color="warn" class="bg-red color-white"
              (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
          </div>
        </md-card-actions>
      </md-card-actions>
    </md-card>
  </div>
</div>
