<div fxLayout="column">
    
    <div class="boxed-content-wrapper cards-no-gaps">
        <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>
                        PDA /
                        <a [routerLink]="['/pda/shipping']">{{ 'shipping' | translate:_authService.getLanguage() }}</a> / {{ 'prepare_order' | translate:_authService.getLanguage()
                        }}
                    </span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white text-only">{{ 'prepare_order' | translate:_authService.getLanguage() }}</md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column">
                <div class="padded-container" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-md="14px">
                    <md-list>
                        <md-list-item>
                            <md-select placeholder="{{ 'branch' | translate:_authService.getLanguage() }}" [(ngModel)]="branchFilter" (ngModelChange)="filter()"
                                name="branch">
                                <md-option [value]="'ALL'">{{ 'all_branches' | translate:_authService.getLanguage() }}</md-option>
                                <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.code">{{branchItem.code}} - {{branchItem.name}}</md-option>
                            </md-select>
                        </md-list-item>
                        <md-list-item *ngFor="let order of filtered_outstanding_orders" [routerLink]="[ '/pda/shipping/prepareOrderItems', order.id ]"
                            [queryParams]="{order_number:order.order_number, branch_id: (order.serve_from_branch_id || order.branch_id) }"
                            style="cursor: pointer;">
                            <h4 md-line>{{ order.customer_name || order.requesting_branch_name }}</h4>
                            <p md-line>{{ order.order_number }}</p>
                            <span md-line>{{order.notes}}</span>
                        </md-list-item>
                    </md-list>
                </div>
            </md-card-content>

            <md-card-actions>
                <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                    <!--button md-button color="primary" class="bg-blue color-white" (click)="searchOrders()">Search</button-->
                    <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
                </div>
            </md-card-actions>
        </md-card>
    </div>
</div>