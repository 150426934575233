<div fxLayout="column">
  

  <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
      <md-icon>warning</md-icon>
      <span>You have unsaved changes</span>
  </div>

  <div class="boxed-content-wrapper cards-no-gaps">
      <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
          <md-card-header fxLayout="column">
              <md-card-title>
                  <span class="md-title" fxFlex=100>{{ 'purchasing' | translate:_authService.getLanguage() }} /
                    <a [routerLink]="['/purchasing/catalogs']">{{ 'catalogs' | translate:_authService.getLanguage() }}</a> / {{ (isEditMode && currentCatalog) ? currentCatalog.name : title }}
                  </span>
              </md-card-title>
              <md-card-subtitle class="bg-grey color-white text-only" *ngIf="isEditMode">{{ 'edit_catalog' | translate:_authService.getLanguage() }}</md-card-subtitle>
              <md-card-subtitle class="bg-grey color-white text-only" *ngIf="!isEditMode">{{ 'create_catalog' | translate:_authService.getLanguage() }}</md-card-subtitle>
          </md-card-header>
          <md-card-content fxLayout="column" *ngIf="currentCatalog">
              <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                  <md-input-container fxFlex="47%">
                      <input mdInput required placeholder="{{ 'name' | translate:_authService.getLanguage() }}" [(ngModel)]="currentCatalog.name">
                      <md-error>{{ 'required' | translate:_authService.getLanguage() }}</md-error>
                      <md-error>{{ 'must_be_unique' | translate:_authService.getLanguage() }}</md-error>
                  </md-input-container>

                  <md-input-container fxFlex="47%">
                      <input mdInput required placeholder="SKU" [(ngModel)]="currentCatalog.sku">
                      <md-error>required</md-error>
                      <md-error>must be unique</md-error>
                  </md-input-container>
              </div>

              <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                <md-input-container fxFlex="47%">
                    <input mdInput placeholder="{{ 'description' | translate:_authService.getLanguage() }}" [(ngModel)]="currentCatalog.description">
                </md-input-container>
              </div>

              <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                <md-input-container fxFlex="47%">
                    <textarea [readonly]="!_authService.isAdmin()" mdInput cols="4" placeholder="{{ 'notes' | translate:_authService.getLanguage() }}" [(ngModel)]="currentCatalog.notes"></textarea>
                </md-input-container>
              </div>

              <md-card-subtitle class="bg-grey color-white text-only">{{ 'items_included' | translate:_authService.getLanguage() }}</md-card-subtitle>
              

              <md-card-actions *ngIf="!_authService.isViewer()">
                  <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                      <button md-button *ngIf="!isEditMode" color="primary" class="bg-blue color-white" (click)="createCatalog()">{{ 'create' | translate:_authService.getLanguage() }}</button>
                      <button md-button *ngIf="isEditMode" color="primary" class="bg-blue color-white" (click)="saveCatalog()">{{ 'save' | translate:_authService.getLanguage() }}</button>
                      <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
                  </div>
              </md-card-actions>
          </md-card-content>
      </md-card>
  </div>
</div>