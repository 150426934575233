<div fxLayout="column">

    <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
        <md-icon>warning</md-icon>
        <span>You have unsaved changes</span>
    </div>

    <div class="boxed-content-wrapper cards-no-gaps">
        <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>{{ 'accounting' | translate:_authService.getLanguage() }} /
                        <a [routerLink]="['/accounting/branches']">{{ 'branches' | translate:_authService.getLanguage() }}</a> / {{ (isEditMode && currentBranch) ?
                        currentBranch.name : title }}
                    </span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white text-only" *ngIf="isEditMode">Edit Branch</md-card-subtitle>
                <md-card-subtitle class="bg-grey color-white text-only" *ngIf="!isEditMode">Create Branch</md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column" *ngIf="currentBranch">
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'prefix' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.code">
                    </md-input-container>

                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="{{ 'name' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.name">
                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="{{ 'branch_address' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.address">
                    </md-input-container>

                    <md-select fxFlex="22%" placeholder="{{ 'city' | translate:_authService.getLanguage() }} / {{ 'country' | translate:_authService.getLanguage() }}"
                        name="city_id" [(ngModel)]="currentBranch.city_id">
                        <md-option *ngFor="let location of allCity" [value]="location.id">{{location.name}}/{{location.country_name}}</md-option>
                    </md-select>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'currency' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.currency"
                            [mdAutocomplete]="currencyAuto" (ngModelChange)="filterCurrencies($event)">
                        <md-autocomplete #currencyAuto="mdAutocomplete">
                            <md-option *ngFor="let c of _currencies" [value]="c.code">{{c.code}} ({{c.description}})</md-option>
                        </md-autocomplete>
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'currency_vs_usd' | translate:_authService.getLanguage() }}" type="number" [(ngModel)]="currentBranch.exchange_rate_euro">
                        <!-- TODO database column is called exchange_rate_euro but it's actually now VS USD, will fix later
                        -->
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'sales_tax' | translate:_authService.getLanguage() }}" type="number" [(ngModel)]="currentBranch.sales_tax">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'sales_tax2' | translate:_authService.getLanguage() }}" type="number" [(ngModel)]="currentBranch.sales_tax2">
                    </md-input-container>
                </div>

                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'company' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.company_name">
                    </md-input-container>
                </div>

                <md-card-subtitle class="bg-grey color-white text-only">Invoice Details</md-card-subtitle>

                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'id_no' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.id_no">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'vat_no' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.vat_no">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'bank' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.bank">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'account' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.account">
                    </md-input-container>
                </div>

                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'starting_number' | translate:_authService.getLanguage() }}" type="number" [(ngModel)]="currentBranch.invoice_starting_num">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'invoice_series' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.invoice_series">
                    </md-input-container>

                    <div fxFlex="22%"></div>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'swift' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.swift">
                    </md-input-container>
                </div>

                <md-card-subtitle class="bg-grey color-white text-only">Contact Details</md-card-subtitle>

                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="{{ 'contact' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.contact_name">
                    </md-input-container>

                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="{{ 'email' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.contact_email">
                    </md-input-container>
                </div>

                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'phone' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.contact_phone">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'fax' | translate:_authService.getLanguage() }}" [(ngModel)]="currentBranch.contact_fax">
                    </md-input-container>
                </div>

                <md-card-actions *ngIf="!_authService.isViewer()">
                    <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                        <button md-button *ngIf="!isEditMode" color="primary" class="bg-blue color-white" (click)="save()">Create</button>
                        <button md-button *ngIf="isEditMode && canUpdate()" color="primary" class="bg-blue color-white" (click)="save()">Save</button>
                        <button md-button color="warn" class="bg-red color-white" (click)="goBack()">Back</button>
                    </div>
                </md-card-actions>
            </md-card-content>
        </md-card>
    </div>
</div>