<div fxLayout="column">
    
    <div class="boxed-content-wrapper cards-no-gaps">
      <md-card>
          <md-card-header fxLayout="column">
            <md-card-title>
                <span class="md-title" fxFlex=100>
                    PDA / {{ 'inventory' | translate:_authService.getLanguage() }}
                </span>
            </md-card-title>
              <md-card-subtitle class="bg-grey color-white text-only">{{ 'inventory' | translate:_authService.getLanguage() }}</md-card-subtitle>
          </md-card-header>
          <md-card-content fxLayout="column">
              <div class="padded-container custom-container">
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/inventory/changeLocation']">{{ 'change_pallet' | translate:_authService.getLanguage() }}</button>
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/pallets']">{{ 'change_location' | translate:_authService.getLanguage() }}</button>
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/inventory/findProduct']">{{ 'find_product' | translate:_authService.getLanguage() }}</button>
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/inventory/findItem']">{{ 'find_similar_items' | translate:_authService.getLanguage() }}</button>
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/inventory/listItems']">{{ 'list_pallet_items' | translate:_authService.getLanguage() }}</button>
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/inventory/cutItem']">{{ 'cut_item' | translate:_authService.getLanguage() }}</button>
                  <hr>
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/inventory/printRollLabel']">{{ 'print_roll_label' | translate:_authService.getLanguage() }}</button>
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/inventory/printProductLabel']">{{ 'print_product_label' | translate:_authService.getLanguage() }}</button>
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/inventory/printLocationLabel']">{{ 'print_location_label' | translate:_authService.getLanguage() }}</button>
              </div>
          </md-card-content>
      </md-card>
    </div>
</div>
