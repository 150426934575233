<div fxLayout="column">
  
  <div class="boxed-content-wrapper">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>{{ 'sales_orders' | translate:_authService.getLanguage() }} / {{ 'list' | translate:_authService.getLanguage()
                        }}
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white">
          <div layout="row">
            <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm"
              placeholder="{{ 'search_here' | translate:_authService.getLanguage() }}" (searchDebounce)="search($event)"
              flex>
            </td-search-box>
            <form class="push-right-sm" fxLayoutGap="16px">
              <md-select placeholder="{{ 'branch' | translate:_authService.getLanguage() }}" [(ngModel)]="branchFilter"
                (ngModelChange)="filter()" name="branch">
                <md-option [value]="'ALL'">{{ 'all_branches' | translate:_authService.getLanguage() }}</md-option>
                <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.code">{{branchItem.code}}
                  - {{branchItem.name}}</md-option>
              </md-select>

              <md-select placeholder="{{ 'status' | translate:_authService.getLanguage() }}" [(ngModel)]="statusFilter"
                (ngModelChange)="filter()" name="branch">
                <md-option [value]="'ALL'">{{ 'all' | translate:_authService.getLanguage() }}</md-option>
                <md-option
                  *ngFor="let s of ['DRAFT', 'RESERVED', 'DECLINED', 'APPROVED', 'PREPARING','PREPARED', 'SHIPPED', 'RECEIVED']"
                  [value]="s">{{s}}</md-option>
              </md-select>
            </form>

            <button md-icon-button [mdMenuTriggerFor]="exportMenu">
              <md-icon>cloud_download</md-icon>
            </button>

            <md-menu #exportMenu="mdMenu">
              <button md-menu-item (click)="_exportService.asCsv(filteredData, 'Sales')">
                <md-icon>cloud_download</md-icon>{{ 'export_this_list' | translate:_authService.getLanguage() }}
              </button>
              <button md-menu-item (click)="salesReport()">
                <md-icon>assignment_returned</md-icon>{{ 'sales_report' | translate:_authService.getLanguage() }}
              </button>
              <button md-menu-item (click)="branchTransferReport()">
                <md-icon>assignment_returned</md-icon>
                {{ 'branch_transfer_report' | translate:_authService.getLanguage()}}
              </button>
              <button md-menu-item (click)="commissionReport()">
                <md-icon>assignment_returned</md-icon>{{ 'commission_report' | translate:_authService.getLanguage()}}
              </button>
              <button md-menu-item (click)="customerAccountReport()">
                <md-icon>assignment_returned</md-icon>Customer Account Report
              </button>
            </md-menu>
          </div>
        </md-card-subtitle>
      </md-card-header>

      <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
        <md-divider></md-divider>
        <div class="mat-table-container tmp-driven-table">
          <table td-data-table class="duplicated-thead-fix">
            <thead>
              <tr td-data-table-column-row>
                <th td-data-table-column>
                  <md-checkbox flex color="primary" (click)="selectAll()" [checked]="allSelected()"></md-checkbox>
                </th>
                <th td-data-table-column *ngFor="let column of columns" [active]="sortBy == column.name"
                  [name]="column.name" [sortOrder]="sortOrder" [sortable]="true" (sortChange)="sort($event)"
                  [numeric]="false">
                  {{column.label}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr td-data-table-row *ngFor="let row of filteredData">
                <td td-data-table-cell>
                  <md-checkbox flex color="primary" (change)="select($event.checked, row)" [checked]="rowSelected(row)">
                  </md-checkbox>
                </td>
                <td td-data-table-cell>
                  <div layout="row">
                    <a class="tableLinks" href="#" [routerLink]="['/sales', 'edit', row['id']]">{{row.order_number}}</a>
                  </div>
                </td>
                <td td-data-table-cell>{{row.invoice_number}}</td>
                <td td-data-table-cell>{{row.customer_name?row.customer_name:'(Branch Transfer)'}}</td>
                <td td-data-table-cell>{{row.salesman}}</td>
                <td td-data-table-cell>{{row.order_date?.substring(0, 10)}}</td>
                <td td-data-table-cell>{{row.delivery_date?.substring(0, 10)}}</td>
                <td td-data-table-cell>{{row.pieces}}</td>
                <td td-data-table-cell>{{row.quantity | number:'1.2-2'}} {{abbreviation(row.selected_unit)}}</td>
                <td td-data-table-cell>{{row.branch_code}} - {{row.branch_name}}</td>
                <td td-data-table-cell>{{row.status}}</td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border default">
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ 'total' | translate:_authService.getLanguage() }}
                  ({{ 'page' | translate:_authService.getLanguage()}})
                </td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ total (filteredData, 'pieces') }}</td>
                <td td-data-table-cell>
                  <!--{{ total (filteredData, 'quantity') | number:'1.2-2'}}-->
                </td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border default">
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ 'total' | translate:_authService.getLanguage() }} ({{ 'all' | translate:_authService.getLanguage()
                                    }})
                </td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ total (data, 'pieces') }}</td>
                <td td-data-table-cell>
                  <!--{{ total (data, 'quantity') | number:'1.2-2' }}-->
                </td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar
            [pageSizes]="[10, 50, 100, 1000, 'All']" [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal"
            (change)="page($event)">
            <span td-paging-bar-label hide-xs>{{ 'rows_per_page' | translate:_authService.getLanguage() }}:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">{{ 'all' | translate:_authService.getLanguage() }} </span>
            <span hide-xs>{{pagingBar.total}} {{ 'items' | translate:_authService.getLanguage() }}</span>
          </td-paging-bar>
        </div>
      </md-card-content>
      <md-card-actions *ngIf="!_authService.isViewer()">
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button color="primary" class="bg-blue color-white"
            routerLink="/sales/new">{{ 'new' | translate:_authService.getLanguage() }}</button>
          <button md-button color="warn" class="bg-red color-white"
            (click)="openConfirm()">{{ 'delete' | translate:_authService.getLanguage() }}</button>
        </div>
      </md-card-actions>
    </md-card>
  </div>
</div>
