<div fxLayout="column">
  <div class="boxed-content-wrapper">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>

          <span class="md-title" fxFlex=100>{{ 'accounting' | translate:_authService.getLanguage() }} /
            <a [routerLink]="['/accounting/bank']">{{ 'account' | translate:_authService.getLanguage() }}</a> / {{account.name}} / {{ 'new_deposit' | translate:_authService.getLanguage() }}</span>

        </md-card-title>
      </md-card-header>

      <md-card-content>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="47%">
            <input mdInput placeholder="{{ 'description' | translate:_authService.getLanguage() }}" [(ngModel)]="description">
          </md-input-container>
          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'date' | translate:_authService.getLanguage() }}" type="date" [(ngModel)]="date">
          </md-input-container>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="22%">
            <input mdInput placeholder="{{ 'amount' | translate:_authService.getLanguage() }}({{account.currency}})" type="number" step="0.01" [(ngModel)]="amount">
          </md-input-container>
        </div>
      </md-card-content>

      <md-card-actions>
          <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
              <button [disabled]="_creating" md-button color="primary" class="bg-blue color-white" (click)="createDeposit()">{{ 'create' | translate:_authService.getLanguage() }}</button>
              <button [disabled]="_creating" md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
          </div>
      </md-card-actions>
    </md-card>
  </div>
</div>