<div fxLayout="column">
  

  <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
    <md-icon>warning</md-icon>
    <span>You have unsaved changes</span>
  </div>

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
      <td-steps mode="horizontal" *ngIf="!isDeclined()">
        <td-step label="DRAFT" sublabel="To be completed" [active]="true" [state]="isDraft()?'complete':'none'"
          disabled="true">NEXT: Move to ORDERED status</td-step>
        <td-step label="APPROVED" sublabel="Approve the Back Order" [state]="wasApproved()?'complete':'none'"
          [active]="true" disabled="true">The order is part of inventory</td-step>
        <td-step label="COMPLETED" sublabel="Pieces have been ordered" [state]="wasCompleted()?'complete':'none'"
          [active]="true" disabled="true">The order is part of inventory</td-step>
      </td-steps>
      <td-steps mode="horizontal" *ngIf="isDeclined()">
        <td-step label="DRAFT" sublabel="To be completed" [active]="true" state="complete" disabled="true"></td-step>
        <td-step label="DECLINED" sublabel="Order Declined" [active]="true" state="complete" disabled="true"></td-step>
      </td-steps>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex="100">Back Orders /
            <a [routerLink]="['/backorder/list']">Order List</a> /
            {{ (isEditMode && currentBo) ? currentBo.order_number : title }}
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white text-only">Order Details</md-card-subtitle>
      </md-card-header>
      <md-card-content fxLayout="column" *ngIf="currentBo">
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="22%">
            <input mdInput readonly placeholder="Order Number" [(ngModel)]="currentBo.order_number">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input [disabled]="isEditMode" mdInput flex placeholder="Prefix - Customer" [mdAutocomplete]="autoCustomer"
              [(ngModel)]="cust" (ngModelChange)="filterCustomers(cust);">
            <md-autocomplete #autoCustomer="mdAutocomplete">
              <md-option *ngFor="let customer of matchingCustomers" [value]="customer?.prefix + ' - ' + customer?.name"
                (click)="setSelectedCustomer(customer)">
                {{customer?.prefix + ' - ' + customer?.name}}
              </md-option>
            </md-autocomplete>
          </md-input-container>

          <!--
          <div fxFlex="22%" *ngIf="isEditMode && !currentBo.customer_name"></div>
          <md-select *ngIf="!isEditMode || currentBo.customer_name" fxFlex="22%" placeholder="Customer / Prefix"
            name="supplierPrefix" [(ngModel)]="currentBo.customer_id"
            (ngModelChange)="getPrefixForCustomerId($event); getSalesman($event); filterBranches()">
            <md-option *ngFor="let customer of filteredCustomers" [value]="customer.id">
              {{customer.prefix}}/{{customer.name}}</md-option>
          </md-select>
-->

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Order Date" type="date" [(ngModel)]="currentBo.order_date">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Delivery Date" type="date" [(ngModel)]="currentBo.delivery_date">
          </md-input-container>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <div fxFlex="22%"></div>

          <md-select fxFlex="22%" placeholder="Status" [(ngModel)]="currentBo.status">
            <md-option *ngFor="let status of availableStatuses" [value]="status">{{status}}</md-option>
          </md-select>

          <md-input-container fxFlex="22%">
            <input mdInput readonly placeholder="Salesman" [ngModel]="currentBo.salesman">
          </md-input-container>

          <md-select fxFlex="22%" [disabled]="isEditMode" placeholder="Branch" name="branch" [(ngModel)]="currentBo.branch_id"
            (ngModelChange)="updateTax($event); filterSalesmen(); loadCustomers();">
            <md-option *ngFor="let branch of filteredBranches" [value]="branch.id">{{branch.code}}/{{branch.name}}
            </md-option>
          </md-select>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="47%">
            <textarea mdInput placeholder="Notes" [(ngModel)]="currentBo.notes">{{currentBo.notes}}</textarea>
          </md-input-container>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Shipping Charges" [(ngModel)]="currentBo.shipping_charges">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Discount" [(ngModel)]="currentBo.discount">
          </md-input-container>

          <!-- Accept/Decline show on branch to branch, edit Only -->
          <div fxFlex="11%" *ngIf="isEditMode"></div>
          <div fxFlex="33%" *ngIf="isEditMode" fxFlexAlign="end" fxFlexAlign.lt-md="end" fxLayoutGap="6px">
            <button *ngIf="currentBo.status=='RESERVED' && _authService.hasRole('Sales') && _authService.isAdmin()"
              md-button class="bg-blue color-white" color="primary" (click)="acceptB2B()">Accept</button>
            <button *ngIf="currentBo.status=='RESERVED' && _authService.hasRole('Sales') && _authService.isAdmin()"
              md-button class="bg-blue color-white" color="primary" (click)="changeB2B()">Change</button>
            <button *ngIf="currentBo.status=='RESERVED' && _authService.hasRole('Sales') && _authService.isAdmin()"
              md-button class="bg-red color-white" color="primary" (click)="declineB2B()">Decline</button>
          </div>

        </div>
      </md-card-content>
    </md-card>

    <!-- Products -->
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-subtitle class="bg-grey color-white text-only">Order items
        </md-card-subtitle>
      </md-card-header>
      <md-card-content fxLayout="column" *ngIf="currentBo">
        <div *ngIf="!_authService.isViewer() && !wasApproved()" class="padded-container" fxLayout="row"
          fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">

          <md-input-container fxFlex="30%">
            <input mdInput flex
              placeholder="{{ 'collection' | translate:_authService.getLanguage() }} - {{ 'design' | translate:_authService.getLanguage() }} / {{ 'color' | translate:_authService.getLanguage() }}"
              [mdAutocomplete]="autoColor" [(ngModel)]="prod" (ngModelChange)="filterProducts(prod);">
            <md-autocomplete #autoColor="mdAutocomplete">
              <md-option *ngFor="let product of matchingProducts"
                [value]="product?.collection_name + ' - ' + product?.design_name + '/' + product?.name"
                (click)="setSelected(product)">
                {{product?.collection_name + ' - ' + product?.design_name + '/' + product?.name}}
              </md-option>
            </md-autocomplete>
          </md-input-container>

          <md-input-container fxFlex="15%">
            <input mdInput placeholder="{{ 'meters' | translate:_authService.getLanguage() }} " [(ngModel)]="meters">
          </md-input-container>

          <md-input-container fxFlex="15%">
            <input mdInput placeholder="{{ 'unit_price' | translate:_authService.getLanguage() }} (CAD)"
              [(ngModel)]="unitPriceCAD">
          </md-input-container>
          <div>
            <button md-button color="primary" class="bg-blue color-white" (click)="addItemToList()">ADD</button>
          </div>
        </div>

        <div class="mat-table-container tmp-driven-table">
          <table td-data-table class="duplicated-thead-fix">
            <thead>
              <tr td-data-table-column-row>
                <th td-data-table-column *ngFor="let column of columns" [numeric]="column.numeric">
                  {{column.label}}
                </th>
                <th td-data-table-column>
                  Total
                </th>
                <th td-data-table-column>

                </th>
              </tr>
            </thead>
            <tbody>
              <tr td-data-table-row *ngFor="let row of items"
                [style.background-color]="(row['quantity']*1 < row['full_length']*1)?'lightyellow':''">
                <td td-data-table-cell>{{ row['collection_name'] }}</td>
                <td td-data-table-cell>{{ row['design_name']}}/{{row['color_name'] }}</td>
                <td td-data-table-cell class="linkCursor" (click)="enterNewValueDialog(row, 'quantity')">
                  <span>{{ row['quantity'] }}</span>
                  <span *ngIf="row['quantity']*1 < row['full_length']*1">&nbsp;of {{ row['full_length']}}</span>
                </td>
                <td td-data-table-cell class="linkCursor" (click)="enterNewValueDialog(row, 'unit_price')">
                  <span>{{ row['unit_price'] | currency:_currency_symbol:'true'}}</span>
                </td>
                <td td-data-table-cell style="text-align:right">
                  {{ row['quantity'] * row['unit_price'] | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell>
                  <a (click)="removeItem(row.id)" class="linkCursor"
                    *ngIf="((((currentBo.customer_name && isEditMode) || !isEditMode) && !isApproved()) || _change_b2b_mode) && !_authService.isViewer()">
                    <md-icon class="rotate45">add_circle</md-icon>
                  </a>
                </td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low first">
                <td td-data-table-cell>{{ 'sub_total' | translate:_authService.getLanguage() }}</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{ total(items, 'quantity') }} m</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell style="text-align:right">
                  {{ totalProduct(items, 'quantity', 'unit_price')| currency:_currency_symbol:'true' }}</td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>Shipping Charges</td>
                <td td-data-table-cell style="text-align:right">
                  {{currentBo.shipping_charges | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>Discount</td>
                <td td-data-table-cell style="text-align:right">-
                  {{currentBo.discount | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell (click)="!isApproved() && openModifyTax1()" style="cursor:pointer">GST
                  ({{ currentBo.tax }}%)</td>
                <td td-data-table-cell style="text-align:right">{{(totalProduct(items, 'quantity', 'unit_price') + (currentBo.shipping_charges*1) - (currentBo.discount*1))
                  * (currentBo.tax/100) | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell (click)="!isApproved() && openModifyTax2()" style="cursor:pointer">QST
                  ({{ currentBo.tax2 }}%)</td>
                <td td-data-table-cell style="text-align:right">{{(totalProduct(items, 'quantity', 'unit_price') + (currentBo.shipping_charges*1) - (currentBo.discount*1))
                  * (currentBo.tax2/100) | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border low">
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>Total</td>
                <td td-data-table-cell style="text-align:right">{{ (totalProduct(items, 'quantity', 'unit_price') + (currentBo.shipping_charges*1) - (currentBo.discount*1))
                  * (1 + (currentBo.tax/100) + (currentBo.tax2/100)) | currency:_currency_symbol:'true'}}</td>
                <td td-data-table-cell></td>
              </tr>
            </tbody>
          </table>
        </div>

      </md-card-content>
      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button [disabled]="isLoading()" *ngIf="!isEditMode && !_authService.isViewer()" color="primary"
            class="bg-blue color-white" (click)="createNewBackOrder()">Create</button>
          <button md-button *ngIf="isEditMode && !wasCompleted() && !_authService.isViewer()" color="primary"
            class="bg-blue color-white" (click)="updateBackOrder()">Update</button>
          <button md-button [disabled]="isLoading()"
            *ngIf="isEditMode && wasApproved() && !wasCompleted() && !_authService.isViewer()" color="primary"
            class="bg-blue color-white" [routerLink]="['/sales', 'new']"
            [queryParams]="{fromBackorder:currentBo.id, useNumber:currentBo.order_number}">Create Sales Order</button>
          <button md-button [disabled]="isLoading()" color="warn" class="bg-red color-white "
            (click)="goBack()">Back</button>
        </div>
      </md-card-actions>
    </md-card>
  </div>
</div>
