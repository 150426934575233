<div fxLayout="column">
  <h4>Select new Piece</h4>
  <md-list style="height: 400px; overflow: scroll">
    <md-list-item *ngFor="let p of products" style="cursor:pointer" (click)="choose(p)">
      <h4 md-line [style.color]="(p.disabled || p.defect)?'darkgray':''">
        {{p.piece_number}} ( {{p.length}}m ) - {{p.grade_code}}
      </h4>
      <p md-line><span style="height: 60%; color:darkgray">[Location: {{p.location_name}}]</span></p>
      <p md-line *ngIf="!p.disabled"> {{p.collection_name}}/{{p.design_name}}/{{p.name}} </p>
      <p md-line *ngIf="p.defect"><md-icon class="md-18" style="display: inline-flex; vertical-align: middle; color:darkgray">warning</md-icon> This piece has defect </p>
      <p md-line *ngIf="p.reserved"><md-icon class="md-18" style="display: inline-flex; vertical-align: middle; color:darkgray">bookmark</md-icon> This piece is reserved </p>
      <p md-line *ngIf="p.disabled"> Also part of the order </p>
    </md-list-item>
  </md-list>
</div>
