<div flex layout="column" layout-wrap layout-margin>
  <md-select [(ngModel)]="bank_id" placeholder="Deposit {{total }} to Bank" flex>
    <md-option *ngFor="let b of allBanks$ | async" [value]="b.id">{{b.name}}</md-option>
  </md-select>

  <md-input-container flex>
    <input mdInput placeholder="Description" [(ngModel)]="description">
  </md-input-container>
  
  <md-input-container flex>
    <input mdInput placeholder="Effective Date" type="date" [(ngModel)]="effective_date">
  </md-input-container>

  <div flex layout="row" layout-padding layout-wrap>
    <button md-button md-raised-button (click)="close(true)">SAVE</button>
    <span flex></span>
    <button md-button md-raised-button (click)="close(false)">CANCEL</button>
  </div>
</div>