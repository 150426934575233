
    <div flex layout="column" layout-margin layout-wrap>
      <h4>{{ isEditMode?'Enter new grade':'Edit Grade' }}</h4>
      <md-divider></md-divider>
      <md-input-container>
        <input mdInput type="text" placeholder="External ID" [(ngModel)]="grade.external_id">
      </md-input-container>
      <md-input-container>
        <input mdInput type="text" placeholder="Grade" [(ngModel)]="grade.name">
      </md-input-container>
      <md-input-container>
        <input mdInput type="text" placeholder="Description" [(ngModel)]="grade.description">
      </md-input-container>
      <div flex layout="row" layout-wrap>
        <button md-raised-button color="primary" *ngIf="!isEditMode" class="bg-blue color-white" (click)="addGrade()">Add</button>
        <button md-raised-button color="primary" *ngIf="isEditMode" class="bg-blue color-white" (click)="editGrade()">Save</button>
        <button md-raised-button color="primary" class="bg-blue color-white" (click)="dialogRef.close()">Close</button>
      </div>
    </div>
  