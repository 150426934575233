<div fxLayout="column">
  <div class="boxed-content-wrapper">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>Accounting / Cheques List</span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white">
          <div layout="row">
            <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="Search Here" (searchDebounce)="search($event)"
              flex>
            </td-search-box>
            <form class="push-right-sm" fxLayoutGap="16px">
              <md-select placeholder="Branch" [(ngModel)]="branchFilter" (ngModelChange)="filter()" name="branch">
                <md-option [value]="'ALL'">All Branches</md-option>
                <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.code">{{branchItem.code}} - {{branchItem.name}}</md-option>
              </md-select>
            </form>
            <!-- If you wanted ALL data replace "filteredData" with "data" -->
            <button md-icon-button [mdMenuTriggerFor]="exportMenu">
              <md-icon>cloud_download</md-icon>
            </button>

            <md-menu #exportMenu="mdMenu">
              <button md-menu-item (click)="_exportService.asCsv(filteredData, 'Cheques')">
                <md-icon>cloud_download</md-icon>Export this list</button>
              <button md-menu-item (click)="cecReport()">
                <md-icon>assignment_returned</md-icon>CEC Report</button>
            </md-menu>
          </div>
        </md-card-subtitle>
      </md-card-header>

      <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
        <md-divider></md-divider>
        <td-data-table #dataTable [data]="filteredData" [columns]="columns" [selectable]="true" [multiple]="true" [sortable]="true"
          [sortOrder]="'ASC'" (sortChange)="sort($event)" [(ngModel)]="selectedRows">
          <ng-template tdDataTableTemplate="id" let-value="value" let-row="row" let-column="column">
            <div layout="row">
              <a class="tableLinks" flex>{{row['cheque_number']}}</a>
            </div>
          </ng-template>
          
          <ng-template tdDataTableTemplate="customer" let-value="value" let-row="row" let-column="column">
            {{row['customer_prefix']}} - {{row['customer_name']}}
          </ng-template>
          <ng-template tdDataTableTemplate="maturity_date" let-value="value" let-row="row" let-column="column">
            {{row['maturity_date']?.substring(0, 10)}}
          </ng-template>
          <ng-template tdDataTableTemplate="branch" let-value="value" let-row="row" let-column="column">
            {{row['branch_code']}} - {{row['branch_name']}}
          </ng-template>

        </td-data-table>


        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSizes]="[10, 50, 100, 1000, 'All']"
            [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal" (change)="page($event)">
            <span td-paging-bar-label hide-xs>Row per page:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">All </span>
            <span hide-xs>{{pagingBar.total}} items</span>
          </td-paging-bar>
        </div>
      </md-card-content>
      <md-card-actions *ngIf="!_authService.isViewer()">
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button [disabled]="_creating || true" md-button (click)="deposit()">Deposit ({{totalSelected()| currency:'USD':true}})</button>
        </div>
      </md-card-actions>
    </md-card>
  </div>
</div>