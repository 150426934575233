<div fxLayout="column">
    
    <div class="boxed-content-wrapper cards-no-gaps">
        <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>
                        PDA /
                        <a [routerLink]="['/pda/receiving']">{{ 'receiving' | translate:_authService.getLanguage() }}</a> / {{ 'scan_returns' | translate:_authService.getLanguage() }}
                    </span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white text-only">{{ 'scan_returns' | translate:_authService.getLanguage() }}</md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column">
                <!--div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                  <md-input-container fxFlex="47%">
                      <input mdInput placeholder="Receiving Number" [(ngModel)]="receivingNumber">
                  </md-input-container>
              </div-->
                <div class="padded-container" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-md="14px">
                    <md-list>
                        <md-list-item>
                            <md-select placeholder="{{ 'branch' | translate:_authService.getLanguage() }}" [(ngModel)]="branchFilter" (ngModelChange)="filter()" name="branch">
                                <md-option [value]="'ALL'">{{ 'all_branches' | translate:_authService.getLanguage() }}</md-option>
                                <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.code">{{branchItem.code}} - {{branchItem.name}}</md-option>
                            </md-select>
                        </md-list-item>
                        <md-list-item *ngFor="let order of filtered_received_orders">
                            <button md-button [routerLink]="[ '/pda/receiving/return/pieces', order.id ]" [queryParams]="{order_number:order.order_number, branch_id: order.branch_id}">{{ order.order_number }}</button>
                        </md-list-item>
                    </md-list>
                </div>
            </md-card-content>

            <md-card-actions>
                <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                    <!--button md-button color="primary" class="bg-blue color-white" (click)="openReceiving()">Open</button-->
                    <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
                </div>
            </md-card-actions>
        </md-card>
    </div>
</div>