<div fxLayout="column">
  
  <div class="boxed-content-wrapper">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>{{ 'page_not_found' | translate:_authService.getLanguage() }}</span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white text-only">
            {{ 'the_screen_was_not_found' | translate:_authService.getLanguage() }}
        </md-card-subtitle>
      </md-card-header>
      <md-card-content>
        <md-divider></md-divider>

      </md-card-content>
    </md-card>
  </div>
</div>