
    <div flex layout="column" layout-margin layout-wrap>
      <md-list *ngIf="lines.length > 0">
        <md-list-item *ngFor="let l of lines" [routerLink]="['/backorder', 'edit', l.id]" style="cursor:pointer" (click)="dialogRef.close()">
          <h4 md-line>{{l.bo_number}}</h4>
          <p md-line *ngIf="l.id != 0">{{l.qty}} meters - Customer: {{l.customer_name}}</p>
        </md-list-item>
      </md-list>
      <span *ngIf="lines.length == 0">No missing meterage</span>
    </div>
  