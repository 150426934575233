<div fxLayout="column">

  <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
    <md-icon>warning</md-icon>
    <span>{{ 'you_have_unsaved_changes' | translate:_authService.getLanguage() }}</span>
  </div>

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card *tdLoading="'_loading > 0'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>{{ 'pda' | translate:_authService.getLanguage() }} /
            <a [routerLink]="['../../']">{{ 'change_location' | translate:_authService.getLanguage() }}</a> /
            {{ (isEditMode && currentPallet) ? currentPallet.name : ('new_pallet' | translate:_authService.getLanguage()) }}
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white text-only">
          {{ 'pallet_details' | translate:_authService.getLanguage() }}</md-card-subtitle>
      </md-card-header>

      <md-card-content fxLayout="column" *ngIf="currentPallet">
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">

          <md-input-container fxFlex="25%">
            <input mdInput placeholder="{{ 'name' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentPallet.name">
          </md-input-container>

          <md-select placeholder="{{ 'branch' | translate:_authService.getLanguage() }}" [(ngModel)]="branchFilter"
            (ngModelChange)="getLocationsForBranch($event)" name="branch" [disabled]="itemsInPallet.length > 0">
            <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.id">{{branchItem.code}} -
              {{branchItem.name}}</md-option>
          </md-select>

          <md-input-container fxFlex="25%">
            <input mdInput placeholder="{{ 'location' | translate:_authService.getLanguage() }}"
              [(ngModel)]="currentPallet.location_name">
          </md-input-container>

        </div>
        <md-divider *ngIf="isEditMode && itemsInPallet.length > 0"></md-divider>
        <div *ngIf="isEditMode && itemsInPallet.length > 0" class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">

          <td-data-table #dataTable [data]="itemsInPallet" [columns]="columns" [selectable]="false" 
            [sortable]="false" [(ngModel)]="selectedRows" fxFlex>
          </td-data-table>

          

        </div>
        <div *ngIf="isEditMode && itemsInPallet.length > 0" class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
          Total Rolls: {{itemsInPallet.length}}
        </div>
        
      </md-card-content>
      <md-card-actions>
        <md-card-actions *ngIf="!_authService.isViewer()">
          <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
            <button *ngIf="!isEditMode" md-button color="primary" class="bg-blue color-white"
              (click)="create()">{{ 'create' | translate:_authService.getLanguage() }}</button>
            <button *ngIf="isEditMode" md-button color="primary" class="bg-blue color-white"
              (click)="save()">{{ 'save_data' | translate:_authService.getLanguage() }}</button>
            <button md-button color="warn" class="bg-red color-white"
              (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
          </div>
        </md-card-actions>
      </md-card-actions>
    </md-card>
  </div>
</div>