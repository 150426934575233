

<div>

  <table td-data-table class="duplicated-thead-fix">
    <thead>
      <tr td-data-table-column-row>
        <th td-data-table-column *ngFor="let column of columns" [numeric]="column.numeric">
          {{column.label}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr td-data-table-row *ngFor="let row of data">
        <td td-data-table-cell>{{row.effective_date.substring(0, 10)}}</td>
        <td td-data-table-cell>{{row.type}}</td>
        <td td-data-table-cell>{{row.amount | number:'1.2-2'}}</td>
      </tr>
      <tr td-data-table-column-row class="total-row no-border default">
        <td td-data-table-cell>Total</td>
        <td td-data-table-cell></td>
        <td td-data-table-cell>{{ total(data, 'amount') | number:'1.2-2' }}</td>
      </tr>
    </tbody>
  </table>

</div>
