
    <div flex layout="column" layout-margin layout-wrap>
      <h4>Enter new design name and collection</h4>
      <md-divider></md-divider>
      <md-input-container>
        <input mdInput type="text" placeholder="Design Name" [(ngModel)]="name">
      </md-input-container>
      <md-select fxFlex="22%" placeholder="Collection" [(ngModel)]="collection_id">
        <md-option *ngFor="let collection of collections" [value]="collection.id">{{collection.name}}</md-option>
      </md-select>
      <div flex layout="row" layout-wrap>
        <button md-raised-button color="primary" class="bg-blue color-white" (click)="addDesign()">Add</button>
        <button md-raised-button color="primary" class="bg-blue color-white" (click)="dialogRef.close()">Close</button>
      </div>
    </div>
  