
    <div flex layout="column" layout-margin layout-wrap>
      <h4>Enter new location and Branch</h4>
      <md-divider></md-divider>
      <md-input-container>
        <input mdInput type="text" placeholder="Location Name" [(ngModel)]="name">
      </md-input-container>
      <md-select fxFlex="22%" placeholder="Branch" [(ngModel)]="branch_id">
        <md-option *ngFor="let branch of branches" [value]="branch.id">{{branch.name}}</md-option>
      </md-select>
      <div flex layout="row" layout-wrap layout-margin>
        <button flex md-raised-button color="primary" class="bg-blue color-white" (click)="addLocation()">Add</button>
        <button flex md-raised-button color="primary" class="bg-blue color-white" (click)="dialogRef.close()">Close</button>
      </div>
    </div>
  