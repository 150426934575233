<div fxLayout="column" style="min-width: 500px;">

  <h4>Select Branch and From Date</h4>
  <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
    <md-input-container flex>
      <input mdInput type="date" placeholder="Date From" [(ngModel)]="from">
    </md-input-container>
    <md-select style="padding-top: 1px;" flex placeholder="Branch" [(ngModel)]="selectedBranch" name="branch">
      <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.id">{{branchItem.code}} -
        {{branchItem.name}}</md-option>
    </md-select>
  </div>

  <h4>Product {{selectedProduct?.collection_name}} {{selectedProduct ? ' - ' : ''}}
    {{selectedProduct?.design_name}}{{selectedProduct ? '/' : ''}} {{selectedProduct?.name}}
    {{selectedProduct ? '(' : ''}}{{selectedProduct?.id}}{{selectedProduct ? ')' : ''}}</h4>


  <md-input-container>
    <input mdInput flex placeholder="Collection - Design/Color" [mdAutocomplete]="autoColor" [(ngModel)]="prod"
      (ngModelChange)="filterProducts(prod);">
    <md-autocomplete #autoColor="mdAutocomplete">
      <md-option *ngFor="let product of matchingProducts"
        [value]="product?.collection_name + ' - ' + product?.design_name + '/' + product?.name"
        (click)="setSelected(product)">
        {{product?.collection_name + ' - ' + product?.design_name + '/' + product?.name}}
      </md-option>
    </md-autocomplete>
  </md-input-container>

  <div class="table-control-buttons" fxFlex fxLayout="row" fxFlexAlign="start center"
    fxFlexAlign.lt-md="center">
    <button md-button color="primary" class="bg-blue color-white"
      (click)="close({from:from, colorId: selectedProduct?.id, branchId: selectedBranch, fullName: selectedProduct?.collection_name + ' - ' + selectedProduct?.design_name + '/' + selectedProduct?.name})">Accept</button>
    <div flex></div>
    <button md-button color="warn" class="bg-red color-white" (click)="close(null)">Cancel</button>
  </div>



</div>
