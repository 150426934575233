<div fxLayout="column">

  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>
            PDA /
            <a [routerLink]="['/pda/shipping']">{{ 'shipping' | translate:_authService.getLanguage() }}</a> /
            {{order_number}} / {{ 'ship_order_items' | translate:_authService.getLanguage()
            }}
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white text-only">
          {{ 'ship_order' | translate:_authService.getLanguage() }}</md-card-subtitle>
      </md-card-header>

      <md-card-content>

        <div flex layout-margin>
          <span>{{ shippedStatusText() }}</span>
          <md-progress-bar mode="determinate" [value]="shippedStatusValue()"></md-progress-bar>
        </div>

        <div class="padded-container" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-md="14px"
          style="max-width: 250px">
          <h4> {{ 'notes_capital' | translate:_authService.getLanguage() }}:</h4>
          <span>{{_notes}}</span>
        </div>
      </md-card-content>

      <md-card-content>
        <div class="padded-container" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-md="14px"
          style="max-width: 250px">
          <md-input-container>
            <input mdInput placeholder="{{ 'scan_skid_pallet' | translate:_authService.getLanguage() }}"
              [(ngModel)]="_scanned">
          </md-input-container>
          <button md-button color="primary" class="bg-blue color-white"
            (click)="markScanned(_scanned)">{{ 'verify' | translate:_authService.getLanguage() }}</button>
          <md-input-container>
            <input mdInput placeholder="{{ 'final_invoice_number' | translate:_authService.getLanguage() }}"
              [(ngModel)]="_invoice_number">
          </md-input-container>
        </div>
      </md-card-content>

      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button [disabled]="_creating" md-button color="primary" class="bg-blue color-white"
            (click)="shipOrder()">{{ 'ship' | translate:_authService.getLanguage() }}</button>
          <button [disabled]="_creating" md-button color="primary" class="bg-blue color-white"
            (click)="backToPreparing()">
            <md-icon>keyboard_backspace</md-icon> {{ 'send_back_to_preparing' | translate:_authService.getLanguage() }}
          </button>
          <!--button md-button color="primary" class="bg-blue color-white" (click)="printAllFreeTextLabels()">{{ 'print_free_text_labels' | translate:_authService.getLanguage() }}</button-->
          <button [disabled]="_creating" md-button color="warn" class="bg-red color-white"
            [routerLink]="['/pda/shipping/shipOrder']">{{ 'back' | translate:_authService.getLanguage() }}</button>
        </div>
      </md-card-actions>

      <hr>

      <md-card-content fxLayout="column">

        <div class="padded-container" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-md="14px"
          *ngFor="let group of grouped_items">
          <md-list>
            <md-list-item>
              <span style="border-bottom: 1px solid black">{{group.location}} ({{group.pieces.length}} piece{{group.pieces.length > 1 ? 's': ''}})</span>
            </md-list-item>

            <md-list-item *ngFor="let item of group.pieces">
              <md-icon md-list-icon *ngIf="item.sold || item.deleted" style="color: red;">cancel</md-icon>
              <md-icon md-list-icon *ngIf="!item.checked && !item.sold && !item.deleted">radio_button_unchecked
              </md-icon>
              <md-icon md-list-icon *ngIf="item.checked" style="color: green; cursor: pointer"
                (click)="unPrepareItem(item)">check_circle</md-icon>

              <h4 md-line>
                <del *ngIf="item.sold || item.deleted">{{item.piece_number}}</del>
                <span *ngIf="!item.sold && !item.deleted">{{item.piece_number}}</span>
              </h4>
              <p md-line> {{item.collection_name}}/{{item.design_name}}/{{item.color_name}} </p>

            </md-list-item>
          </md-list>
        </div>

      </md-card-content>

      <hr>
      <md-card-content>
        <div class="padded-container" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-md="14px"
          style="max-width: 250px">
          <md-input-container>
            <input mdInput placeholder="{{ 'scan_skid_pallet' | translate:_authService.getLanguage() }}"
              [(ngModel)]="_scanned">
          </md-input-container>
          <button md-button color="primary" class="bg-blue color-white"
            (click)="markScanned(_scanned)">{{ 'verify' | translate:_authService.getLanguage() }}</button>
          <md-input-container>
            <input mdInput placeholder="{{ 'final_invoice_number' | translate:_authService.getLanguage() }}"
              [(ngModel)]="_invoice_number">
          </md-input-container>
        </div>
      </md-card-content>

      <md-card-actions>
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button [disabled]="_creating" md-button color="primary" class="bg-blue color-white"
            (click)="shipOrder()">{{ 'ship' | translate:_authService.getLanguage() }}</button>
          <button [disabled]="_creating" md-button color="primary" class="bg-blue color-white"
            (click)="backToPreparing()">
            <md-icon>keyboard_backspace</md-icon> {{ 'send_back_to_preparing' | translate:_authService.getLanguage() }}
          </button>
          <!--button md-button color="primary" class="bg-blue color-white" (click)="printAllFreeTextLabels()">{{ 'print_free_text_labels' | translate:_authService.getLanguage() }}</button-->
          <button [disabled]="_creating" md-button color="warn" class="bg-red color-white"
            [routerLink]="['/pda/shipping/shipOrder']">{{ 'back' | translate:_authService.getLanguage() }}</button>
        </div>
      </md-card-actions>
    </md-card>
  </div>
</div>
