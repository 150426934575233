<div fxLayout="column">
  
  <div class="boxed-content-wrapper">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>{{ 'purchasing' | translate:_authService.getLanguage() }} /
            {{ 'fabrictypes' | translate:_authService.getLanguage() }}</span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white">
          <div layout="row">
            <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm"
              placeholder="{{ 'search_here' | translate:_authService.getLanguage() }}" (searchDebounce)="search($event)"
              flex>
            </td-search-box>
            <!-- If you wanted ALL data replace "filteredData" with "catalogs" -->
            <button md-icon-button (click)="_exportService.asCsv(filteredData, 'Catalogs')">
              <md-icon>cloud_download</md-icon>
            </button>
          </div>
        </md-card-subtitle>
      </md-card-header>

      <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
        <td-data-table #dataTable [data]="filteredData" [columns]="columns" [selectable]="false" [multiple]="false"
          [sortable]="true" [sortOrder]="'ASC'" (sortChange)="sort($event)" [(ngModel)]="selectedRows"
          class="compressed-table">

          <ng-template tdDataTableTemplate="name" let-value="value" let-row="row" let-column="column">
            <div layout="row">
              <a class="tableLinks" href="#" flex [routerLink]="['edit/', row.id]">{{value}}</a>
            </div>
          </ng-template>
          <ng-template tdDataTableTemplate="unit_type" let-value="value" let-row="row" let-column="column">
            <div [ngSwitch]="value">
              <div *ngSwitchCase="'LENGTH'">Meters, Yards</div>
              <div *ngSwitchCase="'AREA'">Meters², SqFt</div>
              <div *ngSwitchDefault>None</div>
            </div>
          </ng-template>
          <ng-template tdDataTableTemplate="default" let-value="value" let-row="row" let-column="column">
            <md-icon *ngIf="value">check_circle</md-icon>
          </ng-template>
          <ng-template tdDataTableTemplate="active" let-value="value" let-row="row" let-column="column">
            <md-icon *ngIf="value">check_circle</md-icon>
            <md-icon *ngIf="!value">block</md-icon>
          </ng-template>
        </td-data-table>
        <div class="md-padding" *ngIf="!dataTable.hasData" layout="row" layout-align="center center">
          <h3>{{ 'no_results_to_display' | translate:_authService.getLanguage() }}.</h3>
        </div>
        <md-divider></md-divider>

        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar
            [pageSize]="_settingsService.getPageSize()" [pageSizes]="[10, 50, 100, 1000, 'All']" [total]="filteredTotal"
            (change)="page($event)">
            <span td-paging-bar-label hide-xs>{{ 'rows_per_page' | translate:_authService.getLanguage() }}:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">{{ 'all' | translate:_authService.getLanguage() }} </span>
            <span hide-xs>{{pagingBar.total}} {{ 'items' | translate:_authService.getLanguage() }}</span>
          </td-paging-bar>
        </div>
      </md-card-content>
      <md-card-actions *ngIf="!_authService.isViewer()">
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button color="primary" class="bg-blue color-white"
            routerLink="/purchasing/fabrictypes/new">{{ 'new' | translate:_authService.getLanguage() }}
          </button>
        </div>
      </md-card-actions>
    </md-card>
  </div>
</div>
