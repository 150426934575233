<div fxLayout="column">
  
  <div class="boxed-content-wrapper cards-no-gaps">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex="100">
            <a [routerLink]="['/inventory/list']">Inventory</a> / {{_piece.branch_name}} / {{_piece.collection_name}} /
            {{_piece.design_name}} /
            <a [routerLink]="['/inventory/list/pieces/', _piece.branch_id, _piece.color_id]">{{_piece.color_name}}</a> /
            <del *ngIf="_piece.deleted || _piece.sold">{{_piece.piece_number}}</del><span *ngIf="!_piece.deleted && !_piece.sold">{{_piece.piece_number}}</span></span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white text-only">Piece Detail</md-card-subtitle>
      </md-card-header>

      <md-card-content fxLayout="column"
        *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">

        <div class="padded-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px"
          fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Piece Number" [(ngModel)]="_pieceNumber">
          </md-input-container>

          <button md-button color="primary" class="bg-blue color-white" (click)="load()">Find</button>

        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <!--md-input-container fxFlex="22%">
            <input mdInput placeholder="Collection" [(ngModel)]="_piece.collection_name" (ngModelChange)="collectionChanged()">
          </md-input-container-->
          <md-select fxFlex="22%" placeholder="Collection" name="Collection" [(ngModel)]="_piece.collection_id"
            (ngModelChange)="collectionChanged($event)">
            <md-option *ngFor="let c of allCollections$ | async" [value]="c.id">{{c.name}}</md-option>
          </md-select>

          <!--md-input-container fxFlex="22%">
            <input mdInput placeholder="Design" [(ngModel)]="_piece.design_name" (ngModelChange)="designChanged()">
          </md-input-container-->
          <md-select fxFlex="22%" placeholder="Design" name="Design" [(ngModel)]="_piece.design_id"
            (ngModelChange)="designChanged($event)">
            <md-option *ngFor="let d of allDesigns$ | async" [value]="d.id">{{d.name}}</md-option>
          </md-select>

          <md-select fxFlex="22%" placeholder="Color" name="Color" [(ngModel)]="_piece.color_id">
            <md-option *ngFor="let c of allColors$ | async" [value]="c.id">{{c.name}}</md-option>
          </md-select>
          <!--md-input-container fxFlex="22%">
            <input mdInput placeholder="Color" [(ngModel)]="_piece.color_name" (ngModelChange)="colorChanged()">
          </md-input-container-->
          <md-select fxFlex="22%" placeholder="Grade" name="Grade" [(ngModel)]="_piece.grade_id">
            <md-option *ngFor="let g of allGrades$ | async" [value]="g.id">{{g.name}}</md-option>
          </md-select>

        </div>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Lot Number" [(ngModel)]="_piece.lot_number">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Length (M)" [(ngModel)]="_piece.length">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Weight (Kg)" [(ngModel)]="_piece.weight">
          </md-input-container>

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Last Updated" readonly [(ngModel)]="_piece.last_updated">
          </md-input-container>
        </div>
        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">

          <md-input-container fxFlex="22%">
            <input mdInput placeholder="Branch" readonly [(ngModel)]="_piece.branch_name">
          </md-input-container>

          <md-select fxFlex="22%" placeholder="Location" name="Location" [(ngModel)]="_piece.location_id">
            <md-option *ngFor="let location of _allLocations" [value]="location.id">{{location.name}}</md-option>
          </md-select>

          <md-checkbox [(ngModel)]="_piece.defect" fxFlex="22%"> Defect </md-checkbox>

          <md-input-container fxFlex="22%" *ngIf="_piece.defect">
            <input mdInput placeholder="Defect Comment" [(ngModel)]="_piece.defect_comment">
          </md-input-container>
        </div>

        <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
          fxLayoutGap.lt-md="14px">
          <md-checkbox [(ngModel)]="_piece.deleted" fxFlex="22%"> Deleted</md-checkbox>
          <md-checkbox [(ngModel)]="_piece.sold" fxFlex="22%"> Sold</md-checkbox>
        </div>
      </md-card-content>
      <md-card-actions *ngIf="_authService.isSuper()">
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxLayoutGap="12px" fxFlexAlign.lt-md="center">
          <button md-button color="primary" class="bg-blue color-white" (click)="update()">Update</button>
          <!--button md-button color="warn" class="bg-red color-white" (click)="delete()">Delete</button-->
        </div>
      </md-card-actions>
    </md-card>
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-subtitle class="bg-grey color-white text-only">Piece History
        </md-card-subtitle>
      </md-card-header>
      <md-card-content fxLayout="column" *tdLoading="'history'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">

        <td-data-table #dataTable [data]="filteredData" [columns]="columns" [sortOrder]="'ASC'"
          [(ngModel)]="selectedRows" class="compressed-table">
        </td-data-table>
        <div class="md-padding" *ngIf="!dataTable.hasData" layout="row" layout-align="center center">
          <h3>{{ 'no_results_to_display' | translate:_authService.getLanguage() }}.</h3>
        </div>
        <md-divider></md-divider>

        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar
            [pageSize]="_settingsService.getPageSize()" [pageSizes]="[10, 50, 100, 1000, 'All']" [total]="filteredTotal"
            (change)="page($event)">
            <span td-paging-bar-label hide-xs>{{ 'rows_per_page' | translate:_authService.getLanguage() }}:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">{{ 'all' | translate:_authService.getLanguage() }} </span>
            <span hide-xs>{{pagingBar.total}} {{ 'items' | translate:_authService.getLanguage() }}</span>
          </td-paging-bar>
        </div>


      </md-card-content>

    </md-card>
  </div>
</div>
