<md-sidenav-container class="example-container">
  <!-- this block only shows when sidenav is opened -->
  <md-sidenav #sidenav class="example-sidenav" fxLayout="column">
    <div class=" custom toolbar vertical" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="16px">
      <span [routerLink]="['']" class="logo" fxFlexFill>ERP</span>

      <button md-button *ngIf="_authService.hasRole('Purchasing')" style="text-transform: uppercase" [mdMenuTriggerFor]="appPurchasingMenu">
        {{ 'purchasing' | translate:_authService.getLanguage() }}
        <md-icon>keyboard_arrow_down</md-icon>
      </button>

      <button md-button *ngIf="_authService.hasRole('Receiving')" style="text-transform: uppercase"  [mdMenuTriggerFor]="appReceivingMenu">
        {{ 'receiving' | translate:_authService.getLanguage() }}
        <md-icon>keyboard_arrow_down</md-icon>
      </button>

      <button md-button *ngIf="_authService.hasRole('Inventory')" style="text-transform: uppercase"  [mdMenuTriggerFor]="appInventoryMenu">
        {{ 'inventory' | translate:_authService.getLanguage() }}
        <md-icon>keyboard_arrow_down</md-icon>
      </button>

      <button md-button *ngIf="_authService.hasRole('Sales')" style="text-transform: uppercase"  [mdMenuTriggerFor]="appSalesMenu">
        {{ 'sales' | translate:_authService.getLanguage() }}
        <md-icon>keyboard_arrow_down</md-icon>
      </button>

      <button md-button *ngIf="_authService.hasRole('Accounting')" style="text-transform: uppercase"  [mdMenuTriggerFor]="appAccountingMenu">
        {{ 'accounting' | translate:_authService.getLanguage() }}
        <md-icon>keyboard_arrow_down</md-icon>
      </button>

      <button md-button *ngIf="_authService.hasRole('PDA')" style="text-transform: uppercase"  [mdMenuTriggerFor]="appPDAMenu">
        PDA
        <md-icon>keyboard_arrow_down</md-icon>
      </button>
    </div>
  </md-sidenav>


  <!-- default primary top toolbar -->
  <md-toolbar color="{{_prod ? 'primary' : 'warn'}}">

    <button type="button" class="mm-button" md-button (click)="sidenav.toggle()" fxHide fxShow.lt-lg>
      <md-icon class="">menu</md-icon>
    </button>

    <span [routerLink]="['']" class="logo" (click)="sidenav.close()">ERP</span>

    <md-menu #appPurchasingMenu="mdMenu" yPosition="below" [overlapTrigger]="false">
      <!-- Collections and designs are accessible via direct routing, but hidden from the menu -->
      <!--button md-menu-item [routerLink]="['/purchasing/collections']" (click)="sidenav.close()">Collections</button>
      <button md-menu-item [routerLink]="['/purchasing/designs']" (click)="sidenav.close()">Designs</button-->
      <button *ngIf="_authService.isSuper()" md-menu-item [routerLink]="['/purchasing/catalogs']" (click)="sidenav.close()">{{ 'catalogs' | translate:_authService.getLanguage() }}</button>
      <button *ngIf="_authService.isSuper()" md-menu-item [routerLink]="['/purchasing/products']" (click)="sidenav.close()">{{ 'products' | translate:_authService.getLanguage() }}</button>
      <button *ngIf="_authService.isSuper()" md-menu-item [routerLink]="['/purchasing/fabrictypes']" (click)="sidenav.close()">{{ 'fabrictypes' | translate:_authService.getLanguage() }}</button>
      <button *ngIf="_authService.isSuper()" md-menu-item [routerLink]="['/purchasing/suppliers']" (click)="sidenav.close()"> {{ 'suppliers' | translate:_authService.getLanguage() }} </button>
      <button md-menu-item [routerLink]="['/purchasing/po']" (click)="sidenav.close()"> {{ 'po_list' | translate:_authService.getLanguage() }} </button>
    </md-menu>

    <md-menu #appReceivingMenu="mdMenu" yPosition="below" [overlapTrigger]="false">
      <button md-menu-item [routerLink]="['/receiving/list']" routerLinkActive="active" (click)="sidenav.close()">{{ 'receiving_list' | translate:_authService.getLanguage() }}</button>
    </md-menu>

    <md-menu #appInventoryMenu="mdMenu" yPosition="below" [overlapTrigger]="false">
      <button md-menu-item [routerLink]="['/inventory/list']" routerLinkActive="active" (click)="sidenav.close()"> {{ 'inventory_list' | translate:_authService.getLanguage() }} </button>
      <button md-menu-item [routerLink]="['/inventory/list/piece']" routerLinkActive="active" (click)="sidenav.close()"> {{ 'piece' | translate:_authService.getLanguage() }} </button>
    </md-menu>

    <md-menu #appSalesMenu="mdMenu" yPosition="below" [overlapTrigger]="false">
      <button md-menu-item [routerLink]="['/sales/list']" routerLinkActive="active" (click)="sidenav.close()"> {{ 'sales_order_list' | translate:_authService.getLanguage() }} </button>
      <button *ngIf="_authService.isAdmin()" md-menu-item [routerLink]="['/sales/forcasting']" routerLinkActive="active" (click)="sidenav.close()"> {{ 'sales_assistant' | translate:_authService.getLanguage() }} </button>
      <button md-menu-item [routerLink]="['/debitnotes/list']" routerLinkActive="active" (click)="sidenav.close()"> {{ 'debit_and_credit_notes_list' | translate:_authService.getLanguage() }} </button>
      <button md-menu-item [routerLink]="['/backorder/list']" routerLinkActive="active" (click)="sidenav.close()"> {{ 'back_order_list' | translate:_authService.getLanguage() }} </button>
      <button md-menu-item [routerLink]="['/refunds/list']" routerLinkActive="active" (click)="sidenav.close()"> {{ 'return_order_list' | translate:_authService.getLanguage() }} </button>
      <button *ngIf="_authService.isExecutive()" md-menu-item [routerLink]="['/accounting/customers']" routerLinkActive="active" (click)="sidenav.close()">{{ 'customers' | translate:_authService.getLanguage() }}</button>
    </md-menu>

    <md-menu #appAccountingMenu="mdMenu" yPosition="below" [overlapTrigger]="false">
      <button *ngIf="_authService.isAdmin()" md-menu-item [routerLink]="['/accounting/invoices']" routerLinkActive="active" (click)="sidenav.close()">{{ 'invoice_list' | translate:_authService.getLanguage() }}</button>
      <button *ngIf="_authService.isAdmin()" md-menu-item [routerLink]="['/accounting/cheques']" routerLinkActive="active" (click)="sidenav.close()">{{ 'cheques_list' | translate:_authService.getLanguage() }}</button>
      <button *ngIf="_authService.isAdmin()" md-menu-item [routerLink]="['/accounting/bank']" routerLinkActive="active" (click)="sidenav.close()">{{ 'accounts_management' | translate:_authService.getLanguage() }}</button>
      <button *ngIf="_authService.isSuper()" md-menu-item [routerLink]="['/accounting/branches']" routerLinkActive="active" (click)="sidenav.close()">{{ 'branches' | translate:_authService.getLanguage() }}</button>
      <button *ngIf="_authService.isExecutive()" md-menu-item [routerLink]="['/accounting/locations']" routerLinkActive="active" (click)="sidenav.close()">{{ 'locations' | translate:_authService.getLanguage() }}</button>
      <button *ngIf="_authService.isExecutive()" md-menu-item [routerLink]="['/accounting/pallets']" routerLinkActive="active" (click)="sidenav.close()">{{ 'pallets' | translate:_authService.getLanguage() }}</button>
      <button *ngIf="_authService.isExecutive()" md-menu-item [routerLink]="['/accounting/grades']" routerLinkActive="active" (click)="sidenav.close()">{{ 'grades' | translate:_authService.getLanguage() }}</button>
      <button *ngIf="_authService.isExecutive()" md-menu-item [routerLink]="['/admin/options']" routerLinkActive="active" (click)="sidenav.close()">{{ 'options' | translate:_authService.getLanguage() }}</button>
      <button *ngIf="_authService.isSuper()" md-menu-item [routerLink]="['/admin/unit-prices']" routerLinkActive="active" (click)="sidenav.close()">{{ 'unit_prices' | translate:_authService.getLanguage() }}</button>
      <button *ngIf="_authService.isExecutive()" md-menu-item [routerLink]="['/admin/cities']" routerLinkActive="active" (click)="sidenav.close()">{{ 'cities' | translate:_authService.getLanguage() }}</button>
    </md-menu>

    <!--md-menu #appAccountingMenu="mdMenu" yPosition="below" [overlapTrigger]="false">
      <button md-menu-item [routerLink]="['/accounting/branches']" (click)="sidenav.close()">Branches</button>
      <button md-menu-item [routerLink]="['/accounting/customers']" (click)="sidenav.close()">Customers</button>
    </md-menu-->

    <md-menu #appPDAMenu="mdMenu" yPosition="below" [overlapTrigger]="false">
      <button *ngIf="_authService.isSuper()" md-menu-item [routerLink]="['/pda/import']" routerLinkActive="active" (click)="sidenav.close()">{{ 'import' | translate:_authService.getLanguage() }}</button>
      <button md-menu-item [routerLink]="['/pda/receiving']" routerLinkActive="active" (click)="sidenav.close()">{{ 'receiving' | translate:_authService.getLanguage() }}</button>
      <button md-menu-item [routerLink]="['/pda/inventory']" routerLinkActive="active" (click)="sidenav.close()">{{ 'inventory' | translate:_authService.getLanguage() }}</button>
      <button md-menu-item [routerLink]="['/pda/shipping']" routerLinkActive="active" (click)="sidenav.close()">{{ 'shipping' | translate:_authService.getLanguage() }}</button>

      
    </md-menu>

    <md-menu #appAdminMenu="mdMenu" yPosition="below" [overlapTrigger]="false">
      <button *ngIf="_authService.isSuper()" md-menu-item [routerLink]="['/admin/users/list']" routerLinkActive="active" (click)="sidenav.close()">{{ 'users' | translate:_authService.getLanguage() }}</button>
    </md-menu>

    <button md-button *ngIf="_authService.hasRole('Purchasing')" style="text-transform: uppercase" [mdMenuTriggerFor]="appPurchasingMenu" fxHide.lt-lg>
      {{ 'purchasing' | translate:_authService.getLanguage() }}
      <md-icon>keyboard_arrow_down</md-icon>
    </button>

    <button md-button *ngIf="_authService.hasRole('Receiving')" style="text-transform: uppercase" [mdMenuTriggerFor]="appReceivingMenu" fxHide.lt-lg>
      {{ 'receiving' | translate:_authService.getLanguage() }}
      <md-icon>keyboard_arrow_down</md-icon>
    </button>

    <button md-button *ngIf="_authService.hasRole('Inventory')" style="text-transform: uppercase" [mdMenuTriggerFor]="appInventoryMenu" fxHide.lt-lg>
      {{ 'inventory' | translate:_authService.getLanguage() }}
      <md-icon>keyboard_arrow_down</md-icon>
    </button>

    <button md-button *ngIf="_authService.hasRole('Sales')" style="text-transform: uppercase"  [mdMenuTriggerFor]="appSalesMenu" fxHide.lt-lg>
      {{ 'sales' | translate:_authService.getLanguage() }}
      <md-icon>keyboard_arrow_down</md-icon>
    </button>

    <button md-button *ngIf="_authService.hasRole('Accounting')" style="text-transform: uppercase"  [mdMenuTriggerFor]="appAccountingMenu" fxHide.lt-lg>
      {{ 'accounting' | translate:_authService.getLanguage() }}
      <md-icon>keyboard_arrow_down</md-icon>
    </button>

    <button md-button *ngIf="_authService.hasRole('PDA')" style="text-transform: uppercase" [mdMenuTriggerFor]="appPDAMenu" fxHide.lt-lg>
      PDA
      <md-icon>keyboard_arrow_down</md-icon>
    </button>

    <span class="fill-remaining-space"></span>

    <button md-button *ngIf="_authService.hasRole('Admin')" style="text-transform: uppercase"  [mdMenuTriggerFor]="appAdminMenu">
      {{ 'admin' | translate:_authService.getLanguage() }}
      <md-icon>keyboard_arrow_down</md-icon>
    </button>

    <button md-button *ngIf="_authService.isLoggedIn" style="text-transform: uppercase" (click)="logout()">
      {{ 'logout' | translate:_authService.getLanguage() }}
    </button>
  </md-toolbar>

  <router-outlet></router-outlet>

</md-sidenav-container>
