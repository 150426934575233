<div fxLayout="column">
    
    <div class="boxed-content-wrapper cards-no-gaps">
        <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>
                        PDA /
                        <a [routerLink]="['/pda/inventory']">{{ 'inventory' | translate:_authService.getLanguage() }}</a> / {{ 'print_product_label' | translate:_authService.getLanguage() }}
                    </span>
                </md-card-title>
                <!--md-card-subtitle class="bg-grey color-white text-only">Print Product Label</md-card-subtitle-->
                <md-card-subtitle class="bg-grey color-white">
                        <div layout="row">
                        <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="{{ 'search_here' | translate:_authService.getLanguage() }}" (searchDebounce)="search($event)"
                            flex>
                        </td-search-box>
                    </div>
                </md-card-subtitle>
            </md-card-header>

            <md-card-content fxLayout="column">
                <td-data-table #dataTable [data]="filteredData" [columns]="columns" [selectable]="false" [multiple]="false" [sortable]="true"
                    [sortOrder]="'ASC'" (sortChange)="sort($event)" [(ngModel)]="selectedRows" class="compressed-table">
                    <ng-template tdDataTableTemplate="id" let-value="value" let-row="row" let-column="column">
                        <div layout="row">
                            <button md-button (click)="print(row['id'])">{{row['id']}}</button>
                        </div>
                    </ng-template>
                </td-data-table>

                <div class="md-padding" *ngIf="!dataTable.hasData" layout="row" layout-align="center center">
                    <h3>{{ 'no_results_to_display' | translate:_authService.getLanguage() }}</h3>
                </div>
                <md-divider></md-divider>

                <div fxLayout='row' fxLayout.lt-md="column">
                    <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSizes]="[10, 50, 100, 1000, 'All']"
                        [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal" (change)="page($event)">
                        <span td-paging-bar-label hide-xs>{{ 'rows_per_page' | translate:_authService.getLanguage() }}:</span>
                        <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
                        <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
                        <span *ngIf="pagingBar.pageSize == 'All'">{{ 'all' | translate:_authService.getLanguage() }} </span>
                        <span hide-xs>{{pagingBar.total}} {{ 'items' | translate:_authService.getLanguage() }}</span>
                    </td-paging-bar>
                </div>
            </md-card-content>

            <md-card-actions>
                <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                    <!--button md-button color="primary" class="bg-blue color-white" (click)="print()">Print</button-->
                    <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
                </div>
            </md-card-actions>
        </md-card>
    </div>
</div>