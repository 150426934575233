<div fxLayout="column">
    <div class="boxed-content-wrapper">
      <md-card>
        <md-card-header fxLayout="column">
          <md-card-title>
            <span class="md-title" fxFlex=100>{{ 'accounting' | translate:_authService.getLanguage() }} / {{ 'accounts_management' | translate:_authService.getLanguage() }}</span>
          </md-card-title>
          <md-card-subtitle class="bg-grey color-white">
            <div layout="row">
              <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="{{ 'search_here' | translate:_authService.getLanguage() }}" (searchDebounce)="search($event)"
                flex>
              </td-search-box>
              <form class="push-right-sm" fxLayoutGap="16px">
                <md-select placeholder="{{ 'branch' | translate:_authService.getLanguage() }}" [(ngModel)]="branchFilter" (ngModelChange)="filter()" name="branch">
                  <md-option [value]="'ALL'">{{ 'all_branches' | translate:_authService.getLanguage() }}</md-option>
                  <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.code">{{branchItem.code}} - {{branchItem.name}}</md-option>
                </md-select>
              </form>
              <!-- If you wanted ALL data replace "filteredData" with "data" -->
              <button md-icon-button (click)="_exportService.asCsv(filteredData, 'Accounts')">
                <md-icon>cloud_download</md-icon>
              </button>
            </div>
          </md-card-subtitle>
        </md-card-header>
  
        <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
          <md-divider></md-divider>
          <td-data-table #dataTable [data]="filteredData" [columns]="columns" [selectable]="true" [multiple]="true" [sortable]="true"
            [sortOrder]="'ASC'" (sortChange)="sort($event)" [(ngModel)]="selectedRows">
            <ng-template tdDataTableTemplate="id" let-value="value" let-row="row" let-column="column">
              <div layout="row">
                <a class="tableLinks" href="#" flex [routerLink]="['ledger', value]">{{value}}</a>
              </div>
            </ng-template>
          </td-data-table>
  
  
          <div fxLayout='row' fxLayout.lt-md="column">
            <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSizes]="[10, 50, 100, 1000, 'All']"
              [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal" (change)="page($event)">
              <span td-paging-bar-label hide-xs>{{ 'rows_per_page' | translate:_authService.getLanguage() }}:</span>
              <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
              <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>{{ 'of' | translate:_authService.getLanguage() }}</span>
              <span *ngIf="pagingBar.pageSize == 'All'">{{ 'all' | translate:_authService.getLanguage() }} </span>
              <span hide-xs>{{pagingBar.total}} {{ 'items' | translate:_authService.getLanguage() }}</span>
            </td-paging-bar>
          </div>
        </md-card-content>
        <md-card-actions *ngIf="!_authService.isViewer()">
          <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
            <button md-button color="primary" class="bg-blue color-white" [routerLink]="['/accounting', 'bank', 'new']">{{ 'new' | translate:_authService.getLanguage() }}</button>
          </div>
        </md-card-actions>
      </md-card>
    </div>
  </div>