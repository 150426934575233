<div fxLayout="column">
    

    <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
        <md-icon>warning</md-icon>
        <span>{{ 'you_have_unsaved_changes' | translate:_authService.getLanguage() }}</span>
    </div>

    <div class="boxed-content-wrapper cards-no-gaps">
        <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>{{ 'purchasing' | translate:_authService.getLanguage() }} /
                        <a [routerLink]="['/purchasing/products']">{{ 'all_products' | translate:_authService.getLanguage() }}</a> / {{ (isEditMode && currentProduct)
                        ? currentProduct.collection_name + ' - ' + currentProduct.design_name + ' - ' + currentProduct.name
                        : title }}
                    </span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white text-only">{{ 'product_detail' | translate:_authService.getLanguage() }}</md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column" *ngIf="currentProduct">
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-select fxFlex="22%" placeholder="{{ 'fabric_type' | translate:_authService.getLanguage() }}" name="fabricType" [(ngModel)]="currentProduct.fabric_type_id">
                        <md-option *ngFor="let fabricTypeItem of allFabricTypes$ | async" [value]="fabricTypeItem.id">{{fabricTypeItem.name}}</md-option>
                    </md-select>

                    <md-input-container fxFlex="22%">
                        <input mdInput [mdAutocomplete]="collAuto" placeholder="{{ 'collection' | translate:_authService.getLanguage() }}" [(ngModel)]="currentProduct.collection_name"
                            (ngModelChange)="filterCollections($event, true)">

                        <md-hint *ngIf="!collectionExists(currentProduct.collection_name)" align="end">
                            <span style="color:red">{{ 'this_collection_will_be_created' | translate:_authService.getLanguage() }}</span>
                        </md-hint>

                        <md-autocomplete #collAuto="mdAutocomplete">
                            <md-option *ngFor="let c of collections" [value]="c">
                                {{ c }}
                            </md-option>
                        </md-autocomplete>
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput [mdAutocomplete]="desAuto" placeholder="Design" [(ngModel)]="currentProduct.design_name" (ngModelChange)="filterDesigns($event, true)">

                        <md-hint *ngIf="!currentProduct.collection_name" align="end">
                            <span style="color:red">{{ 'enter_collection_first' | translate:_authService.getLanguage() }}</span>
                        </md-hint>

                        <md-hint *ngIf="currentProduct.collection_name && !designExists(currentProduct.design_name)" align="end">
                            <span style="color:red">{{ 'this_design_will_be_created' | translate:_authService.getLanguage() }}</span>
                        </md-hint>

                        <md-hint *ngIf="currentProduct.collection_name && designExists(currentProduct.design_name) && !designInCollection(currentProduct.collection_name,currentProduct.design_name)"
                            align="end">
                            <span style="color:red">{{ 'this_design_exists_as_part_of_another_collection_is_the_name_correct' | translate:_authService.getLanguage()
                                }}?</span>
                        </md-hint>

                        <md-autocomplete #desAuto="mdAutocomplete">
                            <md-option *ngFor="let d of designs" [value]="d">
                                {{ d }}
                            </md-option>
                        </md-autocomplete>
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'color' | translate:_authService.getLanguage() }}" [(ngModel)]="currentProduct.name">
                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-select fxFlex="22%" placeholder="{{ 'country_of_origin' | translate:_authService.getLanguage() }}" name="countryOfOrigin" [(ngModel)]="currentProduct.country_of_origin_id">
                        <md-option *ngFor="let countryItem of allCountriesOfOrigin$ | async" [value]="countryItem.id">{{countryItem.name}}</md-option>
                    </md-select>
                    <!--md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'country_of_origin' | translate:_authService.getLanguage() }}"
                            [mdAutocomplete]="autoCountry" [(ngModel)]="currentProduct.country_name" (ngModelChange)="true">
                        <md-autocomplete #autoCountry="mdAutocomplete">
                            <md-option *ngFor="let countryItem of allCountriesOfOrigin$ | async" [value]="countryItem.name">
                                <span>{{countryItem.name}}</span>
                            </md-option>
                        </md-autocomplete>
                    </md-input-container-->

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'customs_code' | translate:_authService.getLanguage() }}" [(ngModel)]="currentProduct.customs_code">
                    </md-input-container>

                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="{{ 'composition' | translate:_authService.getLanguage() }}" [(ngModel)]="currentProduct.composition">
                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'weight_gsm' | translate:_authService.getLanguage() }}" [(ngModel)]="currentProduct.weight_gsm">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'width' | translate:_authService.getLanguage() }} (CM)" [(ngModel)]="currentProduct.width">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput readonly placeholder="{{ 'design_unit_price' | translate:_authService.getLanguage() }}" [value]="currentProduct.unit_price_eur || 0">
                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <textarea mdInput placeholder="{{ 'notes' | translate:_authService.getLanguage() }}" [(ngModel)]="currentProduct.notes"></textarea>
                    </md-input-container>
                </div>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-header fxLayout="column">
                <md-card-subtitle class="bg-grey color-white text-only">{{ 'supplier_product_detail' | translate:_authService.getLanguage() }}</md-card-subtitle>
            </md-card-header>
            <md-card-content *ngIf="currentProduct">
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">

                    <md-select fxFlex="22%" placeholder="{{ 'supplier' | translate:_authService.getLanguage() }}" name="supplierType" [(ngModel)]="currentProduct.supplier_id">
                        <md-option *ngFor="let supplierItem of allSuppliers" [value]="supplierItem.id">{{supplierItem.name}}</md-option>
                    </md-select>
                    <!--md-input-container fxFlex="22%">
                        <input mdInput *ngIf="isEditMode" readonly placeholder="Collection" [value]="lookupSupplier(currentProduct.supplier_id)">
                    </md-input-container-->

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'collection' | translate:_authService.getLanguage() }}" [(ngModel)]="currentProduct.collection_supplier_name">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'design' | translate:_authService.getLanguage() }}" [(ngModel)]="currentProduct.design_supplier_name">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'color' | translate:_authService.getLanguage() }}" [(ngModel)]="currentProduct.color_supplier_name">
                    </md-input-container>
                </div>
            </md-card-content>
        </md-card>
        <md-card class="product-new-picture-card">
            <md-card-header fxLayout="column">
                <md-card-subtitle class="bg-grey color-white text-only">{{ 'picture' | translate:_authService.getLanguage() }}</md-card-subtitle>
            </md-card-header>
            <div fxLayout="row" fxLayoutGap="16px" class="image-previews" layout-padding layout-margin fxLayout.lt-md="row wrap">
                <!--div class="image-preview-i" fxLayoutAlign="end" fxLayout="row" fxFlexOffset="20px" *ngFor="let imageUrl of currentProduct.attachments"
                    [ngStyle]="{ background: 'url(' + imageUrl + ')' }">
                    <a fxFlexAlign="start" href="#" class="remove-preview" (click)="deleteCurrentPreview()">
                        <md-icon>close</md-icon>
                    </a>
                </div-->
                <div class="image-preview-i" *ngFor="let imageUrl of currentProduct.attachments">
                    <img class="image" [src]="imageUrl">

                    <div flex layout="row" class="image-header" layout-align="end center">
                        <md-icon class="linkCursor" (click)="removePicture(imageUrl)">clear</md-icon>
                    </div>
                </div>

            </div>
            <md-card-content>
                <div class="padded-container">
                    <td-file-upload #fileUpload (select)="selectEvent($event)" (upload)="uploadEvent($event)" (cancel)="cancelEvent()" accept=".png,.jpg,.jpeg,"
                        defaultColor="accent" activeColor="warn" cancelColor="primary" [disabled]="false">
                        <md-icon>file_upload</md-icon>
                        <span>
                            {{ fileUpload.files?.name || fileUpload.files?.length }}
                            <span *ngIf="fileUpload.files?.length">{{ 'files_selected' | translate:_authService.getLanguage() }}</span>
                        </span>
                        <ng-template td-file-input-label>
                            <md-icon>attach_file</md-icon>
                            <span>{{ 'choose_image' | translate:_authService.getLanguage() }}...</span>
                        </ng-template>
                    </td-file-upload>
                </div>
            </md-card-content>
            <md-card-actions *ngIf="!_authService.isViewer()">
                <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                    <button *ngIf="!isEditMode" md-button color="primary" class="bg-blue color-white" (click)="createProduct()">{{ 'create' | translate:_authService.getLanguage() }}</button>
                    <button *ngIf="isEditMode" md-button color="primary" class="bg-blue color-white" (click)="saveProduct()">{{ 'save_data' | translate:_authService.getLanguage() }}</button>
                    <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
                </div>
            </md-card-actions>
        </md-card>
    </div>
</div>
