
    <div flex layout="column" layout-margin layout-wrap>
      <h4>Enter new city and country</h4>
      <md-divider></md-divider>
      <md-input-container>
        <input mdInput type="text" placeholder="City Name" [(ngModel)]="city">
      </md-input-container>
      <md-select fxFlex="22%" placeholder="Country" [(ngModel)]="country_id">
        <md-option *ngFor="let country of countries" [value]="country.id">{{country.name}}</md-option>
      </md-select>
      <div flex layout="row" layout-wrap>
        <button md-raised-button color="primary" class="bg-blue color-white" (click)="addCity()">Add</button>
        <button md-raised-button color="primary" class="bg-blue color-white" (click)="dialogRef.close()">Close</button>
      </div>
    </div>
  