<div fxLayout="column">
    
    <div class="boxed-content-wrapper cards-no-gaps">
        <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>{{ 'purchasing' | translate:_authService.getLanguage() }}  /
                        <a [routerLink]="['/purchasing/suppliers']">{{ 'suppliers' | translate:_authService.getLanguage() }} </a> / {{ (isEditMode && currentSupplier) ? currentSupplier.name : title }}
                    </span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white text-only" *ngIf="isEditMode">{{ 'edit_supplier' | translate:_authService.getLanguage() }} </md-card-subtitle>
                <md-card-subtitle class="bg-grey color-white text-only" *ngIf="!isEditMode">{{ 'create_supplier' | translate:_authService.getLanguage() }} </md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column" *ngIf="currentSupplier">
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'name' | translate:_authService.getLanguage() }} " [(ngModel)]="currentSupplier.name">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'prefix' | translate:_authService.getLanguage() }}" [(ngModel)]="currentSupplier.code">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'contact_name' | translate:_authService.getLanguage() }}" [(ngModel)]="currentSupplier.contact_person">
                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="{{ 'address' | translate:_authService.getLanguage() }}" [(ngModel)]="currentSupplier.address">
                    </md-input-container>

                    <md-select fxFlex="22%" placeholder="{{ 'city' | translate:_authService.getLanguage() }} / {{ 'country' | translate:_authService.getLanguage() }}" name="city_id" [(ngModel)]="currentSupplier.city_id">
                        <md-option *ngFor="let location of allCity$ | async" [value]="location.id">{{location.name}}/{{location.country_name}}</md-option>
                    </md-select>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'phone' | translate:_authService.getLanguage() }}" [(ngModel)]="currentSupplier.phone">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'fax' | translate:_authService.getLanguage() }}" [(ngModel)]="currentSupplier.fax">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'email_address' | translate:_authService.getLanguage() }}" [(ngModel)]="currentSupplier.email">
                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <textarea mdInput placeholder="{{ 'notes' | translate:_authService.getLanguage() }}" [(ngModel)]="currentSupplier.notes">Some notes here</textarea>
                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="column" fxLayoutGap="20px" *ngIf="isEditMode" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <span class="row-subtitle">{{ 'branches' | translate:_authService.getLanguage() }}</span>
                    <md-checkbox [checked]="checkBranch(branchItem)" (change)="selectBranch($event, branchItem)" *ngFor="let branchItem of allBranches"
                        flex color="primary">{{branchItem.name}}
                    </md-checkbox>
                </div>
                <div class="padded-container" fxLayout="column" fxLayoutGap="20px" *ngIf="!isEditMode" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <span class="row-subtitle">{{ 'branches' | translate:_authService.getLanguage() }}</span>
                    <md-checkbox (change)="selectBranch($event, branchItem)" *ngFor="let branchItem of allBranches" flex class="example-margin"
                        color="primary" [value]="branchItem">{{branchItem.name}}
                    </md-checkbox>
                </div>

                <md-card-actions *ngIf="!_authService.isViewer()">
                    <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                        <button md-button *ngIf="!isEditMode" color="primary" class="bg-blue color-white" (click)="saveSupplier()">{{ 'create' | translate:_authService.getLanguage() }}</button>
                        <button md-button *ngIf="isEditMode" color="primary" class="bg-blue color-white" (click)="saveSupplier()">{{ 'save' | translate:_authService.getLanguage() }}</button>
                        <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
                    </div>
                </md-card-actions>
            </md-card-content>
        </md-card>
    </div>
</div>