<div fxLayout="column">
    <div class="boxed-content-wrapper">
        <md-card>
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>{{ 'accounting' | translate:_authService.getLanguage() }} / {{ 'branches' | translate:_authService.getLanguage()
                        }}</span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white">
                    <div layout="row">
                        <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="Search Here" (searchDebounce)="search($event)"
                            flex>
                        </td-search-box>
                        <button md-icon-button (click)="_exportService.asCsv(branches, 'Branches')">
                            <md-icon>cloud_download</md-icon>
                        </button>
                    </div>
                </md-card-subtitle>
            </md-card-header>

            <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
                <md-divider></md-divider>
                <td-data-table #dataTable [data]="filteredData" [columns]="columns" [selectable]="true" [multiple]="true" [sortable]="true"
                    [sortOrder]="'ASC'" (sortChange)="sort($event)" [(ngModel)]="selectedRows" class="compressed-table">

                    <ng-template tdDataTableTemplate="name" let-value="value" let-row="row" let-column="column">
                        <div layout="row">
                            <a class="tableLinks" href="#" flex [routerLink]="['edit/', row.id]">{{value}}</a>
                        </div>
                    </ng-template>

                    <ng-template tdDataTableTemplate="branches" let-value="value" let-row="row" let-column="column">
                        <div layout="row" class="supplier-list-branches">
                            <span *ngFor="let item of value; let i = index">
                                <span *ngIf="i !== 0">, </span>{{item.name}}</span>
                        </div>
                    </ng-template>

                    <ng-template tdDataTableTemplate="city_id" let-value="value" let-row="row" let-column="column">
                        <div layout="row">
                            <span *ngIf="!!allCities[value]">{{allCities[value].name}}</span>
                        </div>
                    </ng-template>

                </td-data-table>
                <div class="md-padding" *ngIf="!dataTable.hasData" layout="row" layout-align="center center">
                    <h3>No results to display.</h3>
                </div>
                <md-divider></md-divider>

                <div fxLayout='row' fxLayout.lt-md="column">
                    <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSizes]="[10, 50, 100, 1000, 'All']"
                        [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal" (change)="page($event)">
                        <span td-paging-bar-label hide-xs>Row per page:</span>
                        <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
                        <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
                        <span *ngIf="pagingBar.pageSize == 'All'">All </span>
                        <span hide-xs>{{pagingBar.total}} items</span>
                    </td-paging-bar>
                </div>
            </md-card-content>
            <md-card-actions *ngIf="!_authService.isViewer()">
                <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                    <button md-button color="primary" class="bg-blue color-white" routerLink="new">{{ 'new' | translate:_authService.getLanguage() }}</button>
                    <button md-button color="warn" class="bg-red color-white" (click)="delete()">{{ 'delete' | translate:_authService.getLanguage() }}</button>
                </div>
            </md-card-actions>
        </md-card>
    </div>
</div>