<div fxLayout="column">
    
    <div class="boxed-content-wrapper">
        <md-card>
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>Purchasing / PO List</span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white">
                    <div layout="row">
                        <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="Search Here" (searchDebounce)="search($event)"
                            flex>
                        </td-search-box>
                        <form class="push-right-sm" fxLayoutGap="16px">
                            <md-select placeholder="Branch" [(ngModel)]="branchFilter" (ngModelChange)="filter()" name="branch">
                                <md-option [value]="'ALL'">All Branches</md-option>
                                <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.code">{{branchItem.code}} - {{branchItem.name}}</md-option>
                            </md-select>
                        </form>
                        <!-- If you wanted ALL data replace "filteredData" with "data" -->
                        <button md-icon-button [mdMenuTriggerFor]="exportMenu">
                            <md-icon>cloud_download</md-icon>
                        </button>

                        <md-menu #exportMenu="mdMenu">
                            <button md-menu-item (click)="_exportService.asCsv(filteredData, 'PO List')">
                                <md-icon>cloud_download</md-icon>Export this list</button>
                            <button md-menu-item (click)="orderSchedule()" *ngIf="_authService.isSuper()">
                                <md-icon>assignment_returned</md-icon>Order Schedule</button>
                            <button md-menu-item (click)="productOrdersReport()" *ngIf="_authService.isSuper()">
                                <md-icon>assignment_returned</md-icon>Product Orders Report</button>
                            <button md-menu-item (click)="stockProjectionReport()" *ngIf="_authService.isSuper()">
                                <md-icon>assignment_returned</md-icon>Stock Projection Report</button>
                        </md-menu>
                    </div>
                </md-card-subtitle>
            </md-card-header>

            <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
                <md-divider></md-divider>
                <table td-data-table class="duplicated-thead-fix">
                    <thead>
                        <tr td-data-table-column-row>
                            <th td-data-table-column>
                                <md-checkbox flex color="primary" (click)="selectAll()" [checked]="allSelected()"></md-checkbox>
                            </th>
                            <th td-data-table-column *ngFor="let column of columns" [active]="sortBy == column.name" [name]="column.name" [sortOrder]="sortOrder"
                                [sortable]="true" (sortChange)="sort($event)" [numeric]="false">
                                {{column.label}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr td-data-table-row *ngFor="let row of filteredData">
                            <td td-data-table-cell>
                                <md-checkbox flex color="primary" (change)="select($event.checked, row)" [checked]="rowSelected(row)"></md-checkbox>
                            </td>
                            <td td-data-table-cell>
                                <a class="tableLinks" *ngIf="row['supplier']" flex [routerLink]="['/purchasing', 'po', 'edit', row['id']]">{{row.po_number}}</a>
                                <a class="tableLinks" *ngIf="!row['supplier']" flex [routerLink]="['/purchasing', 'branchtransfer', 'edit', row['id']]">{{row.po_number}}</a>
                            </td>
                            <td td-data-table-cell>{{row.supplier?row.supplier:'(Branch Transfer)'}}</td>
                            <td td-data-table-cell>{{row.order_date?.substring(0, 10)}}</td>
                            <td td-data-table-cell>{{row.pieces}}</td>
                            <td td-data-table-cell>{{row.quantity | number:'1.2-2'}}</td>
                            <td td-data-table-cell>{{row.status}}</td>
                            <td td-data-table-cell>{{row.branch}}</td>
                        </tr>
                        <tr td-data-table-column-row class="total-row no-border default">
                            <td td-data-table-cell></td>
                            <td td-data-table-cell>Total (Page)</td>
                            <td td-data-table-cell></td>
                            <td td-data-table-cell></td>
                            <td td-data-table-cell>{{ total (filteredData, 'pieces') }}</td>
                            <td td-data-table-cell>{{ total (filteredData, 'quantity') | number:'1.2-2' }}</td>
                            <td td-data-table-cell></td>
                            <td td-data-table-cell></td>
                        </tr>
                        <tr td-data-table-column-row class="total-row no-border default">
                            <td td-data-table-cell></td>
                            <td td-data-table-cell>Total (All)</td>
                            <td td-data-table-cell></td>
                            <td td-data-table-cell></td>
                            <td td-data-table-cell>{{ total (data, 'pieces') }}</td>
                            <td td-data-table-cell>{{ total (data, 'quantity') | number:'1.2-2'}}</td>
                            <td td-data-table-cell></td>
                            <td td-data-table-cell></td>
                        </tr>
                    </tbody>
                </table>
                <div fxLayout='row' fxLayout.lt-md="column">
                    <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSize]="_settingsService.getPageSize()"
                        [pageSizes]="[10, 50, 100, 1000, 'All']" [total]="filteredTotal" (change)="page($event)">
                        <span td-paging-bar-label hide-xs>Row per page:</span>
                        <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
                        <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
                        <span *ngIf="pagingBar.pageSize == 'All'">All </span>
                        <span hide-xs>{{pagingBar.total}} items</span>
                    </td-paging-bar>
                </div>
            </md-card-content>
            <md-card-actions *ngIf="!_authService.isViewer()">
                <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                    <button *ngIf="_authService.hasRole('Purchasing') && _authService.isExecutive()" md-button color="primary" class="bg-blue color-white"
                        [mdMenuTriggerFor]="productMenu">New
                        <md-icon>arrow_drop_down</md-icon>
                    </button>
                    <md-menu #productMenu="mdMenu" yPosition="below" xPosition="after" [overlapTrigger]="false">
                        <md-nav-list>
                            <a *ngIf="_authService.isAdmin()" md-list-item routerLink="/purchasing/po/new">Purchase Order</a>
                            <a *ngIf="_authService.isExecutive()" md-list-item routerLink="/purchasing/branchtransfer/new">Branch to Branch</a>
                        </md-nav-list>
                    </md-menu>
                    <button md-button color="warn" class="bg-red color-white" (click)="openConfirm()">Delete</button>
                </div>
            </md-card-actions>
        </md-card>
    </div>
</div>