<div fxLayout="column">
    
    <div class="boxed-content-wrapper">
        <md-card>
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>{{ 'purchasing' | translate:_authService.getLanguage() }} / {{ 'products' | translate:_authService.getLanguage() }}</span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white">
                    <div layout="row">
                        <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="{{ 'search_here' | translate:_authService.getLanguage() }}" (searchDebounce)="search($event)"
                            flex>
                        </td-search-box>
                        <form class="push-right-sm" fxLayoutGap="16px">
                            <md-select placeholder="{{ 'fabric_type' | translate:_authService.getLanguage() }} " (change)="fabricFilter($event)" name="fabricType" class="fs18">
                                <md-option [value]="'all'">{{ 'all' | translate:_authService.getLanguage() }}</md-option>
                                <md-option *ngFor="let fabricTypeItem of allFabricTypes$ | async" [value]="fabricTypeItem.name">{{fabricTypeItem.name}}</md-option>
                            </md-select>
                            <md-select placeholder="{{ 'suppliers' | translate:_authService.getLanguage() }} " (change)="supplierFilter($event)" name="supplier">
                                <md-option [value]="'all'">{{ 'all' | translate:_authService.getLanguage() }}</md-option>
                                <md-option *ngFor="let supplierItem of allSuppliers$ | async" [value]="supplierItem.name">{{supplierItem.name}}</md-option>
                            </md-select>
                        </form>
                        <!-- If you wanted ALL data replace "filteredData" with "data" -->
                        <button md-icon-button (click)="_exportService.asCsv(filteredData, 'Products')">
                            <md-icon>cloud_download</md-icon>
                        </button>
                    </div>
                </md-card-subtitle>
            </md-card-header>

            <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
                <md-divider></md-divider>
                <td-data-table #dataTable [data]="filteredData" [columns]="columns" [selectable]="true" [multiple]="true" [sortable]="true"
                    [sortOrder]="'ASC'" (sortChange)="sort($event)" [(ngModel)]="selectedRows">
                    <ng-template tdDataTableTemplate="id" let-value="value" let-row="row" let-column="column">
                        <div layout="row">
                            <a class="tableLinks" href="#" flex [routerLink]="['edit/', value]">{{value}}</a>
                        </div>
                    </ng-template>
                    <ng-template tdDataTableTemplate="copy" let-value="value" let-row="row" let-column="column">
                        <div layout="row">
                            <a href="#" flex [routerLink]="['edit/', row['id']]" [queryParams]="{copy:true}">
                                <md-icon>content_copy</md-icon>
                            </a>
                        </div>
                    </ng-template>
                </td-data-table>


                <div fxLayout='row' fxLayout.lt-md="column">
                    <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSizes]="[10, 50, 100, 1000, 'All']"
                        [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal" (change)="page($event)">
                        <span td-paging-bar-label hide-xs>{{ 'rows_per_page' | translate:_authService.getLanguage() }}:</span>
                        <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
                        <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
                        <span *ngIf="pagingBar.pageSize == 'All'">{{ 'all' | translate:_authService.getLanguage() }} </span>
                        <span hide-xs>{{pagingBar.total}} {{ 'items' | translate:_authService.getLanguage() }}</span>
                    </td-paging-bar>
                </div>
            </md-card-content>
            <md-card-actions *ngIf="!_authService.isViewer()">
                <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                    <button md-button color="primary" class="bg-blue color-white" routerLink="/purchasing/products/new">{{ 'new' | translate:_authService.getLanguage() }}</button>
                    <button md-button color="warn" class="bg-red color-white" (click)="openConfirm()">{{ 'delete' | translate:_authService.getLanguage() }}</button>
                </div>
            </md-card-actions>
        </md-card>
    </div>
</div>