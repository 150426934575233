<div fxLayout="column">
    
    <div class="boxed-content-wrapper cards-no-gaps">
        <md-card>
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>
                        PDA /
                        <a [routerLink]="['/pda/receiving']">{{ 'receiving' | translate:_authService.getLanguage() }}</a> / {{ 'print_labels' | translate:_authService.getLanguage()
                        }}
                    </span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white text-only">{{ 'print_labels' | translate:_authService.getLanguage() }}</md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column">
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="{{ 'receiving_number' | translate:_authService.getLanguage() }}" [(ngModel)]="receivingNumber">
                    </md-input-container>
                </div>
            </md-card-content>

            <md-card-content *ngIf="grouped_items.length > 0">
                <div flex layout-margin>
                    <span>{{ printedStatusText() }}</span>
                    <md-progress-bar mode="determinate" [value]="printedStatusValue()"></md-progress-bar>
                </div>

                <md-toolbar color="primary">Pieces</md-toolbar>
                <div flex layout="column" *ngFor="let c of grouped_items" layout-padding>
                    <md-toolbar>{{c.design_color}}</md-toolbar>
                    <td-data-table #dataTable [data]="c.pieces" [columns]="columns" [sortOrder]="'DESC'">
                        <ng-template tdDataTableTemplate="printed" let-value="value" let-row="row" let-column="column">
                            <md-icon md-list-icon *ngIf="!row['printed']">radio_button_unchecked</md-icon>
                            <md-icon md-list-icon *ngIf="row['printed']" style="color: green; cursor: pointer">check_circle</md-icon>
                        </ng-template>
                        <ng-template tdDataTableTemplate="reprint" let-value="value" let-row="row" let-column="column">
                            <md-icon class="linkCursor" (click)="printOneRoll(row); row['printed']=true">print</md-icon>
                        </ng-template>
                    </td-data-table>
                    <div flex>
                        <button md-button md-raised-button color="primary" (click)="printGroup(c.pieces)">Print All</button>
                    </div>
                </div>
            </md-card-content>

            <md-card-actions>
                <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                    <!--button md-button color="primary" class="bg-blue color-white" (click)="print()">{{ 'print' | translate:_authService.getLanguage() }}</button-->
                    <button md-button color="primary" class="bg-blue color-white" (click)="load()">{{ 'load' | translate:_authService.getLanguage() }}</button>
                    <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
                </div>
            </md-card-actions>
        </md-card>
    </div>
</div>