<div fxLayout="column">
  
  <div class="boxed-content-wrapper">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>
            <a [routerLink]="['/inventory/list']">Inventory</a> /
            {{_branch_name}} / {{_collection_name }} / {{_design_name}} / {{_color_name}} / Pieces
          </span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white">
          <div layout="row">
            <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="Search Here"
              (searchDebounce)="search($event)" flex>
            </td-search-box>
          </div>
        </md-card-subtitle>
      </md-card-header>

      <md-card-content>
        <md-divider></md-divider>
        <div class="mat-table-container tmp-driven-table">
          <table td-data-table class="duplicated-thead-fix">
            <thead>
              <tr td-data-table-column-row>
                <th td-data-table-column *ngFor="let column of columns" [active]="sortBy == column.name"
                  [name]="column.name" [sortOrder]="sortOrder" [sortable]="true" (sortChange)="sort($event)"
                  [numeric]="false">
                  {{column.label}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr td-data-table-row *ngFor="let row of filteredData">
                <td td-data-table-cell *ngFor="let column of columns" [numeric]="column.numeric">

                  <div [ngSwitch]="column.name">

                    <span *ngSwitchCase="'piece_number'">
                      <div layout="row">
                        <a class="tableLinks" href="#" [routerLink]="['/inventory/list/piece/', row.id]">
                          <md-icon class="color-white md-18" style="display: inline-flex;
                          vertical-align: middle;" *ngIf="row['defect']" title="{{row['defect_comment']}}">warning</md-icon><md-icon class="color-white md-18" style="display: inline-flex;
                          vertical-align: middle;" *ngIf="row['reserved']" title="{{row['reserved_comment']}}">bookmark</md-icon> {{row.piece_number}}
                        </a>
                      </div>
                    </span>
                    <span *ngSwitchCase="'length'">
                      {{ row.length  | number:'1.2-2' }}
                    </span>
                    <span *ngSwitchCase="'weight'">
                      {{ row.weight  | number:'1.2-2' }}
                    </span>
                    <span *ngSwitchDefault>
                      {{row[column.name]}}
                    </span>
                  </div>

                  <!--
                                    <span *ngIf="column.name === 'piece_number'; else defaultTd">
                                        <div layout="row">
                                            <a class="tableLinks" href="#" [routerLink]="['/inventory/list/piece/', row.id]">{{row.piece_number}}</a>
                                        </div>
                                    </span>
                                    <ng-template #defaultTd>
                                        <span>
                                        {{row[column.name]}}
                                        </span>
                                    </ng-template>
-->
                </td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border default">
                <td td-data-table-cell>Total (All)</td>
                <td td-data-table-cell>{{totalPiecesCount}} PCS</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{totalLengthValue | number:'1.2-2'}}</td>
                <td td-data-table-cell>{{totalWeightValue | number:'1.2-2'}}</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar
            [pageSizes]="[10, 50, 100, 1000, 'All']" [total]="filteredTotal" (change)="page($event)">
            <span td-paging-bar-label hide-xs>Row per page:</span> {{pagingBar.range}} <span hide-xs>of
              {{pagingBar.total}}</span>
          </td-paging-bar>
        </div>
      </md-card-content>
    </md-card>
  </div>
</div>
