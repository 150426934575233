<div flex layout="column" layout-wrap layout-margin>
  <div flex>
    <h4>Customer Credit Details: {{details.customer_name}}</h4>
  </div>
  <div flex>
    <p>Invoices outstanding: {{details.invoices_outstanding || 0 | number:'1.2-2'}}</p>
    <p>Orders outstanding: {{details.orders_outstanding || 0 | number:'1.2-2'}}</p>
    <p>&nbsp;</p>
    <p>Credit limit: {{getCurrentCreditLimit() | number:'1.2-2'}}</p>
    <p>&nbsp;</p>
    <p [style.color]="getRemainingCredit() > 0?'green':'red'">
      <b>Remaining Credit: {{getRemainingCredit() | number:'1.2-2'}}</b>
    </p>
  </div>
</div>