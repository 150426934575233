<div fxLayout="row" fxLayout.lt-md="column">

  <md-card fxFlex=75>
    <md-card-header fxLayout="column">
      <md-card-title>
        <span fxFlex=100 class="md-title">{{ 'home' | translate:_authService.getLanguage() }}
          {{_prod ? '' : ' (Development)'}}</span>
      </md-card-title>
      <md-card-subtitle class="bg-grey color-white text-only">
        {{ 'welcome' | translate:_authService.getLanguage() }}: {{_authService.getUsername()}}
        ({{_authService.getFirstName()}} {{_authService.getLastName()}})
      </md-card-subtitle>
    </md-card-header>

    <md-card-content>
      <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">

        <div flex fxLayout="column">
          <h3 md-subheader>{{ 'branch_access' | translate:_authService.getLanguage() }}</h3>
          <md-list fxLayout="column">
            <md-list-item *ngFor="let branch of _authService.getUserBranches()">{{branch.code}} - {{branch.name}}
            </md-list-item>
          </md-list>
        </div>

        <div flex fxLayout="column">
          <h3 md-subheader>{{ 'rights' | translate:_authService.getLanguage() }}</h3>
          <md-list fxLayout="column">
            <md-list-item>{{ _authService.getRightLevel() }}</md-list-item>
          </md-list>
        </div>

        <div flex fxLayout="column">
          <h3 md-subheader>{{ 'module_roles' | translate:_authService.getLanguage() }}</h3>
          <md-list fxLayout="column">
            <md-list-item *ngFor="let role of _authService.getUserRoles()">{{role.name}}</md-list-item>
          </md-list>
        </div>

        <!--div fxFlex="22%" class="padded-container" fxLayout="column" fxLayoutGap="14px">
        <md-select placeholder="{{ 'printer' | translate:_authService.getLanguage() }}" [(ngModel)]="printer_id">
          <md-option *ngFor="let printer of printers$ | async" [value]="printer.id">{{printer.name}}
          </md-option>
        </md-select>
        <button md-button md-raised-button color="primary"
          (click)="setPrinter()">{{ 'set_printer' | translate:_authService.getLanguage() }}</button>
        <md-divider></md-divider>
        <button md-button md-raised-button color="primary"
          (click)="updatePassword()">{{ 'update_password' | translate:_authService.getLanguage() }}</button>
        <md-divider></md-divider>
        <md-select placeholder="{{ 'report_csv_separator' | translate:_authService.getLanguage() }}"
          [(ngModel)]="csv_separator">
          <md-option [value]="'semicolon'">{{ 'semicolon_europe' | translate:_authService.getLanguage() }} (;)
          </md-option>
          <md-option [value]="'comma'">{{ 'comma_north_america' | translate:_authService.getLanguage() }} (,)
          </md-option>
        </md-select>
        <button md-button md-raised-button color="primary"
          (click)="setCsvSeparator()">{{ 'set_csv_separator' | translate:_authService.getLanguage() }}</button>
        <b>Version: {{version}}</b>
      </div -->
      </div>

    </md-card-content>
  </md-card>

  <md-card fxFlex=25>
    <md-card-header fxLayout="column">
      <md-card-title>
        <span class="md-title" fxFlex=100>{{ 'options' | translate:_authService.getLanguage() }}</span>
      </md-card-title>
      <md-card-subtitle class="bg-grey color-white text-only"><b>Version: {{version}}</b></md-card-subtitle>
    </md-card-header>

    <md-card-content>
      <div fxLayout="column">

        <div fxLayout="column" layout-margin>
          <md-select placeholder="{{ 'printer' | translate:_authService.getLanguage() }}" [(ngModel)]="printer_id">
            <md-option *ngFor="let printer of printers$ | async" [value]="printer.id">{{printer.name}}
            </md-option>
          </md-select>
          <button md-button md-raised-button color="primary"
            (click)="setPrinter()">{{ 'set_printer' | translate:_authService.getLanguage() }}</button>
        </div>

        <md-divider style="margin-top: 20px; margin-bottom: 20px"></md-divider>

        <div fxLayout="column" layout-margin>
          <md-select placeholder="{{ 'report_csv_separator' | translate:_authService.getLanguage() }}"
            [(ngModel)]="csv_separator">
            <md-option [value]="'semicolon'">{{ 'semicolon_europe' | translate:_authService.getLanguage() }} (;)
            </md-option>
            <md-option [value]="'comma'">{{ 'comma_north_america' | translate:_authService.getLanguage() }} (,)
            </md-option>
          </md-select>
          <button md-button md-raised-button color="primary" class="text-upper"
            (click)="setCsvSeparator()">{{ 'set_csv_separator' | translate:_authService.getLanguage() }}</button>
        </div>

        <md-divider style="margin-top: 20px; margin-bottom: 20px"></md-divider>

        <div fxLayout="column" layout-margin>
          <button md-button md-raised-button color="primary"
            (click)="updatePassword()">{{ 'update_password' | translate:_authService.getLanguage() }}</button>
        </div>

      </div>

    </md-card-content>

  </md-card>

</div>
