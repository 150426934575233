<div fxLayout="column">
  <div class="boxed-content-wrapper">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>Accounting / Invoices</span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white">
          <div layout="row">
            <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="Search Here"
              (searchDebounce)="search($event)" flex>
            </td-search-box>
            <form class="push-right-sm" fxLayoutGap="16px">
              <md-select placeholder="Branch" [(ngModel)]="branchFilter" (ngModelChange)="filter()" name="branch">
                <md-option [value]="'ALL'">All Branches</md-option>
                <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.code">{{branchItem.code}}
                  - {{branchItem.name}}</md-option>
              </md-select>
            </form>

            <!-- If you wanted ALL data replace "filteredData" with "data" -->
            <button md-icon-button [mdMenuTriggerFor]="exportMenu">
              <md-icon>cloud_download</md-icon>
            </button>

            <md-menu #exportMenu="mdMenu">
              <button md-menu-item (click)="_exportService.asCsv(filteredData, 'Invoices')">
                <md-icon>cloud_download</md-icon>Export this list
              </button>
              <button md-menu-item (click)="customerSummaryReport()">
                <md-icon>assignment_returned</md-icon>Customer Summary Report
              </button>
              <button md-menu-item (click)="customerAccountReport()">
                <md-icon>assignment_returned</md-icon>Customer Account Report
              </button>
            </md-menu>
          </div>
        </md-card-subtitle>
      </md-card-header>

      <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
        <div class="mat-table-container tmp-driven-table">

          <table td-data-table class="duplicated-thead-fix">
            <thead>
              <tr td-data-table-column-row>
                <th td-data-table-cell></th>

                <th td-data-table-column *ngFor="let column of columns" [active]="sortBy == column.name" [name]="column.name"
                  [sortOrder]="sortOrder" [sortable]="true" (sortChange)="sort($event)" [numeric]="false">
                  {{column.label}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr td-data-table-row *ngFor="let row of filteredData">
                <td td-data-table-cell>
                  <md-icon *ngIf="row['type']=='Invoice'" class="linkCursor" (click)="editInvoiceNumber(row)" title="Edit Invoice Number">edit</md-icon>
                </td>

                <td td-data-table-cell *ngFor="let column of columns" [numeric]="column.numeric">
                  <div [ngSwitch]="column.name">
                    <span *ngSwitchCase="'id'">
                      <div layout="row" *ngIf="row['invoice_number']">
                        <a class="tableLinks" href="#" flex [routerLink]="['settle', row['id']]">{{row['invoice_number']}}</a>
                      </div>
                    </span>
                    <span *ngSwitchCase="'invoice_date'"> {{row['invoice_date']?.substring(0, 10)}} </span>
                    <span *ngSwitchCase="'customer'"> {{row['customer_name']}} </span>
                    <span *ngSwitchCase="'branch'"> {{row['branch_code']}} - {{row['branch_name']}} </span>
                    <span *ngSwitchCase="'balance'"> {{row['total'] - row['processed'] | number:'1.2-2'}} </span>
                    <span *ngSwitchCase="'total'"> {{row['total'] | number:'1.2-2'}} </span>
                    <!--<span *ngSwitchCase="'credit'"> {{row['credit'] | number:'1.2-2'}} </span>
                    <span *ngSwitchCase="'debit'"> {{row['debit'] | number:'1.2-2'}} </span>
                    <span *ngSwitchCase="'credit'"> {{(row['total'] < 0 ? row['total'] * -1 : 0) | number:'1.2-2'}} </span>
                    <span *ngSwitchCase="'debit'"> {{(row['total'] > 0 ? row['total'] : 0) | number:'1.2-2'}} </span>-->
                     

                    <span *ngSwitchCase="'processed'" class="linkCursor" (click)="viewProcessed(row)"> {{row['processed'] | number:'1.2-2'}} <md-icon class="md-18">info</md-icon></span>
                    <span *ngSwitchCase="'cheques'"> {{row['cheques'] | number:'1.2-2'}} </span>
                    <!--ng-template #defaultTd-->
                    <span *ngSwitchDefault> {{row[column.name]}} </span>
                    <!--/ng-template-->
                  </div>
                </td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border default">
                <td td-data-table-cell></td>
                <td td-data-table-cell>Total (Page)</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{getTotal(filteredData, 'total') | number:'1.2-2'}}</td>
                <!--<td td-data-table-cell>{{getTotal(filteredData, 'debit') | number:'1.2-2'}}</td>
                <td td-data-table-cell>{{getTotal(filteredData, 'credit') | number:'1.2-2'}}</td>-->
                <td td-data-table-cell>{{getTotal(filteredData, 'processed') | number:'1.2-2'}}</td>
                <td td-data-table-cell>{{getTotal(filteredData, 'total') - getTotal(filteredData, 'processed')|
                  number:'1.2-2'}}</td>
                <td td-data-table-cell>{{getTotal(filteredData, 'cheques') | number:'1.2-2'}}</td>
                <td td-data-table-cell></td>
              </tr>
              <tr td-data-table-column-row class="total-row no-border default">
                <td td-data-table-cell></td>
                <td td-data-table-cell>Total (All)</td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell></td>
                <td td-data-table-cell>{{getTotal(data, 'total') | number:'1.2-2'}}</td>
                <td td-data-table-cell>{{getTotal(data, 'processed') | number:'1.2-2'}}</td>
                <td td-data-table-cell>{{getTotal(data, 'total') - getTotal(data, 'processed')| number:'1.2-2'}}</td>
                <td td-data-table-cell>{{getTotal(data, 'cheques') | number:'1.2-2'}}</td>
                <td td-data-table-cell></td>
              </tr>
            </tbody>
          </table>

        </div>
        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSizes]="[10, 50, 100, 1000, 'All']"
            [pageSize]="_settingsService.getPageSize()" [total]="filteredTotal" (change)="page($event)">
            <span td-paging-bar-label hide-xs>Row per page:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">All </span>
            <span hide-xs>{{pagingBar.total}} items</span>
          </td-paging-bar>
        </div>

      </md-card-content>
      <md-card-actions *ngIf="!_authService.isViewer()">
        <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
          <button md-button color="primary" class="bg-blue color-white" [mdMenuTriggerFor]="invoiceMenu">New
            <md-icon>arrow_drop_down</md-icon>
          </button>
        </div>
        <md-menu #invoiceMenu="mdMenu" yPosition="below" xPosition="after" [overlapTrigger]="false">
          <md-nav-list>
            <!-- <a md-list-item (click)="newInvoice()">Invoice</a> -->
            <a md-list-item [routerLink]="['settle', 'new']">Payment</a>
            <a md-list-item (click)="newAdvance()">Advance</a>
            <!-- <a md-list-item (click)="newCredit()">Credit</a> -->
          </md-nav-list>
        </md-menu>
      </md-card-actions>
    </md-card>
  </div>
</div>
