<div flex layout="column" layout-wrap layout-margin>
  <div flex>
    <h4>Cut Item</h4>
  </div>
  <div flex>
    <md-input-container>
      <input mdInput readonly placeholder="Piece Number" [(ngModel)]="pieceNumber">
    </md-input-container>
  </div>
  <div flex>
    <md-input-container>
      <input mdInput readonly placeholder="Piece Length" [(ngModel)]="fullLength">
    </md-input-container>
  </div>
  <div flex>
    <md-input-container>
      <input mdInput readonly placeholder="New Piece Length" [(ngModel)]="newLength">
    </md-input-container>
  </div>
  <div flex>
    <md-input-container>
      <input mdInput placeholder="New Piece Number" [(ngModel)]="newPieceNumber">
    </md-input-container>
  </div>
  <div fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
    <button md-button color="primary" class="bg-blue color-white" (click)="cutItem()">Cut</button>
    <button md-button color="warn" class="bg-red color-white" (click)="close()">Back</button>
  </div>
</div>