<div fxLayout="column">
  <h4>{{instruction}}</h4>
  <md-input-container fxFlex="22%">
    <input mdInput type="date" placeholder="Date" [(ngModel)]="date">
  </md-input-container>


    <div class="table-control-buttons" fxLayout="row" fxFlex fxFlexAlign="start center" fxLayoutGap="12px" fxFlexAlign.lt-md="center">
      <button md-button color="primary" class="bg-blue color-white" (click)="close({date:date})">Accept</button>
      <div flex></div>
      <button md-button color="warn" class="bg-red color-white" (click)="close(null)">Cancel</button>
    </div>
</div>