<div fxLayout="column" style="min-width: 400px;">
  <h4>Select Customer</h4>

  <md-input-container flex layout-margin>
    <input mdInput flex placeholder="Prefix - Customer" [mdAutocomplete]="autoCustomer" [(ngModel)]="cust"
      (ngModelChange)="filter(cust);">
    <md-autocomplete #autoCustomer="mdAutocomplete">
      <md-option *ngFor="let customer of matchingCustomers" [value]="customer?.prefix + ' - ' + customer?.name"
        (click)="setSelected(customer)">
        {{customer?.prefix + ' - ' + customer?.name}}
      </md-option>
    </md-autocomplete>
  </md-input-container>


  <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxLayoutGap="12px" fxFlexAlign.lt-md="center">
    <button md-button color="primary" class="bg-blue color-white" (click)="close(selectedCustomer)">Accept</button>
    <button md-button color="warn" class="bg-red color-white" (click)="close(null)">Cancel</button>
  </div>

</div>
