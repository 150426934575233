<div fxLayout="column">
    

    <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
        <md-icon>warning</md-icon>
        <span>You have unsaved changes</span>
    </div>

    <div class="boxed-content-wrapper cards-no-gaps">
        <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
            <td-steps mode="horizontal" *ngIf="!isCancelled()">
                <td-step label="DRAFT" sublabel="To be completed" [active]="true" [state]="isDraft()?'complete':'none'" disabled="true">NEXT: Move to ORDERED status</td-step>
                <td-step label="ORDERED" sublabel="Submit Order to supplier" [state]="isOrdered()?'complete':'none'" [active]="true" disabled="true">NEXT: Scan pieces in PDA module</td-step>
                <td-step label="PACKING LIST" sublabel="Upload packing list in Receiving" [state]="isPackingList()?'complete':'none'" [active]="true"
                    disabled="true">The order is part of inventory</td-step>
                <td-step label="RECEIVED" sublabel="Scan pieces in PDA module" [state]="isReceived()?'complete':'none'" [active]="true" disabled="true">The order is part of inventory</td-step>
            </td-steps>
            <td-steps mode="horizontal" *ngIf="isCancelled()">
                <td-step label="DRAFT" sublabel="To be completed" [active]="true" state="complete" disabled="true"></td-step>
                <td-step label="CANCELLED" sublabel="The order is cancelled" state="complete" [active]="true" disabled="true"></td-step>
            </td-steps>
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex="100">{{ 'purchasing' | translate:_authService.getLanguage() }} /
                        <a [routerLink]="['/purchasing/po']">{{ 'po_list' | translate:_authService.getLanguage() }}</a> / {{ (isEditMode && currentPo) ? currentPo.po_number
                        : title }}
                    </span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white text-only">{{ 'po_details' | translate:_authService.getLanguage() }}</md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column" *ngIf="currentPo">
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-select fxFlex="22%" placeholder="{{ 'branch' | translate:_authService.getLanguage() }}" name="branch" [(ngModel)]="currentPo.requesting_branch_id"
                        (ngModelChange)="branchChanged($event); loadSuppliers($event)">
                        <md-option *ngFor="let branch of allBranches$ | async" [value]="branch.id">{{ branch.code}} - {{branch.name}}</md-option>
                    </md-select>

                    <md-select fxFlex="22%" placeholder="{{ 'supplier' | translate:_authService.getLanguage() }} / {{ 'prefix' | translate:_authService.getLanguage() }}"
                        name="supplierPrefix" [(ngModel)]="this.currentPo.supplier_id" (ngModelChange)="changedSupplier($event)">
                        <md-option *ngFor="let supplierPrefix of allSupplierPrefixes$ | async" [value]="supplierPrefix.id">{{supplierPrefix.code}}/{{supplierPrefix.name}}</md-option>
                    </md-select>

                    <md-input-container fxFlex="22%">
                        <input mdInput type="date" placeholder="{{ 'order_date' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.order_date">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput readonly placeholder="{{ 'supplier_order_ref' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.supplier_order_ref">
                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput type="date" placeholder="{{ 'estimate_arrival_date' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.estimated_arrival_date">
                    </md-input-container>

                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="{{ 'ship_to' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.ship_to">
                    </md-input-container>

                    <md-select fxFlex="22%" placeholder="{{ 'status' | translate:_authService.getLanguage() }}" name="status" [(ngModel)]="currentPo.status">
                        <md-option *ngFor="let status of allStatuses" [value]="status">{{status}}</md-option>
                    </md-select>
                </div>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-header fxLayout="column">
                <md-card-subtitle class="bg-grey color-white text-only">{{ 'invoice_and_payment_details' | translate:_authService.getLanguage() }}
                </md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column" *ngIf="currentPo">
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'proforma_invoice_number' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.proforma_invoice_number">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput type="date" placeholder="{{ 'proforma_invoice_date' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.proforma_invoice_date">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'downpayment_amount' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.downpayment_amount">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput type="date" placeholder="{{ 'downpayment_date' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.downpayment_date">
                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'additional_details' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.additional_details">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'additional_cost' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.additional_cost">
                    </md-input-container>

                    <!--div fxFlex="22%"></div-->
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'currency' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.currency"
                            [mdAutocomplete]="currencyAuto" (ngModelChange)="filterCurrencies($event)" (blur)="setCurrency()">
                        <md-autocomplete #currencyAuto="mdAutocomplete">
                            <md-option *ngFor="let c of _currencies" [value]="c.code">{{c.code}} ({{c.description}})</md-option>
                        </md-autocomplete>
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <!-- total + additional cost - credit - downpayment - balance paid * Change request
                        <input mdInput readonly placeholder="{{ 'balance_due_calculated' | translate:_authService.getLanguage() }}" [value]="(currentPo.total_amount || 0)*1 + (currentPo.additional_cost || 0)*1 - (currentPo.credit_amount || 0) - (currentPo.downpayment_amount || 0) - (currentPo.balance_paid_amount || 0)"> -->

                        <!-- total + additional cost - credit - downpayment 
                        <input mdInput readonly placeholder="{{ 'balance_due_calculated' | translate:_authService.getLanguage() }}" [value]="(currentPo.total_amount || 0)*1 + (currentPo.additional_cost || 0)*1 - (currentPo.credit_amount || 0) - (currentPo.balance_paid_amount || 0)"> -->

                        <!-- Balance Due Calculated = Final Amount - Downpayment - Balance Paid Amount -->
                        <input mdInput readonly placeholder="{{ 'balance_due_calculated' | translate:_authService.getLanguage() }}" [value]="(currentPo.total_amount || 0)*1 - (currentPo.balance_paid_amount || 0) - (currentPo.downpayment_amount || 0)">

                    </md-input-container>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'credit_details' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.credit_details">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'credit_amount' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.credit_amount">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'balance_paid_amount' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.balance_paid_amount">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput type="date" placeholder="{{ 'payment_date' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.payment_date">
                    </md-input-container>
                </div>


                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'final_invoice_number' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.final_invoice_number">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput type="date" placeholder="{{ 'final_invoice_date' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.final_invoice_date">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'final_qty_produced' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.total_qty_produced">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'final_invoice_amount' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.total_amount">
                    </md-input-container>
                </div>

                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <textarea mdInput placeholder="{{ 'notes' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.notes">{{currentPo.notes}}</textarea>
                    </md-input-container>
                </div>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-header fxLayout="column">
                <md-card-subtitle class="bg-grey color-white text-only">{{ 'shipping_details' | translate:_authService.getLanguage() }}</md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column" *ngIf="currentPo">
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'fwd_agent_detail' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.forward_agent_detail">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput type="date" placeholder="{{ 'closing_date' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.closing_date">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput type="date" placeholder="{{ 'sailing_date' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.sailing_date">
                    </md-input-container>

                    <md-select fxFlex="22%" placeholder="{{ 'transport_way' | translate:_authService.getLanguage() }}" name="Transport Way" [(ngModel)]="currentPo.transport_way">
                        <md-option *ngFor="let transportWay of allTransportWays$ | async" [value]="transportWay">{{transportWay}}</md-option>
                    </md-select>
                </div>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="{{ 'place_of_dispatch' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.place_of_dispatch">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput type="date" placeholder="{{ 'actual_arrival_date' | translate:_authService.getLanguage() }}" [(ngModel)]="currentPo.actual_arrival_date">
                    </md-input-container>

                </div>
            </md-card-content>
        </md-card>
        <md-card>
            <md-card-header fxLayout="column">
                <md-card-subtitle class="bg-grey color-white text-only">{{ 'po_items' | translate:_authService.getLanguage() }}</md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column" *ngIf="currentPo">
                <div *ngIf="!isReceived() && !_authService.isViewer()" class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column"
                    fxLayoutGap.lt-md="14px">
                    <!-- Load colors directly when selecting branch -->
                    <!--md-select fxFlex="22%" placeholder="{{ 'collections' | translate:_authService.getLanguage() }}" name="collections" [(ngModel)]="collection" (ngModelChange)="loadColors($event)">
                        <md-option *ngFor="let collection of allCollections$ | async" [value]="collection">{{collection.name}}</md-option>
                    </md-select-->
                    <md-input-container fxFlex="22%">
                        <input mdInput readonly placeholder="{{ 'collections' | translate:_authService.getLanguage() }} " [ngModel]="collection">
                    </md-input-container>

                    <md-select fxFlex="22%" placeholder="{{ 'design' | translate:_authService.getLanguage() }} / {{ 'color' | translate:_authService.getLanguage() }}"
                        name="designColor" [(ngModel)]="design" (ngModelChange)="collection = $event.collection_name;unit_type = $event.unit_type">
                        <md-option *ngFor="let designColor of allDesignColors$ | async" [value]="designColor">{{designColor.design_name}}/{{designColor.color_name}}</md-option>
                    </md-select>

                    <md-input-container fxFlex="15%">
                        <input mdInput placeholder="{{ getUnit(unit_type) }} " [(ngModel)]="length">
                    </md-input-container>

                    <md-input-container fxFlex="15%">
                        <input mdInput placeholder="{{ 'unit_price' | translate:_authService.getLanguage() }}" [(ngModel)]="unitPrice">
                    </md-input-container>


                    <div>
                        <button md-button color="primary" class="bg-blue color-white" (click)="addItemToList()">ADD</button>
                    </div>
                </div>

                <!--app-po-orders-list></app-po-orders-list-->
                <div class="mat-table-container tmp-driven-table" layout="column">
                    <table td-data-table class="duplicated-thead-fix">
                        <thead>
                            <tr td-data-table-column-row>
                                <th td-data-table-column *ngFor="let column of columns" [numeric]="column.numeric">
                                    {{column.label}}
                                </th>
                                <th td-data-table-column>{{ 'total' | translate:_authService.getLanguage() }}</th>
                                <th td-data-table-column></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr td-data-table-row *ngFor="let row of filteredData">
                                <!-- <td td-data-table-cell>{{ row['supplier_collection_name'] }} {{row['supplier_collection_name']}}</td>
                                <td td-data-table-cell>{{ row['supplier_design_name']}}/{{row['color_name'] }}</td>
                                <td td-data-table-cell>{{ row['collection_name'] }}</td>
                                <td td-data-table-cell>{{ row['design_name']}}/{{row['color_name'] }}</td> -->
                                <td td-data-table-cell>{{ row['collection_name'] }}</td>
                                <td td-data-table-cell>{{ row['design_name']}}/{{row['color_name'] }}</td>
                                <td td-data-table-cell>{{ row['mystic_collection_name'] }}</td>
                                <td td-data-table-cell>{{ row['mystic_design_name']}}/{{row['mystic_color_name'] }}</td>
                                <td td-data-table-cell style="cursor:pointer" (click)="enterNewValueDialog(row, 'quantity')">
                                    <span>{{ row['quantity']  | number:'1.2-2'}} {{getUnitSymbol(row.unit_type)}}</span>
                                </td>
                                <!--td td-data-table-cell>{{ row['quantity'] }}</td-->
                                <td td-data-table-cell>{{ row['unit_price'] | currency:_this_PO_currency:true}}</td>
                                <td td-data-table-cell>{{ row['quantity'] * row['unit_price'] | currency:_this_PO_currency:true}}</td>
                                <td td-data-table-cell>
                                    <a (click)="removeItem(row)" style="cursor:pointer" *ngIf="!isReceived() && !_authService.isViewer()">
                                        <md-icon class="rotate45">add_circle</md-icon>
                                    </a>
                                </td>
                            </tr>
                            <tr td-data-table-column-row class="total-row no-border default">
                                <td td-data-table-cell>{{ 'total' | translate:_authService.getLanguage() }}</td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell>{{ getTotalMeters()  | number:'1.2-2'}}</td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell>{{ getTotalValue() | currency:_this_PO_currency:true}}</td>
                                <td td-data-table-cell></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div fxLayout='row' fxLayout.lt-md="column">
                    <!--td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSizes]="[5, 10, 15, 20]"
                        [total]="filteredTotal" (change)="page($event)">
                        <span td-paging-bar-label hide-xs>{{ 'rows_per_page' | translate:_authService.getLanguage() }}:</span> {{pagingBar.range}}
                        <span hide-xs>of {{pagingBar.total}}</span>
                    </td-paging-bar-->

                    <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSize]="_settingsService.getPageSize()"
                        [pageSizes]="[10, 50, 100, 1000, 'All']" [total]="filteredTotal" (change)="page($event)">
                        <span td-paging-bar-label hide-xs>Row per page:</span>
                        <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
                        <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
                        <span *ngIf="pagingBar.pageSize == 'All'">All </span>
                        <span hide-xs>{{pagingBar.total}} items</span>
                    </td-paging-bar>
                </div>

                <md-card-actions>
                    <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxLayoutGap="12px" fxFlexAlign.lt-md="center">
                        <button md-button [disabled]="saving" *ngIf="!isEditMode && !_authService.isViewer()" color="primary" class="bg-blue color-white"
                            (click)="createPo()">{{ 'create' | translate:_authService.getLanguage() }}</button>
                        <button md-button [disabled]="saving" *ngIf="isEditMode && !_authService.isViewer()" color="primary" class="bg-blue color-white"
                            (click)="savePo()">{{ 'save' | translate:_authService.getLanguage() }}</button>
                        <button md-button color="primary" class="bg-blue color-white" (click)="printPO()">{{ 'print_po' | translate:_authService.getLanguage() }}</button>
                        <button md-button color="warn" class="bg-red color-white" (click)="goBack()">{{ 'back' | translate:_authService.getLanguage() }}</button>
                    </div>
                </md-card-actions>
            </md-card-content>
        </md-card>
    </div>
</div>