<div fxLayout="column">
  
  <div class="boxed-content-wrapper">
    <md-card>
      <md-card-header fxLayout="column">
        <md-card-title>
          <span class="md-title" fxFlex=100>Inventory</span>
        </md-card-title>
        <md-card-subtitle class="bg-grey color-white">
          <div layout="row">
            <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="Search Here"
              (searchDebounce)="search($event)" flex>
            </td-search-box>
            <form class="push-right-sm" fxLayoutGap="16px">
              <md-select placeholder="Fabric type" [(ngModel)]="_selectedFabricTypeId"
                (ngModelChange)="onSelect(_selectedFabricTypeId); getPieces(_selectedFabricTypeId, _selectedBranch)"
                name="fabricType" class="fs18">
                <md-option *ngFor="let fabricTypeItem of allFabricTypes$ | async" [value]="fabricTypeItem.id">
                  {{fabricTypeItem.name}}</md-option>
              </md-select>
              <md-select placeholder="Unit" [(ngModel)]="_selectedUnit" (ngModelChange)="onUnitChange(_selectedUnit)"
                name="unit" class="fs18">
                <md-option *ngFor="let unit of availableUnits" [value]="unit">{{unit}}</md-option>
              </md-select>
              <md-select placeholder="Branch" [(ngModel)]="_selectedBranch"
                (ngModelChange)="getPieces(_selectedFabricTypeId, _selectedBranch)" name="branch">
                <md-option [value]="0">ALL</md-option>
                <md-option *ngFor="let branchItem of allBranches$ | async" [value]="branchItem.id">{{branchItem.code}} -
                  {{branchItem.name}}</md-option>
              </md-select>
            </form>

            <!-- If you wanted ALL data replace "filteredData" with "data" -->
            <button md-icon-button [mdMenuTriggerFor]="exportMenu">
              <md-icon>cloud_download</md-icon>
            </button>

            <md-menu #exportMenu="mdMenu">
              <button md-menu-item (click)="_exportService.asCsv(filteredData, 'Inventory')">
                <md-icon>cloud_download</md-icon>Export this list
              </button>
              <button md-menu-item (click)="stockReport()">
                <md-icon>assignment_returned</md-icon>Stock Report
              </button>
              <button md-menu-item (click)="stockCardReportPrompt()">
                <md-icon>assignment_returned</md-icon>Stock Card Report
              </button>
              <button md-menu-item (click)="detailedStockReport()">
                <md-icon>assignment_returned</md-icon>Detailed Stock Report
              </button>
              <button md-menu-item (click)="openStockHistoryDialog()">
                <md-icon>assignment_returned</md-icon>Stock History Report
              </button>
            </md-menu>
          </div>
        </md-card-subtitle>
      </md-card-header>


      <!-- 
    { name: 'collection_name', label: 'Collection' },
    { name: 'design_color', label: 'Design / Color' },
    { name: 'branch', label: 'Branch' },
    { name: 'pieces', label: 'Pieces' },
    { name: 'grade_code', label: 'Grade' },
    { name: 'available', label: 'Available (M)' },
    { name: 'available_y', label: 'Available (y)' },
    { name: 'available_Msq', label: 'Available (M²)' },
    { name: 'available_Sft', label: 'Available (SqFt)' },
    { name: 'reserved', label: 'Reserved (M)' },
    { name: 'reserved_y', label: 'Reserved (y)' },
    { name: 'reserved_Msq', label: 'Reserved (M²)' },
    { name: 'reserved_Sft', label: 'Reserved (SqFt)' },
    { name: 'reserved_N', label: 'Reserved' },
    { name: 'missing', label: 'Back Order (M)' },
    { name: 'missing_y', label: 'Back Order (y)' },
    { name: 'missing_Msq', label: 'Back Order (M²)' },
    { name: 'missing_Sft', label: 'Back Order (SqFt)' },
    { name: 'ordered', label: 'Ordered (M)' },
    { name: 'ordered_y', label: 'Ordered (y)' },
    { name: 'ordered_Msq', label: 'Ordered (M²)' },
    { name: 'ordered_Sft', label: 'Ordered (SqFt)' },
    { name: 'ordered_N', label: 'Ordered' } 
-->


      <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">

        <td-data-table #dataTable [data]="filteredData" [columns]="allColumns" [selectable]="false" [multiple]="false"
          [sortable]="true" [sortOrder]="'ASC'" (sortChange)="sort($event)" [(ngModel)]="selectedRows"
          class="compressed-table">

          <ng-template tdDataTableTemplate="pieces" let-value="value" let-row="row" let-column="column">
            <span style="min-width: 50px;">
              <a class="tableLinks" *ngIf="row['pieces'] > 0 && row['total_row'] != true" href="#"
                [routerLink]="['/inventory/list/pieces/', row.branch_id, row.color_id]">
                <md-icon class="color-white md-18" style="display: inline-flex;
                  vertical-align: middle;" *ngIf="row['defect']"
                  title="{{row['defect_comment']}}">warning</md-icon> {{row.pieces}}
              </a>
              <span *ngIf="row['pieces'] == 0 || row['total_row'] == true">{{row.pieces}}</span>
            </span>
          </ng-template>

          <ng-template tdDataTableTemplate="available" let-value="value" let-row="row" let-column="column">
            <span>{{row.available || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="available_y" let-value="value" let-row="row" let-column="column">
            <span>{{row.available * 1.09361 || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="available_Msq" let-value="value" let-row="row" let-column="column">
            <span>{{row.available || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="available_Sft" let-value="value" let-row="row" let-column="column">
            <span>{{row.available * 10.7639 || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="reserved" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer" (click)="openReservedDialog(row)">{{row.reserved || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="reserved_y" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer" (click)="openReservedDialog(row)">{{row.reserved * 1.09361 || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="reserved_Msq" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer" (click)="openReservedDialog(row)">{{row.reserved || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="reserved_Sft" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer" (click)="openReservedDialog(row)">{{row.reserved * 10.7639 || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="reserved_N" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer" (click)="openReservedDialog(row)">{{row.reserved || 0}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="missing" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer" (click)="openMissingDialog(row)">{{+row.missing || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="missing_y" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer" (click)="openMissingDialog(row)">{{row.missing * 1.09361 || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="missing_Msq" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer" (click)="openMissingDialog(row)">{{row.missing || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="missing_Sft" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer" (click)="openMissingDialog(row)">{{row.missing * 10.7639 || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="ordered" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer" (click)="openOrderedDialog(row)">{{+row.ordered || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="ordered_y" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer" (click)="openOrderedDialog(row)">{{row.ordered * 1.09361 || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="ordered_Msq" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer">{{row.ordered || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="ordered_Sft" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer" (click)="openOrderedDialog(row)">{{row.ordered * 10.7639 || 0 | number:'1.2-2'}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="ordered_N" let-value="value" let-row="row" let-column="column">
            <span style="cursor: pointer" (click)="openOrderedDialog(row)">{{row.ordered || 0}}</span>
          </ng-template>

          <ng-template tdDataTableTemplate="actions" let-value="value" let-row="row" let-column="column">
            <div layout="row">
              <a (click)="stockCardReport(row)" title="Stock Card Report" style="cursor:pointer">
                <md-icon>assignment_returned</md-icon>
              </a>
              <a (click)="openPicturesDialog(row)" style="cursor:pointer">
                <md-icon>photo_camera</md-icon>
              </a>
            </div>
          </ng-template>

        </td-data-table>

        



        <div class="md-padding" *ngIf="!dataTable.hasData" layout="row" layout-align="center center">
          <h3>{{ 'no_results_to_display' | translate:_authService.getLanguage() }}.</h3>
        </div>
        <md-divider></md-divider>

        <div fxLayout='row' fxLayout.lt-md="column">
          <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar
            [pageSize]="_settingsService.getPageSize()" [pageSizes]="[10, 50, 100, 1000, 'All']" [total]="filteredTotal"
            (change)="page($event)">
            <span td-paging-bar-label hide-xs>{{ 'rows_per_page' | translate:_authService.getLanguage() }}:</span>
            <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
            <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
            <span *ngIf="pagingBar.pageSize == 'All'">{{ 'all' | translate:_authService.getLanguage() }} </span>
            <span hide-xs>{{pagingBar.total}} {{ 'items' | translate:_authService.getLanguage() }}</span>
          </td-paging-bar>
        </div>

      </md-card-content>
    </md-card>
  </div>
</div>
