<div fxLayout="column">

    <div *ngIf="changed()" class="padded-container unsaved-changes" fxLayout="row" fxLayoutGap="30px">
        <md-icon>warning</md-icon>
        <span>You have unsaved changes</span>
    </div>

    <div class="boxed-content-wrapper cards-no-gaps">
        <md-card *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>Sales /
                        <a [routerLink]="['/accounting/customers']">Customers</a> / {{ (isEditMode && currentCustomer) ? currentCustomer.name : title }}
                    </span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white text-only" *ngIf="isEditMode">Edit Customer</md-card-subtitle>
                <md-card-subtitle class="bg-grey color-white text-only" *ngIf="!isEditMode">Create Customer</md-card-subtitle>
            </md-card-header>
            <md-card-content fxLayout="column" *ngIf="currentCustomer">
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput required placeholder="Name" [(ngModel)]="currentCustomer.name">
                        <!--<md-hint align="end">
                            <span style="color:red">required</span>
                        </md-hint>-->
                        <md-error>required</md-error>
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput required placeholder="Prefix" [(ngModel)]="currentCustomer.prefix">
                        <md-error>required</md-error>
                        <md-error>must be unique</md-error>
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="Register Of Commerce" [(ngModel)]="currentCustomer.roc_number">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="Fiscal ID" [(ngModel)]="currentCustomer.fiscal_id">
                    </md-input-container>
                </div>

                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <input mdInput placeholder="Address" [(ngModel)]="currentCustomer.address">
                        <!-- (blur)="fillShippingAddress(currentCustomer.address)" -->
                    </md-input-container>

                    <!--md-input-container fxFlex="22%">
                        <input mdInput placeholder="City/Country" [(ngModel)]="currentCustomer.city_name">
                    </md-input-container-->
                    <md-select fxFlex="22%" placeholder="City/Country" name="city_id" [(ngModel)]="currentCustomer.city_id" (ngModelChange)="fillShippingCity($event)">
                        <md-option *ngFor="let location of allCity" [value]="location.id">{{location.name}}, {{location.country_name}}</md-option>
                    </md-select>

                    <!--md-select fxFlex="22%" placeholder="Branch" name="branch" [(ngModel)]="currentCustomer.branch_id" (ngModelChange)="filterSalesmen()">
                        <md-option *ngFor="let branch of allBranches$ | async" [value]="branch.id">{{branch.code}}/{{branch.name}}</md-option>
                    </md-select-->
                </div>

                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="Contact Name" [(ngModel)]="currentCustomer.contact_name">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="Mobile" [(ngModel)]="currentCustomer.mobile_number">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="Phone" [(ngModel)]="currentCustomer.phone_number">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="Fax" [(ngModel)]="currentCustomer.fax_number">
                    </md-input-container>
                </div>

                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput placeholder="E-mail Address" [(ngModel)]="currentCustomer.email_address">
                    </md-input-container>
                </div>

                <md-card-subtitle class="bg-grey color-white text-only">Financial Info</md-card-subtitle>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput [readonly]="!_authService.isAdmin()" placeholder="Term Of Payment" type="number" [(ngModel)]="currentCustomer.term_of_payment">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <div layout="row">
                            <input mdInput [readonly]="!_authService.isAdmin()" placeholder="Credit Margin" [(ngModel)]="credit_margin">
                            <md-icon [title]="prettify(currentCustomer.credit_margin)" (click)="openCreditDialog()" style="cursor:pointer">access_time</md-icon>
                        </div>
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput [readonly]="!_authService.isAdmin()" type="date" placeholder="Credit Date" [(ngModel)]="credit_date">
                    </md-input-container>

                    <!--md-input-container fxFlex="22%">
                        <input mdInput placeholder="Sales Person" [(ngModel)]="currentCustomer.sales_person">
                    </md-input-container-->
                    <md-select fxFlex="22%" placeholder="Salesman" [(ngModel)]="currentCustomer.sales_person" [disabled]="!_authService.isAdmin()">
                        <md-option *ngFor="let p of valid_salesmen" [value]="p">{{p}}</md-option>
                    </md-select>

                </div>

                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="22%">
                        <input mdInput [readonly]="!_authService.isAdmin()" placeholder="Bank" [(ngModel)]="currentCustomer.bank">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput [readonly]="!_authService.isAdmin()" placeholder="Account" [(ngModel)]="currentCustomer.account">
                    </md-input-container>

                    <md-input-container fxFlex="22%">
                        <input mdInput [readonly]="!_authService.isAdmin()" placeholder="Share Capital" [(ngModel)]="currentCustomer.share_capital">
                    </md-input-container>

                    <div fxFlex="22%"></div>
                </div>

                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-input-container fxFlex="47%">
                        <textarea [readonly]="!_authService.isAdmin()" mdInput cols="4" placeholder="Notes" [(ngModel)]="currentCustomer.notes"></textarea>
                    </md-input-container>
                </div>

                <md-card-subtitle class="bg-grey color-white text-only">Shipping Address</md-card-subtitle>
                <div class="padded-container" fxLayout="row" fxLayoutGap="30px" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-checkbox [(ngModel)]="_useShipping" (ngModelChange)="clearShipping($event)" aria-label="Same as above" fxFlex="22%">
                        Same as above
                    </md-checkbox>

                    <!-- If shipping is same as above, then display the above as redonly -->
                    <md-input-container *ngIf="!_useShipping" fxFlex="47%">
                        <input mdInput placeholder="Address" [(ngModel)]="currentCustomer.shipping_address">
                    </md-input-container>
                    <md-input-container *ngIf="_useShipping" fxFlex="47%">
                        <input mdInput placeholder="Address" readonly [ngModel]="currentCustomer.address">
                    </md-input-container>

                    <md-select *ngIf="!_useShipping" fxFlex="22%" placeholder="City/Country" name="city_id" [(ngModel)]="currentCustomer.shipping_city_id">
                        <md-option *ngFor="let location of allCity" [value]="location.id">{{location.name}}, {{location.country_name}}</md-option>
                    </md-select>
                    <md-select *ngIf="_useShipping" disabled fxFlex="22%" placeholder="City/Country" name="city_id" [(ngModel)]="currentCustomer.city_id">
                        <md-option *ngFor="let location of allCity" [value]="location.id">{{location.name}}, {{location.country_name}}</md-option>
                    </md-select>
                </div>

                <md-card-subtitle class="bg-grey color-white text-only">Branches</md-card-subtitle>

                <div class="padded-container" fxLayout="column" fxLayoutGap="20px" *ngIf="isEditMode" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-checkbox [checked]="checkBranch(branchItem)" (change)="selectBranch($event, branchItem)" *ngFor="let branchItem of allBranches"
                        flex color="primary">{{branchItem.name}}
                    </md-checkbox>
                </div>
                <div class="padded-container" fxLayout="column" fxLayoutGap="20px" *ngIf="!isEditMode" fxLayout.lt-md="column" fxLayoutGap.lt-md="14px">
                    <md-checkbox (change)="selectBranch($event, branchItem); filterSalesmen()" *ngFor="let branchItem of allBranches" flex class="example-margin"
                        color="primary" [value]="branchItem">{{branchItem.name}}
                    </md-checkbox>
                </div>

                <md-card-actions *ngIf="!_authService.isViewer()">
                    <div class="table-control-buttons" fxFlex fxFlexAlign="start" fxFlexAlign.lt-md="center" fxLayoutGap="12px">
                        <button md-button *ngIf="!isEditMode" color="primary" class="bg-blue color-white" (click)="createCustomer()">Create</button>
                        <button md-button *ngIf="isEditMode" color="primary" class="bg-blue color-white" (click)="saveCustomer()">Save</button>
                        <button md-button color="warn" class="bg-red color-white" (click)="goBack()">Back</button>
                    </div>
                </md-card-actions>
            </md-card-content>
        </md-card>
    </div>
</div>