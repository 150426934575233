<div fxLayout="column">
    
    <div class="boxed-content-wrapper cards-no-gaps">
      <md-card>
          <md-card-header fxLayout="column">
            <md-card-title>
                <span class="md-title" fxFlex=100>
                    PDA / {{ 'shipping' | translate:_authService.getLanguage() }}
                </span>
            </md-card-title>
              <md-card-subtitle class="bg-grey color-white text-only">{{ 'shipping' | translate:_authService.getLanguage() }}</md-card-subtitle>
          </md-card-header>
          <md-card-content fxLayout="column">
              <div class="padded-container custom-container">
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/shipping/prepareOrder']">{{ 'prepare_order' | translate:_authService.getLanguage() }}</button>
                  <!-- The below functionality is out... for now -->
                  <!--button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/shipping/checkOrder']">Check Order</button-->
                  <button md-button color="primary" class="bg-blue color-white custom-button" [routerLink]="['/pda/shipping/shipOrder']">{{ 'ship_order' | translate:_authService.getLanguage() }}</button>
              </div>
          </md-card-content>
      </md-card>
    </div>
</div>
