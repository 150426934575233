<div fxLayout="column">
    
    <div class="boxed-content-wrapper">
        <md-card>
            <md-card-header fxLayout="column">
                <md-card-title>
                    <span class="md-title" fxFlex=100>Receiving</span>
                </md-card-title>
                <md-card-subtitle class="bg-grey color-white">
                    <div layout="row">
                        <td-search-box #searchBox backIcon="arrow_forward" class="push-right-sm" placeholder="Search Here" (searchDebounce)="search($event)"
                            flex>
                        </td-search-box>
                        <form class="push-right-sm" fxLayoutGap="16px">
                            <md-select placeholder="Branch" [(ngModel)]="branch_id_filter" name="branch" (ngModelChange)="filter()">
                                <md-option [value]="0">All</md-option>
                                <md-option *ngFor="let branchItem of _authService.getUserBranches()" [value]="branchItem.id">{{branchItem.code}} - {{branchItem.name}}</md-option>
                            </md-select>
                        </form>
                        <!-- If you wanted ALL data replace "filteredData" with "data" -->
                        <button md-icon-button [mdMenuTriggerFor]="exportMenu">
                            <md-icon>cloud_download</md-icon>
                        </button>

                        <md-menu #exportMenu="mdMenu">
                            <button md-menu-item (click)="_exportService.asCsv(filteredData, 'Receiving')">
                                <md-icon>cloud_download</md-icon>Export this list</button>
                            <button *ngIf="_authService.isAdmin()" md-menu-item (click)="receivingsReport()">
                                <md-icon>assignment_returned</md-icon>Receivings Report</button>
                        </md-menu>
                    </div>
                </md-card-subtitle>
            </md-card-header>

            <md-card-content *tdLoading="'loading'; mode:'indeterminate'; type:'circle'; strategy:'overlay'; color:'accent'">
                <div class="mat-table-container tmp-driven-table">
                    <table td-data-table class="duplicated-thead-fix">
                        <thead>
                            <tr td-data-table-column-row>
                                <th td-data-table-column [name]="'id'" [active]="sortBy == 'id'" [sortOrder]="sortOrder" [sortable]="true" (sortChange)="sort($event)"
                                    [numeric]="false">Rec#</th>
                                <th td-data-table-column [name]="'supplier'" [active]="sortBy == 'supplier'" [sortOrder]="sortOrder" [sortable]="true" (sortChange)="sort($event)"
                                    [numeric]="false">Supplier</th>
                                <th td-data-table-column *ngFor="let column of columns" [active]="sortBy == column.name" [name]="column.name" [sortOrder]="sortOrder"
                                    [sortable]="true" (sortChange)="sort($event)" [numeric]="false">
                                    {{column.label}}
                                </th>
                                <th td-data-table-column [name]="requesting_branch_code" [active]="sortBy == 'requesting_branch_code'" [sortOrder]="sortOrder" [sortable]="true"
                                    (sortChange)="sort($event)" [numeric]="false">Branch</th>

                                <th td-data-table-column [name]="actual_arrival_date" [active]="sortBy == 'actual_arrival_date'" [sortOrder]="sortOrder" [sortable]="true"
                                    (sortChange)="sort($event)" [numeric]="false">PO #</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr td-data-table-row *ngFor="let row of filteredData">
                                <td td-data-table-cell>
                                    <a class="tableLinks" [routerLink]="['/receiving/edit/', row.id]">{{row.receiving_number}}</a>
                                </td>
                                <td td-data-table-cell>
                                    <span>{{ row.supplier_code?row.supplier_code:getB2BCode(row) }}</span>
                                </td>
                                <td td-data-table-cell *ngFor="let column of columns" [numeric]="column.numeric">
                                    <span *ngIf="column.name != 'actual_arrival_date' && column.name != 'actual_length' && column.name != 'actual_weight'">{{row[column.name]}}</span>
                                    <span *ngIf="column.name == 'actual_length'">{{row[column.name] | number:'1.2-2'}}</span>
                                    <span *ngIf="column.name == 'actual_weight'">{{row[column.name] | number:'1.2-2'}}</span>
                                    <span *ngIf="column.name == 'actual_arrival_date'">{{row[column.name]?.substring(0, 10) }}</span>
                                </td>
                                <td td-data-table-cell>
                                    <span>{{ row.requesting_branch_code }} - {{row.requesting_branch_name}}</span>
                                </td>

                                <td td-data-table-cell>
                                    <span>{{ row.po_number }}</span>
                                </td>
                            </tr>
                            <tr td-data-table-column-row class="total-row no-border default">
                                <!--td td-data-table-cell></td-->
                                <td td-data-table-cell>Total (Page)</td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell>{{ total(filteredData, 'pieces') }}</td>
                                <td td-data-table-cell>{{ total(filteredData, 'actual_length') | number:'1.2-2'}} m</td>
                                <td td-data-table-cell>{{ total(filteredData, 'actual_weight') | number:'1.2-2'}} Kg</td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                            </tr>
                            <tr td-data-table-column-row class="total-row no-border default">
                                <!--td td-data-table-cell></td-->
                                <td td-data-table-cell>Total (All)</td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell>{{ total(data, 'pieces') }}</td>
                                <td td-data-table-cell>{{ total(data, 'actual_length') | number:'1.2-2'}} m</td>
                                <td td-data-table-cell>{{ total(data, 'actual_weight') | number:'1.2-2' }} Kg</td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                                <td td-data-table-cell></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div fxLayout='row' fxLayout.lt-md="column">
                    <td-paging-bar fxFlex fxFlexAlign="end" fxFlexAlign.lt-md="center" md-pull-right #pagingBar [pageSize]="_settingsService.getPageSize()"
                        [pageSizes]="[10, 50, 100, 1000, 'All']" [total]="filteredTotal" (change)="page($event)">
                        <span td-paging-bar-label hide-xs>Row per page:</span>
                        <span *ngIf="pagingBar.pageSize != 'All'">{{pagingBar.range}}</span>
                        <span *ngIf="pagingBar.pageSize != 'All'" hide-xs>of</span>
                        <span *ngIf="pagingBar.pageSize == 'All'">All </span>
                        <span hide-xs>{{pagingBar.total}} items</span>
                    </td-paging-bar>
                </div>
            </md-card-content>
        </md-card>
    </div>
</div>